<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog--">
      <v-card-title v-if="noSelectedItems" class="primary d-flex justify-space-between" data-cy="dialog--">
        <span v-if="action=='archive'">{{$t("routes.node.archiveNoSelectedItems")}}</span>
        <span v-else                  >{{$t("routes.node.unarchiveNoSelectedItems")}}</span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-title v-else class="primary d-flex justify-space-between" data-cy="dialog--">
        <span v-if="action=='archive'">{{$tc("routes.node.archiveConfirmMessage", totalItems)}}</span>
        <span v-else                  >{{$tc("routes.node.unarchiveConfirmMessage", totalItems)}}</span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5 scrollable">
        <div v-if="unarchivePreviewMessage && !noSelectedItems">
          {{$t("routes.node.unarchivePreviewMessage")}}
        </div>

        <div class="success" v-if="successMessage"> {{ successMessage }} </div>
        <div class="error" v-if="errorMessage"> {{ errorMessage }} </div>
        <div class="error" v-if="errorTranslatable">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>

        <UtilityPodsErrors></UtilityPodsErrors>
        <UtilityNodesErrors></UtilityNodesErrors>

        <ul v-for="node in archivableNodes" v-bind:key="node.id">
          <li>{{node.name}}</li>
        </ul>

        <ul v-for="pod in archivablePods" v-bind:key="pod.id">
          <li>{{pod.name}}</li>
        </ul>

        <div v-if="!noSelectedItems" class="mt-4">
          {{$t("routes.node.taskCanTakeSomeTime")}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!serviceHasRan"
          @click="archiveSelected"
          v-bind:disabled="isOkDisabled"
          elevation="0"
          color="primary"
          data-cy="dialog--ok"
        >
          {{ getArchiveButtonString }}
        </v-btn>
        <v-btn text @click="dialog = false" data-cy="dialog--">{{$t('common.close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "ArchiveSelectedDialog",
  props: {
    action: {
      type: String,
      required: true,
    },
    nodes: {
      type: Array,
      required: false,
    },
    pods: {
      type: Array,
      required: false
    },
    unarchivePreviewMessage: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function() {
    return {
      dialog: false,
      serviceHasRan: false,

      errorMessage: '',
      successMessage: '',
      errorTranslatable: null,
      okayPressed: false,
    };
  },
  methods: {
    updatePodsUtility: function() {
      if (!this.getArchiveService) {
        // this message should not be shown anyways...
        this.errorMessage = "Archiving service not found.";
        return;
      }

      let utilityPayload = {
        serviceAlias: this.getArchiveService.alias,
        pods: this.archivablePods,
        nodes: this.archivableNodes,
        action: this.action,
      }

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      return this.$store.dispatch(UPDATEPODSUTILITY, utilityPayload);
    },
    archiveSelected: function() {
      let self = this;
      this.successMessage = '';
      this.errorMessage = '';
      this.errorTranslatable = null;
      this.okayPressed = true;

      this.updatePodsUtility()
      .then(function() {

        const GETRUNNABLEPODS = self.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
        const runnablePods = self.$store.getters[GETRUNNABLEPODS];

        const GETRUNNABLENODES = self.$store.getters.keywords.SERVICE.GETRUNNABLENODES;
        const runnableNodes = self.$store.getters[GETRUNNABLENODES];

        if (runnableNodes.length == 0 && runnablePods.length == 0) {
          if (self.action == "archive") {
            throw { response: { data: { error: { message: self.$t("routes.node.archiveOperationError")}}}}
          } else {
            throw { response: { data: { error: { message: self.$t("routes.node.unarchiveOperationError")}}}}
          }
        }


        let payload = {
          serviceAlias: self.getArchiveService.alias,
          utilityPods: runnablePods,
          utilityNodes: runnableNodes,
          params: {
            action: self.action,
          }
        }

        const RUNSERVICE = self.$store.getters.keywords.SERVICE.RUNSERVICE;
        return self.$store.dispatch(RUNSERVICE, payload);
      })
      .then(function() {
        if (self.utilityPodsErrors.length == 0 && self.utilityNodesErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuSaveFrame.operationQueued");
          self.serviceHasRan = true;
          self.$emit("archiveSuccess", true);
          self.dialog = false;
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    }
  },
  computed: {
    archivableNodes: function() {
      const status = this.action == "archive" ? 0 : 1;
      return (this.nodes || []).filter(x => x.archived === status);
    },
    archivablePods: function() {
      const status = this.action == "archive" ? 0 : 1;
      return (this.pods || []).filter(x => x && x.archived === status);
    },
    totalItems: function() {
      return this.archivablePods.length + this.archivableNodes.length;
    },
    noSelectedItems: function() {
      let isNothingSelected = this.archivableNodes.length == 0 &&
                              this.archivablePods.length == 0;

      return isNothingSelected;
    },
    isOkDisabled: function() {
      return this.noSelectedItems || this.okayPressed;
    },
    getArchiveService: function() {
      const GETARCHIVESERVICE = this.$store.getters.keywords.SERVICE.GETARCHIVESERVICE;
      return this.$store.getters[GETARCHIVESERVICE];
    },
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
    utilityNodesErrors: function() {
      const GETUTILITYNODESERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYNODESERRORS;
      return this.$store.getters[GETUTILITYNODESERRORS];
    },
    getArchiveButtonString: function() {
      if (this.action === "archive") {
        return this.$t('common.archive');
      }

      return this.$t('common.unarchive');
    }
  },
  watch: {
    dialog: function() {
      this.successMessage = '';
      this.errorMessage = '';
      this.errorTranslatable = null;
    },
    archivablePods: function() {
      this.serviceHasRan = false;
      this.okayPressed = false;
    },
    archivableNodes: function() {
      this.serviceHasRan = false;
      this.okayPressed = false;
    }
  }
}
</script>
