import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    usersWithAccessToPod: [],
    reviewers: [],
  },
  getters: {
    getUsersWithAccessToPod: function (state) {
      return state.usersWithAccessToPod;
    },
    getReviewers: function (state) {
      return state.reviewers;
    }
  },
  mutations: {
    setUsersWithAccessToPod: function (state, users) {
      state.usersWithAccessToPod = users;
    },
    refreshReviewers: function (state, invitedReviewers) {
      state.reviewers = invitedReviewers;
    },
  },
  actions: {
    refreshUsersWithAccessToPod: function (context, podID) {
      return Api.reviewer.getUsers(podID)
        .then(function (response) {
          context.commit('setUsersWithAccessToPod', response.data);
          return response.data;
        });
    },
    refreshReviewers: function (context, podID) {
      return Api.reviewer.getReviewers(podID)
        .then(function (response) {
          context.commit('refreshReviewers', response.data);
          return response.data;
        });
    },
    inviteReviewers: function(context, data) {
      return Api.reviewer.inviteReviewers(data.podId, data.payload)
        .then(function (response) {
          context.commit('refreshReviewers', response.data);
          return response.data;
        });
    },
  }
}
