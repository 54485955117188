<template>
  <v-dialog
    v-model="dialog"
    v-bind:disabled="!transcript"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-editTranscript-activator">
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t('routes.pod.menuTranscript.editTranscript')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-on:submit.prevent="formSubmit()" data-cy="dialog-editTranscript-modal">
        <v-card-text class="pb-0">
          <div>
            {{$t('routes.pod.menuTranscript.transcriptName')}}:
          </div>
          <div>
            <v-text-field
              type="text"
              v-model="transcriptname"
              v-bind:rules="transcriptnameRules"
              required block
              solo flat
              data-cy="dialog-editTranscript-transcriptName"
            >
            </v-text-field>
          </div>

          <div v-if="errorMessage" class="error" data-cy="dialog-editTranscript-errorMessage">
            {{errorMessage}}
          </div>

          <div v-if="errorTranslatable" class="error">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>
        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" data-cy="dialog-editTranscript-ok">{{$t('common.update')}}</v-btn>
          <v-btn text @click="dialog = false" data-cy="dialog-editTranscript-false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "TranscriptCreateDialog",
  props: {
    transcript: {
      type: Object,
      required: false,
    },
  },
  data: function() {
    return {
      dialog: false,

      transcriptname: "",
      transcriptnameRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.invalidTranscriptName')
      ],

      errorMessage: "",
      errorTranslatable: null,
    };
  },
  mounted() {
    if (this.transcript) {
      this.transcriptname = this.transcript.name;
    }
  },
  watch: {
    dialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.transcriptname = this.transcript.name;
      });
    },
    transcript: function(newTranscript) {
      if (newTranscript) {
        this.transcriptname = newTranscript.name;
      }
    },
  },
  methods: {
    formSubmit: function() {
      if (!this.transcript)
        return;

      const validForm = this.$refs.form.validate();
      this.errorMessage = "";
      this.errorTranslatable = null;

      if (!validForm) {
        return;
      }

      const data = {
        id: this.transcript.id,
        podID: this.transcript.podid,
        payload: {
          name: this.transcriptname,
        }
      };

      let self = this;
      const UPDATETRANSCRIPT = this.$store.getters.keywords.POD.TRANSCRIPT.UPDATETRANSCRIPT;

      this.$store.dispatch(UPDATETRANSCRIPT, data)
      .then(function(transcript) {
        transcript;
        // console.log("GOT transcript", transcript);
        self.$emit("refreshSelectedTranscript", transcript);
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
  },
  computed: {
  },
}
</script>
