<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podMenu-translateSubtitles-activator">
        <slot></slot>
      </div>
    </template>

    <v-form ref="form" v-on:submit.prevent="runJobs" autocomplete="off">
      <v-card >
        <v-card-title class="primary d-flex justify-space-between">
          <span>{{$t('routes.pod.menuTranslateSubtitles.translate')}} {{ filename }}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="scrollable mt-4">
          <div>
            <v-select
              v-model="selectedService"
              v-bind:rules="selectedServiceRules"
              v-bind:items="translationsServices"
              item-text="name"
              item-value="id"
              v-bind:label="$t('routes.pod.menuTranslateSubtitles.selectServiceToUse')"
              solo flat
              required
              return-object
              single-line
              data-cy="podMenu-translateSubtitles-selectService"
            ></v-select>
          </div>

          <div>
            <v-select
              v-model="inputLanguage"
              v-bind:rules="inputLanguageRules"
              v-bind:items="languages"
              item-text="name"
              item-value="value"
              v-bind:label="$t('routes.pod.menuTranslateSubtitles.selectInputLanguage')"
              solo flat
              required
              return-object
              single-line
              data-cy="podMenu-translateSubtitles-selectInputLanguage"
            ></v-select>
          </div>

          <div>
            <v-select
              v-model="outputLanguage"
              v-bind:rules="outputLanguageRules.concat([differentLanguagesRule])"
              v-bind:items="languages"
              item-text="name"
              item-value="value"
              v-bind:label="$t('routes.pod.menuTranslateSubtitles.selectOutputLanguage')"
              solo flat
              required
              return-object
              single-line
              data-cy="podMenu-translateSubtitles-selectOutputLanguage"
            ></v-select>
          </div>

          <div class="d-flex font-italic">
            <div class="flex-grow-1">{{$t('routes.pod.menuTranslateSubtitles.numberOfCharacters')}}</div>
            <div>{{numberOfCharacters | toKilo(1)}}</div>
          </div>


          <div class="d-flex font-italic">
            <div class="flex-grow-1">{{$t('routes.pod.creditsLeft')}}</div>
            <div>{{ creditsLeft }}</div>
          </div>

          <div class="d-flex font-italic">
            <div class="flex-grow-1">{{$t('routes.pod.creditsLeftAfterJob')}}</div>
            <div>{{ creditsLeftAfterJob }}</div>
          </div>

          <hr />

          <!-- TODO: Rework this message box to redirect to URL -->
          <div v-if="$canControlTenantConfig() && creditsLeftAfterJob < 0" class="my-2">
            <div>{{$t('routes.pod.menuTranslateSubtitles.wantToBuyBundle')}}</div>
            <div class="d-flex justify-space-around">
              <v-btn v-on:click="dialog = false" color="primary" elevation=0>{{$t('common.no')}}</v-btn>
              <v-btn to="/console/subscriptions" color="primary" elevation=0>{{$t('common.yes')}}</v-btn>
            </div>
          </div>

          <UtilityPodsErrors data-cy="podMenu-translateSubtitles-utilityPodsError"></UtilityPodsErrors>

          <div v-show="successMessage" class="success" data-cy="podMenu-translateSubtitles-successMessage">{{successMessage}}</div>
          <div v-show="errorMessage" class="error" data-cy="podMenu-translateSubtitles-errorMessage">{{errorMessage}}</div>

          <div class="text-overflow">{{ asset.name }}</div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            v-if="creditsLeftAfterJob >= 0"
            v-bind:disabled="isRunJobsDisabled"
            type="submit"
            color="primary"
            elevation=0
            data-cy="podMenu-translateSubtitles-submit-btn"
          >
            {{$t('routes.pod.menuTranslateSubtitles.translateSubtitle')}}
          </v-btn>

          <v-btn text @click="dialog = false" data-cy="podMenu-translateSubtitles-close-btn">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "TranslateSubtitleAssetDialog",
  props: {
    asset: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,

      selectedService: '',
      selectedServiceRules: [
        v => !!v || this.$t('routes.pod.menuTranslateSubtitles.serviceProviderRequired'),
      ],

      inputLanguage: '',
      inputLanguageRules: [
        v => !!v || this.$t('routes.pod.menuTranslateSubtitles.inputLanguageRequired'),
      ],

      outputLanguage: '',
      outputLanguageRules: [
        v => !!v || this.$t('routes.pod.menuTranslateSubtitles.outputLanguageRequired'),
      ],

      serviceOptions: null,
      preventDoubleclick: false,

      errorMessage: '',
      successMessage: '',
    };
  },
  watch: {
    selectedService: function(newServices) {
      if (this.asset && this.inputLanguage && this.outputLanguage) {
        this.updatePodsUtility();
        this.errorMessage = '';
      }

      if (newServices && newServices.alias) {
        this.refreshServiceOptions(newServices.alias);
      }
    },
    // inputSource: function() {
    //   if (this.selectedService && this.inputLanguage && this.outputLanguage) {
    //     this.updatePodsUtility();
    //     this.errorMessage = '';
    //   }
    // },
    inputLanguage: function() {
      if (this.selectedService && this.asset && this.outputLanguage) {
        this.updatePodsUtility();
        this.errorMessage = '';
      }
    },
    outputLanguage: function() {
      if (this.selectedService && this.inputLanguage && this.asset) {
        this.updatePodsUtility();
        this.errorMessage = '';
      }
    }

  },
  methods: {
    refreshServiceOptions: function(serviceAlias) {
      let self = this;
      const REFRESHSERVICEOPTIONS = this.$store.getters.keywords.SERVICE.REFRESHSERVICEOPTIONS;
      this.$store.dispatch(REFRESHSERVICEOPTIONS, serviceAlias)
      .then(function(options) {
        self.serviceOptions = options;
      });
    },
    updatePodsUtility() {
      if (this.selectedSuitablePods.length == 0) {
        const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
        this.$store.dispatch(RESETUTILITYPODS);
        return;
      }

      if (!this.selectedService || !this.asset || !this.inputLanguage || !this.outputLanguage || this.inputLanguage.value === this.outputLanguage.value) {
        return;
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        pods: this.selectedSuitablePods,
        assetID: this.asset.id,
        sourcelanguage: this.inputLanguage.value,
        targetlanguage: this.outputLanguage.value
      };

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      return this.$store.dispatch(UPDATEPODSUTILITY, payload);
    },
    runJobs: function() {
      console.log("Run Translation job!");
      console.log("Pods:", this.selectedSuitablePods);
      console.log("Service:", this.selectedService);
      console.log("Input source: ", this.asset);
      console.log("Input language: ", this.inputLanguage);

      this.errorMessage = '';
      this.successMessage = '';

      const validForm = this.$refs.form.validate();
      if (!validForm) {
        return;
      }

      const payload = {
        serviceAlias: this.selectedService.alias,
        utilityPods: this.runnablePods,
        params: {
          assetid: this.asset.id,
          sourcelanguage: this.inputLanguage.value,
          targetlanguage: this.outputLanguage.value
        }
      };

      let self = this;

      this.preventDoubleclick = true;
      setTimeout(() => {
        self.preventDoubleclick = false;
      }, 10000);

      const RUNSERVICE = this.$store.getters.keywords.SERVICE.RUNSERVICE;

      this.updatePodsUtility()
      .then(function() {
        self.$store.dispatch(RUNSERVICE, payload)
          .then(function(response) {

            if (response?.pods) {
              for (const pod of response.pods) {
                if (pod.error) {
                  self.errorMessage = self.$replaceT(pod.error.code, pod.error.params, pod.error.content);
                  break;
                }
              }
            }
          });
      })
      .then(function() {
        if (!self.utilityPodsErrors || self.utilityPodsErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuTranslateSubtitles.operationQueued");
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }

        self.preventDoubleclick = false;
      });
    },
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isAudioPod: function() {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
    selectedSuitablePods: function() {
      if (this.isVideoPod || this.isAudioPod) {
        return [this.pod];
      }
      return [];
      // return this.selectedVideoPods.concat(this.selectedAudioPods);
    },
    translationsServices: function() {
      const GETTRANSLATIONS = this.$store.getters.keywords.SERVICE.GETTRANSLATIONS;
      return this.$store.getters[GETTRANSLATIONS];
    },
    languages: function() {
      if (this.selectedService && this.serviceOptions && this.serviceOptions.languages) {
        return this.serviceOptions.languages;
      }
      return [];
    },
    differentLanguagesRule: function() {
      if (this.inputLanguage.value && this.outputLanguage.value && this.inputLanguage.value === this.outputLanguage.value) {
        return this.$t('routes.pod.menuTranslateSubtitles.theLanguagesMustDiffer');
      }
      return true;
    },
    runnablePods: function() {
      const GETRUNNABLEPODS = this.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
      return this.$store.getters[GETRUNNABLEPODS];
    },
    getUtilityPods: function() {
      const GETUTILITYPODS = this.$store.getters.keywords.SERVICE.GETUTILITYPODS;
      return this.$store.getters[GETUTILITYPODS];
    },
    summedCost: function() {
      let sum = 0;
      for (let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.cost !== null &&
            this.getUtilityPods[i].metadata.cost !== undefined) {
          sum += this.getUtilityPods[i].metadata.cost;
        }
      }
      return sum;
    },
    numberOfCharacters: function() {
      if (!Array.isArray(this.getUtilityPods)) {
        return 0;
      }

      let characters = 0;
      for(let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.characters !== null &&
            this.getUtilityPods[i].metadata.characters !== undefined) {
          characters += this.getUtilityPods[i].metadata.characters;
        }
      }

      return characters;
    },
    creditsLeft: function() {
      for(let i = 0; i < this.getUtilityPods.length; i++) {
        if (this.getUtilityPods[i].metadata &&
            this.getUtilityPods[i].metadata.available !== null &&
            this.getUtilityPods[i].metadata.available !== undefined) {
          return this.getUtilityPods[i].metadata.available;
        }
      }

      return 0;
    },
    creditsLeftAfterJob: function() {
      return this.creditsLeft - this.summedCost;
    },
    isRunJobsDisabled: function() {
      if (!this.selectedService ||
          this.runnablePods.length == 0 ||
          !this.asset ||
          !this.inputLanguage ||
          !this.outputLanguage ||
          this.preventDoubleclick == true
      ) {
        return true;
      }

      return false;
    },
    filename: function () {
      if (this.asset && this.asset.name) {
        if (this.asset.name.length > 20) {
          return this.asset.name.substring(0, 20) + '...';
        }

        return this.asset.name;
      }

      return '';
    },
  }
}
</script>

<style>
</style>
