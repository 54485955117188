import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    services: [],
    utilityPods: [],
    utilityNodes: [],
    limits: null,
  },
  getters: {
    getLimits: function(state) {
      return state.limits;
    },
    getAllServices: function(state) {
      return state.services;
    },
    getRekognition: function(state) {
      const lookingFor = ['aws-detecting-text', 'aws-detecting-labels'];
      const services = [];

      for (let i = 0; i < state.services.length; i++) {
        if (lookingFor.includes(state.services[i].alias)) {
          services.push(state.services[i]);
        }
      }

      return services;
    },
    getTextToSpeech: function(state) {
      return state.services.filter(x => x.usage === 'transcribe');
    },
    getTranslations: function(state) {
      const lookingFor = ['aws-translate', 'google-translate'];
      return state.services.filter(x => lookingFor.indexOf(x.alias) >= 0);
    },
    getExtractFrames: function(state) {
      return state.services.find(x => x.alias === 'extract-frames');
    },
    getMediaConverter: function(state) {
      return state.services.find(x => x.alias === 'media-converter');
    },
    getMediaTranscoder: function(state) {
      return state.services.find(x => x.alias === 'media-transcoder');
    },
    getUpdateThumbnail: function(state) {
      return state.services.find(x => x.alias === 'thumbnail-generator');
    },
    getImprintSubtitles: function(state) {
      return state.services.find(x => x.alias === 'imprint-subtitles');
    },
    getGeneratingSubtitles: function(state) {
      return state.services.find(x => x.alias === 'generating-subtitles');
    },
    getArchiveService: function(state) {
      return state.services.find(x => x.alias === 'aws-archive');
    },
    getExportFCPXML: function(state) {
      return state.services.find(x => x.alias === 'generate-FCPXML');
    },
    getUtilityPods: function(state) {
      return state.utilityPods;
    },
    getUtilityPodsErrors: function(state) {
      if (Array.isArray(state.utilityPods)) {
        return state.utilityPods.filter(x => x.error);
      }

      return [];
    },
    getUtilityNodesErrors: function(state) {
      if (Array.isArray(state.utilityNodes)) {
        return state.utilityNodes.filter(x => x.error);
      }

      return [];
    },
    getRunnablePods: function(state) {
      if (state.utilityPods && Array.isArray(state.utilityPods)) {
        return state.utilityPods.filter(x => (x.info && x.info.runnable));
      }

      return [];
    },
    getRunnableNodes: function(state) {
      if (state.utilityNodes && Array.isArray(state.utilityNodes)) {
        return state.utilityNodes.filter(x => (x.info && x.info.runnable));
      }

      return [];
    },
    isPodRunnable: function(state) {
      return function(pod) {

        if (!Array.isArray(state.utilityPods)) {
          return false;
        }

        const utilityPod = state.utilityPods.find(function (x) {
          return x && x.pod && x.pod.id && x.pod.id == pod.id;
        });

        return utilityPod && utilityPod.info && utilityPod.info.runnable;
      };
    },
    getUtilityPod: function(state) {
      return function(pod) {
        if (!Array.isArray(state.utilityPods)) {
          return false;
        }

        return state.utilityPods.find(function (x) {
          return x && x.pod && x.pod.id && x.pod.id == pod.id;
        });
      }
    }
  },
  mutations: {
    setServices: function(state, services) {
      state.services = services;
    },
    setUtilityPods: function(state, utilityPods) {
      state.utilityPods = utilityPods;
    },
    setUtilityNodes: function(state, utilityNodes) {
      state.utilityNodes = utilityNodes;
    },
    setLimits: function(state, limits) {
      state.limits = limits;
    },
  },
  actions: {
    refreshServices: function(context) {
      context.commit('setUtilityPods', []);
      return Api.service.getAllServices()
      .then(function(response) {
        context.commit('setServices', response.data);
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    resetUtilityPods: function(context) {
      context.commit('setUtilityPods', []);
    },
    resetUtilityNodes: function(context) {
      context.commit('setUtilityNodes', []);
    },
    updatePodsUtility: function(context, payload) {
      const serviceAlias = payload.serviceAlias;
      const nodeIDs = [];
      const podIDs = [];
      const assetID = payload.assetID;
      const sourceLanguage = payload.sourcelanguage;
      const targetLanguage = payload.targetlanguage;
      const action = payload.action;

      if (payload.nodes) {
        for (let i = 0; i < payload.nodes.length; i++) {
          nodeIDs.push(payload.nodes[i].id);
        }
      }

      if (payload.pods) {
        for(let i = 0; i < payload.pods.length; i++) {
          podIDs.push(payload.pods[i].id);
        }
      }

      return Api.service.getPodsUtility(serviceAlias, nodeIDs, podIDs, assetID, sourceLanguage, targetLanguage, action)
      .then(function(response) {
        if (!response.data) {
          response.data = {};
        }

        context.commit('setUtilityPods', response.data.pods || []);
        context.commit('setUtilityNodes', response.data.nodes || []);
        return response.data;
      })
      .catch(function(error) {
        console.log("Error getting pods utility");
        console.log(error);
      });
    },
    runService: function(context, payload) {
      const serviceAlias = payload.serviceAlias;
      const nodeIDs = [];
      const podIDs = [];

      if (!Array.isArray(payload.utilityNodes)) {
        payload.utilityNodes = [];
      }

      for(let i = 0; i < payload.utilityNodes.length; i++) {
        if (payload.utilityNodes[i].node) {
          nodeIDs.push(payload.utilityNodes[i].node.id);
        }
      }

      for(let i = 0; i < payload.utilityPods.length; i++) {
        if (payload.utilityPods[i].pod) {
          podIDs.push(payload.utilityPods[i].pod.id);
        }
      }

      return Api.service.runService(serviceAlias, nodeIDs, podIDs, payload.params)
      .then(function(response) {
        if (response && response.data && response.data.pods) {
          context.commit('setUtilityPods', response.data.pods || []);
        }

        if (response && response.data && response.data.nodes) {
          context.commit('setUtilityNodes', response.data.nodes || []);
        }

        return response.data;
      });
    },

    refreshServiceOptions: function(context, serviceAlias) {
      return Api.service.getServiceOptions(serviceAlias)
      .then(function(response) {
        // console.log("Service options", response);
        return response.data;
      })
    },

    refreshLimits: function(context, payload) {
      return Api.service.getResources(payload)
      .then(function(response) {
        context.commit('setLimits', response.data);
        // console.log("Limits", response.data)
      });
    }
  }
}
