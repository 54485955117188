<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>


    <v-form ref="form" data-cy="podMenu-tools-saveFrame-form">
      <v-card >
        <v-card-title class="primary d-flex justify-space-between">
          <span>{{$t('routes.pod.menuLabelsAndMetadata.exportFCPXML')}}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="scrollable mt-4 pt-4">
          <div v-if="getMlLabels.length > 0 || getCustomLabels.length > 0">
            {{$t('routes.pod.menuExportFCPXML.userMessage')}}
          </div>

          <UtilityPodsErrors data-cy="podMenu-exportFCPXML-noLabels-message"></UtilityPodsErrors>

          <div v-for="item in runnablePods" v-bind:key="item.id" class="my-2 d-flex justify-space-between speech-to-text-file">
            <div class="text-truncate" data-cy="podMenu-exportFCPXML-runnablePodName">{{item.pod.name}}</div>
            <div>{{ getCustomLabels.length }} + {{ getMlLabels.length }} {{$t('routes.pod.menuExportFCPXML.labels')}}</div>
          </div>

          <div v-show="successMessage" class="success" data-cy="podMenu-exportFCPXML-successMessage">{{successMessage}}</div>
          <div v-show="errorMessage" class="error" data-cy="podMenu-exportFCPXML-errorMessage">{{errorMessage}}</div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
          v-if="getMlLabels.length > 0 || getCustomLabels.length > 0"
          v-bind:disabled="isRunJobsDisabled"
          v-on:click.prevent="runJobs()"
          elevation=0
          color="primary"
          data-cy="podMenu-exportFCPXML-runJobs-btn">
            {{$t('common.export')}}
          </v-btn>

          <v-btn text @click="dialog = false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "ExportFCPXMLDialog",
  data() {
    return {
      dialog: false,

      errorMessage: '',
      successMessage: '',
      preventDoubleclick: false,
      jobIsWorking: false,
      selectedService: 'generate-FCPXML',

    };
  },
  mounted: function() {
    // const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
    // this.$store.dispatch(RESETUTILITYPODS);
    // this.updatePodsUtility();

    // for (const pod of this.suitablePods) {
    //   const REFRESHMLLABELS = this.$store.getters.keywords.POD.LABEL.REFRESHMLLABELS;
    //   this.$store.dispatch(REFRESHMLLABELS, pod.id);

    //   const REFRESHLABELS = this.$store.getters.keywords.POD.LABEL.REFRESHLABELS;
    //   this.$store.dispatch(REFRESHLABELS, pod.id);
    // }
  },
  watch: {
    suitablePods: function() {
      this.updatePodsUtility();
    },
    dialog(newValue) {
      if (newValue) {
        this.updatePodsUtility();
      } else {
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.errorMessage = '';
      this.successMessage = '';
      this.preventDoubleclick = false;
      this.jobIsWorking = false;
      this.selectedService = 'generate-FCPXML';
    },
    updatePodsUtility() {
      this.errorMessage = '';

      if (this.suitablePods.length == 0) {
        const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
        this.$store.dispatch(RESETUTILITYPODS);
        return;
      }

      if (!this.getExportFCPXMLService.alias) {
        return;
      }

      const payload = {
        serviceAlias: this.getExportFCPXMLService.alias,
        pods: this.suitablePods,
      };

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      this.$store.dispatch(UPDATEPODSUTILITY, payload);
    },
    runJobs: function() {
      this.errorMessage = "";
      this.successMessage = "";

      if (!this.getExportFCPXMLService.alias) {
        return;
      }

      const payload = {
        serviceAlias: this.getExportFCPXMLService.alias,
        utilityPods: this.runnablePods,
      };

      let self = this;
      this.preventDoubleclick = true;

      const RUNSERVICE = this.$store.getters.keywords.SERVICE.RUNSERVICE;

      this.$store.dispatch(RUNSERVICE, payload)
      .then(function() {
        if (self.utilityPodsErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuExportFCPXML.operationQueued");
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }

        self.preventDoubleclick = false;
      });
    }
  },
  computed: {
    getMlLabels: function() {
      const GETMLLABELS = this.$store.getters.keywords.POD.LABEL.GETMLLABELS;
      return this.$store.getters[GETMLLABELS];
    },
    getCustomLabels: function() {
      const GETLABELS = this.$store.getters.keywords.POD.LABEL.GETLABELS;
      return this.$store.getters[GETLABELS];
    },
    suitablePods: function() {
      let suitablePods = [];
      if (this.isVideoPod) {
        const GETSELECTEDVIDEOPODS = this.$store.getters.keywords.POD.GETSELECTEDVIDEOPODS;
        suitablePods = this.$store.getters[GETSELECTEDVIDEOPODS];
      }
      if (this.isAudioPod) {
        const GETSELECTEDAUDIOPODS = this.$store.getters.keywords.POD.GETSELECTEDAUDIOPODS;
        suitablePods = this.$store.getters[GETSELECTEDAUDIOPODS];
      }
      if (this.isImagePod) {
        const GETSELECTEDIMAGEPODS = this.$store.getters.keywords.POD.GETSELECTEDIMAGEPODS;
        suitablePods = this.$store.getters[GETSELECTEDIMAGEPODS];
      }

      return suitablePods;
    },
    getUtilityPods: function() {
      const GETUTILITYPODS = this.$store.getters.keywords.SERVICE.GETUTILITYPODS;
      return this.$store.getters[GETUTILITYPODS];
    },
    getExportFCPXMLService: function() {
      const GETEXPORTFCPXML = this.$store.getters.keywords.SERVICE.GETEXPORTFCPXML;
      return this.$store.getters[GETEXPORTFCPXML];
    },
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
    runnablePods: function() {
      const GETRUNNABLEPODS = this.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
      return this.$store.getters[GETRUNNABLEPODS];
    },
    isRunJobsDisabled: function() {
      // console.log(this.selectedService, this.runnablePods.length, this.preventDoubleclick);
      if (this.runnablePods.length == 0 ||
          this.preventDoubleclick == true
      ) {
        return true;
      }

      return false;
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isImagePod: function() {
      const ISIMAGEPOD = this.$store.getters.keywords.POD.ISIMAGEPOD;
      return this.$store.getters[ISIMAGEPOD];
    },
    isAudioPod: function() {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
  }

}
</script>

<style>
</style>
