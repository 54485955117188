<template>
  <v-expansion-panels v-model="nodesPanel" id="node-expansion-panel" flat >

    <v-expansion-panel v-show="getFolderSearchResults.length > 0" style="" data-cy="nodeSideMenu----">
      <v-expansion-panel-header
      :color="nodesPanel == 0 ? 'primary' : ''"
      @contextmenu.prevent="showExpansionMenu"
      expand-icon="mdi-menu-down"
      ripple
      style="">

        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-magnify</v-icon>
            {{$t('menus.nodeList.searchResults')}}
          </div>
          <div>
            <v-chip x-small>{{getFolderSearchResults.length}}</v-chip>
          </div>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="node in getFolderSearchResults" :key="node.id" class="py-2">
          <router-link v-if="node.parent" :to="'/node/' + node.parent.id" style="color: unset;">
            <v-icon small>
              {{getNodeIcon(node.parent)}}
            </v-icon>
            {{node.parent.name}}
          </router-link>
          <span v-if="node.parent"> / </span>
          <router-link :to="'/node/'+node.id" style="color: unset;">
            <v-icon small>
              {{getNodeIcon(node)}}
            </v-icon>
            {{node.name}}
          </router-link>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel style="" data-cy="nodeSideMenu-allFolders">
      <v-expansion-panel-header
      :color="nodesPanel == 1 ? 'primary' : ''"
      @contextmenu.prevent="showExpansionMenu"
      expand-icon="mdi-menu-down"
      ripple
      style="">

        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-folder</v-icon>
            {{$t('menus.nodeList.allFolders')}}
          </div>
          <div><v-chip x-small>{{getSortedChildren.length}}</v-chip></div>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-if="isLoadingAccessPoints || (nodeTreeIds.length > 0 && accessPoints.length > 0 && openedNodeIds.length === 0)"
          class="d-flex justify-center py-2">
            <v-progress-circular
              :size="40"
              :width="4"
              indeterminate
              color="primary"
            ></v-progress-circular>
        </div>

        <div v-if="!isLoadingAccessPoints && nodeTreeIds.length === 0 && accessPoints.length === 0" style="padding: 16px 0">
          {{$t('menus.nodeList.noAccess')}}
        </div>

        <div v-if="openedNodeIds.length !== 0">
          <NodeTreeItem
            v-for="node in getSortedChildren"
            :key="node.id"
            :node="node"
            :sortFunction="getSortFunction"
            :openedIds="openedNodeIds"
            @showContextMenu="showContextMenuEvent"
          >
          </NodeTreeItem>
        </div>



      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-show="getClientNodes.length > 0" data-cy="nodeSideMenu-Clients">
      <v-expansion-panel-header
      :color="nodesPanel == 2 ? 'primary' : ''"
      @contextmenu.prevent="showExpansionMenu"
      expand-icon="mdi-menu-down"
      ripple
      style="">

        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-account-tie</v-icon>
            {{$t('menus.nodeList.clients')}}
          </div>
          <div><v-chip x-small>{{getClientNodes.length}}</v-chip></div>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <NodeTreeItem
          v-for="node in getClientNodes"
          :key="node.id"
          :node="node"
          :sortFunction="getSortFunction"
          @showContextMenu="showContextMenuEvent"
        >
        </NodeTreeItem>

      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-show="getProjectNodes.length > 0" data-cy="nodeSideMenu-Projects">
      <v-expansion-panel-header
      :color="nodesPanel == 3 ? 'primary' : ''"
      @contextmenu.prevent="showExpansionMenu"
      expand-icon="mdi-menu-down"
      ripple
      style="">

        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-briefcase-clock</v-icon>
            {{$t('menus.nodeList.projects')}}
          </div>
          <div><v-chip x-small>{{getProjectNodes.length}}</v-chip></div>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <NodeTreeItem
          v-for="node in getProjectNodes"
          :key="node.id"
          :node="node"
          :sortFunction="getSortFunction"
          @showContextMenu="showContextMenuEvent"
        >
        </NodeTreeItem>

      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-show="getArchivedNodes.length > 0" data-cy="nodeSideMenu-Projects">
      <v-expansion-panel-header
      :color="nodesPanel == 4 ? 'primary' : ''"
      @contextmenu.prevent="showExpansionMenu"
      expand-icon="mdi-menu-down"
      ripple
      style="">

        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-package-down</v-icon>
            {{$t('menus.nodeList.archived')}}
          </div>
          <div><v-chip x-small>{{getArchivedNodes.length}}</v-chip></div>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <NodeTreeItem
          v-for="node in getArchivedNodes"
          :key="node.id"
          :node="node"
          :sortFunction="getSortFunction"
          @showContextMenu="showContextMenuEvent"
        >
        </NodeTreeItem>

      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header
        :color="nodesPanel == 5 ? 'primary' : ''"
        expand-icon="mdi-menu-down"
        ripple
        style=""
        @contextmenu.prevent="showRecycleBinContextMenu"
        @click="$router.currentRoute.path !== `/recycle-bin/${getRootNodeId}` && $router.push(`/recycle-bin/${getRootNodeId}`)"
      >
        <div class="d-flex justify-space-between">
          <div>
            <v-icon small class="mr-1">mdi-recycle</v-icon>
            {{ $t('menus.nodeList.recycleBin') }}
          </div>
          <div><v-chip x-small>{{ getRecycleBinPodsCount }}</v-chip></div>
        </div>

        <template v-slot:actions>
          <v-icon style="visibility: hidden;">
            mdi-delete-circle
          </v-icon>
        </template>
      </v-expansion-panel-header>

      <!-- <v-expansion-panel-content>
        <NodeRecycleBinTreeItem
          v-for="node in getRecycleBinRootNodes"
          :key="node.id"
          :node="node"
          @contextMenu="showRecycleBinContextMenu"
        />
      </v-expansion-panel-content> -->
    </v-expansion-panel>

    <ExpansionpanelContextMenu
      v-model="expansionMenu"
      :positionX="expansionMenuX"
      :positionY="expansionMenuY"
      :sortString="sortString"
      @setSort="setNodeSort"
    />

    <NodeContextMenu
      v-if="contextMenuNode && contextMenuNode.archived === archiveStatus.NOTARCHIVED"
      v-model="showContextMenu"
      :positionX="contextMenuX"
      :positionY="contextMenuY"
      :node="contextMenuNode"
      :key="contextMenuX * contextMenuY"
    />

    <ArchivedNodeContextMenu
      v-if="contextMenuNode && contextMenuNode.archived === archiveStatus.ARCHIVED"
      v-model="showContextMenu"
      :positionX="contextMenuX"
      :positionY="contextMenuY"
      :node="contextMenuNode"
      :key="contextMenuX * contextMenuY"
    />

    <RecycleBinContextMenu
      v-model="recycleBinContextMenu"
      :items-count="getRecycleBinPodsCount"
      :position-y="recycleBinContextMenuY"
      :position-x="recycleBinContextMenuX"
      :retention-period="getRecycleBinRetentionPeriod"
      @contextMenuItemClicked="recycleBinContextMenu = false"
    />

  </v-expansion-panels>
</template>

<script>
import NodeTreeItem from './NodeTreeItem.vue'
import NodeContextMenu from '../../../../routes/nodes2/components/contextMenus/NodeContextMenu.vue'
import ArchivedNodeContextMenu from '../../../../routes/nodes2/components/contextMenus/ArchivedNodeContextMenu.vue'
import ExpansionpanelContextMenu from './components/ExpansionpanelContextMenu.vue'
// import NodeRecycleBinTreeItem from '@/layouts2/mainLayout/components/nodeSidemenu/NodeRecycleBinTreeItem.vue';
import RecycleBinContextMenu from '@/layouts2/mainLayout/components/nodeSidemenu/components/RecycleBinContextMenu.vue';

export default {
  name: "NodeExpansionPanel",
  components: {
    RecycleBinContextMenu,
    // NodeRecycleBinTreeItem,
    NodeTreeItem,
    NodeContextMenu,
    ArchivedNodeContextMenu,
    ExpansionpanelContextMenu,
  },
  props: {
    // searchText: String,
  },
  data() {
    return {
      nodesPanel: this.$route.name === 'recycle-bin' ? 5 : 1,
      recycleBinTransitionPanel: this.$route.name === 'recycle-bin' ? 1 : null,
      recycleBinTransitionRoute: null,

      // The context menu for custom tree
      showContextMenu: false,
      contextMenuNode: null,
      contextMenuX: 0,
      contextMenuY: 0,

      sortString: 'nameAsc',
      expansionMenu: false,
      expansionMenuX: 0,
      expansionMenuY: 0,

      nodeTreeIds: [],
      openedNodeIds: [],

      recycleBinContextMenu: false,
      recycleBinContextMenuX: 0,
      recycleBinContextMenuY: 0,
    };
  },
  watch: {
    accessPoints(newValue, oldValue) {
      // Check if the ID's of the old value and new value differ
      let newAccessPointIds = (newValue || []).map(x => x.id);
      let oldAccessPointIDs = (oldValue || []).map(x => x.id);

      for (let i = 0; i < newAccessPointIds.length; i++) {
        if (oldAccessPointIDs.indexOf(newAccessPointIds[i]) < 0) {
          this.refreshAll();
          return;
        }
      }
    },
    getRootNodeId(oldID, newID) {
      if (oldID !== newID) {

        this.refreshAll();
      }
    },
    getCurrentNode(newNode) {
      const newRouteId = String(this.$route.params.id);

      if (newNode &&
          newNode.id == newRouteId &&
          this.nodeTreeIds.indexOf(newRouteId) < 0)
      {
        this.updateNodeTreeRecursive(newNode.id);
      }
    },
    getFolderSearchResults(newResults) {
      if (newResults && newResults.length !== 0) {
        this.nodesPanel = 0;
      }
    },
    nodesPanel(newValue, oldValue) {
      if (oldValue && newValue === 5) {
        this.recycleBinTransitionPanel = oldValue;
      }
    },
    $route(to, from) {
      if (to.name === 'recycle-bin') {
        this.recycleBinTransitionRoute = from;
      } else if (from.name === 'recycle-bin' && (!this.recycleBinTransitionRoute || to.path === this.recycleBinTransitionRoute?.path)) {
        this.nodesPanel = this.recycleBinTransitionPanel;
        this.recycleBinTransitionRoute = null;
        this.recycleBinTransitionPanel = null;
      }
    }
  },
  methods: {
    async refreshAll() {
      if (!this.getRootNodeId) {
        return;
      }

      const promises = [
        this.getSubnodes(this.getRootNodeId, this.NODETYPES.CLIENT),
        this.getSubnodes(this.getRootNodeId, this.NODETYPES.PROJECT),
        this.getSubnodes(this.getRootNodeId, 'archived'),
        this.refreshRecycleBin(this.getRootNodeId),
      ];

      await Promise.all(promises);
    },
    async refreshRecycleBin(nodeId) {
      const REFRESHNODESLIST = this.$store.getters.keywords.RECYCLEBIN.REFRESHNODESLIST;
      const GETTENANTSETTINGS = this.$store.getters.keywords.TENANT.GETTENANTSETTINGS;
      const SETRETENTIONPERIOD = this.$store.getters.keywords.RECYCLEBIN.SETRETENTIONPERIOD;

      try {
        const {
          recycle_bin_retention_period: retentionPeriod
        } = await this.$store.dispatch(GETTENANTSETTINGS, this.getRootNodeId);
        this.$store.commit(SETRETENTIONPERIOD, retentionPeriod);

        const payload = {
          rootNodeId: this.getRootNodeId,
          silent: false,
        };

        return this.$store.dispatch(REFRESHNODESLIST, payload);
      } catch (error) {
        console.error(`Failed to refresh recycle bin info for node ${nodeId}`, error);
      }
    },
    getSubnodes(nodeId, type) {
      const GETSUBNODES = this.$store.getters.keywords.NODE.GETSUBNODES;
      const payload = {
        nodeid: nodeId,
        type: type,
      };

      try {
        return this.$store.dispatch(GETSUBNODES, payload);
      } catch (error) {
        console.error(`Error getting the subnodes of node Id ${nodeId}!`, error);
      }
    },
    showContextMenuEvent(event) {
      this.expansionMenu = false;

      this.showContextMenu = false;
      this.contextMenuNode = event.contextMenuNode;
      this.contextMenuX = event.contextMenuX;
      this.contextMenuY = event.contextMenuY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },
    showExpansionMenu(event) {
      this.showContextMenu = false;

      this.expansionMenu = false;
      this.expansionMenuX = event.clientX;
      this.expansionMenuY = event.clientY;

      this.$nextTick(() => {
        this.expansionMenu = true;
      });
    },
    showRecycleBinContextMenu(event) {
      this.recycleBinContextMenu = false;
      this.recycleBinContextMenuX = event.clientX;
      this.recycleBinContextMenuY = event.clientY;
      this.$nextTick(() => {
        this.recycleBinContextMenu = true;
      });
    },
    setNodeSort(sortString) {
      this.sortString = sortString;
    },
    async updateNodeTreeRecursive(nodeId) {
      // UpdateNodeTree Recursive

      this.nodeTreeIds = [];
      await this.updateNodePath(nodeId)

      // trigger the update on the nodes
      this.openedNodeIds = this.nodeTreeIds;
    },
    async updateNodePath(nodeId) {
      this.nodeTreeIds.push(nodeId);

      const GETNODEINFO = this.$store.getters.keywords.NODE.GETNODEINFO;
      let nodeData = await this.$store.dispatch(GETNODEINFO, nodeId);

      if (nodeData.parentid) {
        await this.updateNodePath(nodeData.parentid);
      }
    }
  },
  computed: {
    NODETYPES: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      return this.$store.getters[NODETYPES];
    },
    archiveStatus() {
      const ARCHIVESTATUS = this.$store.getters.keywords.COMMON.ARCHIVESTATUS;
      return this.$store.getters[ARCHIVESTATUS];
    },
    accessPoints() {
      const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
      return this.$store.getters[ACCESSPOINTS];
    },
    getCurrentNode() {
      const GETCURRENTNODE = this.$store.getters.keywords.NODE.GETCURRENTNODE;
      return this.$store.getters[GETCURRENTNODE];
    },
    getNodeById() {
      return function(nodeId) {
        const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
        return this.$store.getters[GETNODEBYID](nodeId);
      }
    },
    getChildrenById() {
      return function(parentId) {
        const GETCHILDRENBYID = this.$store.getters.keywords.NODE.GETCHILDRENBYID;
        return this.$store.getters[GETCHILDRENBYID](parentId);
      }
    },

    getRootNodeId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    getAccesspointChildren() {
      if (this.getRootNodeId) {
        return this.getChildrenById(this.getRootNodeId);
      }

      return [];
    },
    getFilteredChildren() {
      // if (this.searchText) {
      //   const searchLowerCase = this.searchText.toLowerCase();

      //   return this.getAccesspointChildren.filter(
      //     node => node.name.toLowerCase().includes(searchLowerCase)
      //   );
      // }

      return this.getAccesspointChildren;
    },
    getSortedChildren() {
      // First create shallow copy of the array and the sort
      return this.getFilteredChildren.slice().sort(this.getSortFunction);
    },


    getClientNodes() {
      return this.getNodesByType(this.NODETYPES.CLIENT).slice().sort(this.getSortFunction);
    },
    getProjectNodes() {
      return this.getNodesByType(this.NODETYPES.PROJECT).slice().sort(this.getSortFunction);
    },
    getNodesByType() {
      return function(typeId) {
        const GETNODESBYTYPE = this.$store.getters.keywords.NODE.GETNODESBYTYPE;
        return this.$store.getters[GETNODESBYTYPE](typeId);
      }
    },
    getArchivedNodes() {
      const GETARCHIVEDNODES = this.$store.getters.keywords.NODE.GETARCHIVEDNODES;
      const result = this.$store.getters[GETARCHIVEDNODES](this.getRootNodeId);

      return result;
    },

    getSortFunction() {
      if (this.sortString === "nameAsc") {
        return function(a, b) {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA > nameB)  return 1;
          if (nameA == nameB) return 0;
          return -1;
        }
      }

      if (this.sortString === "nameDesc") {
        return function(a, b) {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB)  return 1;
          if (nameA == nameB) return 0;
          return -1;
        }
      }

      if (this.sortString === "dateAsc") {
        return function(a, b) {
          const createdAtA = new Date(a.createdat);
          const createdAtB = new Date(b.createdat);

          if (createdAtA > createdAtB) return 1;
          if (createdAtA == createdAtB) return 0;
          return -1;
        }
      }

      if (this.sortString === "dateDesc") {
        return function(a, b) {
          const createdAtA = new Date(a.createdat);
          const createdAtB = new Date(b.createdat);

          if (createdAtA < createdAtB) return 1;
          if (createdAtA == createdAtB) return 0;
          return -1;
        }
      }

      return function() {return true};
    },

    getFolderSearchResults() {
      const GETFOLDERSEARCHRESULTS = this.$store.getters.keywords.SEARCH.GETFOLDERSEARCHRESULTS;
      return this.$store.getters[GETFOLDERSEARCHRESULTS];
    },
    getNodeIcon() {
      return function(node) {
        if (node.archived === 1 || node.archived === 2) {
          return 'mdi-package-down';
        }

        let iconName = 'mdi-folder';

        switch (node.type) {
          // case 1: iconName = 'mdi-folder'; break;
          case 2: iconName = 'mdi-briefcase-clock'; break;
          case 3: iconName = 'mdi-account-tie'; break;
          case 4: iconName = 'mdi-home-assistant'; break;
          default: iconName = 'mdi-folder'; break;
        }

        return iconName;
      }
    },
    isLoadingAccessPoints() {
      const ISLOADINGACCESSPOINTS = this.$store.getters.keywords.NODE.ISLOADINGACCESSPOINTS;
      return this.$store.getters[ISLOADINGACCESSPOINTS];
    },
    getRecycleBinPodsCount() {
      const GETALLPODS = this.$store.getters.keywords.RECYCLEBIN.GETALLPODS;
      return this.$store.getters[GETALLPODS].length;
    },
    getRecycleBinRootNodes() {
      const GETNODEBYID = this.$store.getters.keywords.RECYCLEBIN.GETNODEBYID;
      const GETCHILDNODES = this.$store.getters.keywords.RECYCLEBIN.GETCHILDNODES;

      const accessPointNode = this.$store.getters[GETNODEBYID](this.getRootNodeId);

      if (accessPointNode) {
        return this.$store.getters[GETCHILDNODES](accessPointNode.id);
      }

      return this.$store.getters[GETCHILDNODES](null);
    },
    getRecycleBinRetentionPeriod() {
      const GETRETENTIONPERIOD = this.$store.getters.keywords.RECYCLEBIN.GETRETENTIONPERIOD;
      return this.$store.getters[GETRETENTIONPERIOD];
    },
  }
}
</script>

<style>

/*
#node-expansion-panel {
}
*/

#node-expansion-panel > .v-expansion-panel {
  margin: 2px 0px;
  background-color: unset;
  border-radius: 0px;
}
/* #nodes-panel > div.v-expansion-panel.v-expansion-panel--active.v-item--active > button */
#node-expansion-panel > .v-expansion-panel > .v-expansion-panel-header {
  /* background-color: red !important; */
  padding: 16px !important;
  border-radius: 4px;

}

#node-expansion-panel .v-item--active > .v-expansion-panel-header {
  /* background-color: #3dabff; */
  color: #131313;
}

#node-expansion-panel .v-item--active .v-expansion-panel-header .v-icon {
  /* background-color: #3dabff; */
  color: #131313;
  border-radius: 4px;
}
</style>
