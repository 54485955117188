import API from '../AxiosInstance';

export default {
  getAllPods: function() {
    return API.get('pods');
  },

  getPodByID: function(podID) {
    return API.get('pods/'+podID);
  },

  getPodMeta: function(podID) {
    return API.get('pods/' + podID + "/meta");
  },

  getPodActions: function(podID) {
    return API.get('pods/' + podID + '/actionlogs');
  },

  getPodsByNodeID: function(nodeID) {
    return API.get('nodes/' + nodeID + '/pods');
  },

  createPod: function(data) {
    const body = data;
    return API.post('pods', body);
  },

  updatePod: function(data) {
    return API.patch('pods/' + data.id, data);
  },
  updatePodNote: function(data) {
    const body = {
      note: data.note
    };

    return API.patch('pods/' + data.podId + '/note', body);
  },

  transcodeFile: function(data) {
    const body = {};
    return API.post('pods/' + data.id +"/transcode", body);
  },

  extractArchive: function(data) {
    const body = {
      shouldDeletePod: data.shouldDeletePod,
    };

    return API.post('pods/' + data.id + '/extract', body);
  },

  generateAudio: function(data) {
    const body = {};
    return API.post('pods/' + data.id +"/audio", body);
  },

  deletePod: function(id) {
    const body = {};
    return API.delete('pods/' + id, body);
  },

  createPodAsset: function(data) {
    const body = data;
    return API.post('pods/' + data.podid + '/asset', body);
  },

  updatePodAsset: function(data) {
    const body = data.payload;
    return API.patch('pods/' + data.podID + '/assets/' + data.assetID, body);
  },

  deletePodAsset: function(podID, assetID) {
    const body = {};
    return API.delete('pods/' + podID + '/assets/' + assetID, body);
  },

  setAssetLanguage: function(podID, assetID, languageID) {
    const body = {
      language: languageID,
    };

    return API.patch(`/pods/${podID}/assets/${assetID}/languages`, body);
  },

  createSubtitleAsset: function(data) {
    const body = data.payload;
    return API.post('pods/' + data.podID + '/subtitles', body);
  },

  updateSubtitleAsset: async function(data) {
    const body = data.payload;
    return API.patch('pods/' + data.podID + '/subtitles/' + data.assetID, body);
  },

  promoteAssetToPod: function(data) {
    const body = data.payload;
    return API.post('/pods/' + data.podID + '/assets/' + data.assetID + '/promote', body);
  },

  moveManyPods: function(data) {
    const body = data;
    return API.patch('pods/move', body);
  },

  copyManyPods: function(data) {
    const body = data;
    return API.post('pods/copy', body);
  },
  convertSubtitle: function(data) {
    const body = data.payload;
    return API.post('/pods/' + data.podID + '/assets/' + data.assetID + '/convert', body);
  },

  addVersion: function(payload) {
    const body = {
      versionText: payload.versionText,
    };
    return API.patch(`pods/${payload.sourcePodId}/versions/${payload.targetPodId}`, body);
  },
  reorderVersions: function(payload) {
    const body = {
      podids: payload.podids,
    };
    return API.post(`pods/${payload.podid}/reorder`, body);
  },
  updateTeamOnly: function(data) {
    const body = {
      teamonly: data.teamonly,
    };

    return API.patch('pods/' + data.id + '/teamonly', body);
  },
  renameFile: function(data) {
    const body = {
      name: data.name,
    };

    return API.patch(`pods/${data.id}/rename`, body);
  },
};
