<template>
  <v-menu
  v-model="showMenu"
  offset-y
  >

    <template v-slot:activator="{on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot default></slot>
      </div>
    </template>

    <v-list>

      <TranscriptUpdateDialog
        v-bind:transcript="transcript"
        v-on:refreshSelectedTranscript="refreshSelectedTranscript"
      >
        <v-list-item @click="stub" data-cy="podMenu-transcript-actions-editTranscript">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon dense left>mdi-playlist-edit</v-icon>
              {{$t('routes.pod.menuTranscript.editTranscript')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </TranscriptUpdateDialog>

      <TranscriptDeleteDialog
        v-bind:transcript="transcript"
        v-on:refreshSelectedTranscript="refreshSelectedTranscript"
      >
        <v-list-item @click="stub" data-cy="podMenu-transcript-actions-deleteTranscript">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon dense left>mdi-playlist-remove</v-icon>
              {{$t('routes.pod.menuTranscript.deleteTranscript')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </TranscriptDeleteDialog>

      <TranscriptExportDialog v-bind:transcript="transcript">
        <v-list-item @click="stub" data-cy="podMenu-transcript-actions-exportTranscriptToFile">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon dense left>mdi-playlist-play</v-icon>
              {{$t('routes.pod.menuTranscript.exportTranscriptToFile')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </TranscriptExportDialog>

    </v-list>



  </v-menu>
</template>

<script>
import TranscriptUpdateDialog from './dialogs/TranscriptUpdateDialog.vue'
import TranscriptExportDialog from './dialogs/TranscriptExportDialog.vue'
import TranscriptDeleteDialog from './dialogs/TranscriptDeleteDialog.vue'

export default {
  name: "TranscriptContextMenu",
  components: {
    TranscriptUpdateDialog,
    TranscriptExportDialog,
    TranscriptDeleteDialog,
  },
  props: {
    transcript: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // dialog: false,
      showMenu: false,
    };
  },
  methods: {
    stub() {
      this.showMenu = false;
    },
    refreshSelectedTranscript(item) {
      this.$emit('refreshSelectedTranscript', item);
    },
  },
}
</script>

<style>
</style>