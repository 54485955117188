<template>
  <v-form ref="form" class="pod-comments-form" data-cy="commentForm-form" v-on:keydown.esc="hideForm">
    <div v-if="isFirstLevelComment" class="d-flex pod-comments-in-out justify-space-between">
      <div>
        <v-text-field
          v-model="inTime"
          v-bind:rules="inOutTimeInputRules"
          v-bind:label="$t('routes.pod.menuComment.inTime')"
          v-bind:append-icon="focusedInput === 'in' ? 'mdi-vector-link' : ''"
          v-on:focus="onInTimeFocus"
          v-on:click:append="focusedInput = ''"
          v-on:keydown.esc="hideForm"
          block dense solo flat
          data-cy="commentForm-inTime">
        </v-text-field>
      </div>
      <div class="d-flex justify-space-around">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              tile
              icon
              :aria-label="!inOutTimesLinked ? $t('routes.pod.menuComment.linkInOutTime') : $t('routes.pod.menuComment.unlinkInOutTime')"
              @click="inOutTimesLinked = !inOutTimesLinked"
            >
              <v-icon>{{ !inOutTimesLinked ? 'mdi-link' : 'mdi-link-off' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ !inOutTimesLinked ? $t('routes.pod.menuComment.linkInOutTime') : $t('routes.pod.menuComment.unlinkInOutTime') }}</span>
        </v-tooltip>
      </div>
      <div>
        <v-text-field
          v-model="outTime"
          v-bind:rules="inOutTimeInputRules.concat(outTimeComparison)"
          v-bind:label="$t('routes.pod.menuComment.outTime')"
          v-bind:append-icon="focusedInput === 'out' ? 'mdi-vector-link' : ''"
          v-on:focus="onOutTimeFocus"
          v-on:click:append="focusedInput = ''"
          v-on:keydown.esc="hideForm"
          block dense solo flat
          data-cy="commentForm-outTime">
        </v-text-field>
      </div>
    </div>


    <div class="d-flex">

      <div class="flex-grow-1">
        <v-textarea
          v-model="commentText"
          class="media-list-notes-textarea"
          :rules="commentTextRules"
          :error-messages="commentTextError"
          :placeholder="$t('routes.pod.menuComment.addComment')"
          @keydown.esc="hideForm"
          @input="onCommentInput"
          counter="2048"
          rows="3"
          required
          solo flat
          data-cy="commentForm-text"
        >
        </v-textarea>
      </div>

      <div class="ml-2 d-flex flex-column justify-center">

        <!-- Team only comments -->
        <v-tooltip v-if="$canBelongstoTeam()" bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile icon v-bind="attrs" v-on="on" v-on:click="teamonly = !teamonly" data-cy="">
              <v-icon v-if="!teamonly" small color="primary">mdi-shield-lock-open-outline</v-icon>
              <v-icon v-else small color="primary">mdi-shield-lock-outline</v-icon>
            </v-btn>
          </template>

          <span v-if="!teamonly">{{$t('routes.pod.menuComment.makeCommentTeamonly')}}</span>
          <span v-else>{{$t('routes.pod.menuComment.makeCommentPublic')}}</span>
        </v-tooltip>

        <!-- Save button -->
        <v-tooltip bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs" v-on="on"
              v-bind:disabled="isSubmitted"
              v-on:click="saveComment"
              tile icon
              data-cy="commentForm-save-btn"
            >
              <v-icon small color="primary">mdi-floppy</v-icon>
            </v-btn>
          </template>

          <span>{{$t('common.save')}}</span>
        </v-tooltip>

        <!-- Cancel button -->
        <v-tooltip bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile icon v-bind="attrs" v-on="on" v-on:click="hideForm" data-cy="commentForm-cancel-btn">
              <v-icon small color="primary">mdi-cancel</v-icon>
            </v-btn>
          </template>

          <span>{{$t('common.cancel')}}</span>
        </v-tooltip>
      </div>
    </div>

    <!-- <div class="d-flex justify-space-between">
      <div>
        <v-checkbox
          v-if="$canBelongstoTeam()"
          v-model="teamonly"
          v-bind:label="$t('routes.pod.menuComment.teamOnly')"
          dense
          class="mt-0"
          hide-details
        ></v-checkbox>
      </div>

      <div>
        <v-tooltip bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile icon v-bind="attrs" v-on="on" v-on:click="hideForm" data-cy="commentForm-cancel-btn">
              <v-icon small color="primary">mdi-cancel</v-icon>
            </v-btn>
          </template>

          <span>{{$t('common.cancel')}}</span>
        </v-tooltip>


        <v-tooltip bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile icon v-bind="attrs" v-on="on" v-on:click="saveComment" data-cy="commentForm-save-btn">
              <v-icon small color="primary">mdi-floppy</v-icon>
            </v-btn>
          </template>

          <span>{{$t('common.save')}}</span>
        </v-tooltip>
      </div>
    </div> -->
  </v-form>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PodCommentForm',
  props: {
    editComment: {
      type: Object,
      required: false,
    },
    replyComment: {
      type: Object,
      required: false,
    }
  },
  data: function() {
    let self = this;
    return {
      commentText: "",
      commentTextError: "",
      commentTextRules: [
        v => !!v || this.$t('routes.pod.menuComment.commentIsRequired'),
      ],

      isSubmitted: false,
      inOutTimesLinked: false,
      teamonly: false,
      focusedInput: "",
      inTime: "",
      outTime: "",
      inOutTimeInputRules: [
        function(v) {
          if (!v) return true;
          if (!/^\d{2,}:\d{2}\.\d{2}$/g.test(v)) return self.$t('routes.pod.menuComment.invalidFormat');
          let seconds = v.split(".")[0].split(":")[1];
          if (parseInt(seconds) >= 60) return self.$t('routes.pod.menuComment.invalidSeconds');
          let videoDuration = (self.transcodedAsset && self.transcodedAsset.duration) ? parseFloat(self.transcodedAsset.duration) : 0;
          if (!videoDuration) return true;
          if ((self.videotimeToInt(v) / 1000) > videoDuration) return self.$t('routes.pod.menuComment.incorrectTime');
          return true;
        }
      ],
    };
  },
  mounted: function() {
    if (this.editComment) {
      this.commentText = this.editComment.content;
      this.teamonly = this.editComment.teamonly;

      if (this.editComment.start !== null) {
        this.inTime = Vue.filter("toTimeMilliseconds")(this.editComment.start);
      }

      if (this.editComment.end) {
        this.outTime = Vue.filter("toTimeMilliseconds")(this.editComment.end);
      }
    }

    if (this.replyComment) {
      this.inTime = Vue.filter("toTimeMilliseconds")(this.replyComment.start);
      if (this.replyComment.end) {
        this.outTime = Vue.filter("toTimeMilliseconds")(this.replyComment.end);
      }
    }

    if (!this.editComment && !this.replyComment) {
      this.inTime = Vue.filter("toTimeMilliseconds")(this.playerCURRENTTIME);
      this.outTime = "";
      // this.outTime = Vue.filter("toTimeMilliseconds")(this.playerCURRENTTIME + 2);
    }

    const INCREASEVISIBLEFORMS = this.$store.getters.keywords.POD.COMMENT.INCREASEVISIBLEFORMS;
    this.$store.dispatch(INCREASEVISIBLEFORMS);
  },
  beforeDestroy: function() {
    const DECREASEVISIBLEFORMS = this.$store.getters.keywords.POD.COMMENT.DECREASEVISIBLEFORMS;
    this.$store.dispatch(DECREASEVISIBLEFORMS);
  },
  methods: {
    videotimeToInt: function(value) {
      let minutes = value.split(":")[0];
      let seconds = value.split(".")[0].split(":")[1];
      let milliseconds = value.split(".")[1];

      milliseconds = parseInt(parseFloat("0." + milliseconds) * 1000);
      seconds = parseInt(seconds) * 1000;
      minutes = parseInt(minutes) * 60000;
      return minutes + seconds + milliseconds;
    },
    hideForm: function() {
      this.$emit('visibility', false);
    },
    onCommentInput: function() {
      if (this.playerISPLAYING) {
        const SETPAUSEEVENT = this.$store.getters.keywords.PLAYER.SETPAUSEEVENT;
        this.$store.commit(SETPAUSEEVENT, this.playerCURRENTTIME);
      }
    },
    onInTimeFocus: function () {
      this.focusedInput = 'in';

      if (this.inTime && !this.playerISPLAYING) {
        const time = this.videotimeToInt(this.inTime) / 1000;
        const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
        this.$store.commit(SETSEEKTIMEEVENT, time);
      }
    },
    onOutTimeFocus: function () {
      this.focusedInput = 'out';

      if (this.outTime && !this.playerISPLAYING) {
        const time = this.videotimeToInt(this.outTime) / 1000;
        const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
        this.$store.commit(SETSEEKTIMEEVENT, time);
      }
    },
    updateFocusedInput: function(input) {
      this.focusedInput = input;
    },
    saveComment: function() {
      const validForm = this.$refs.form.validate();
      this.commentTextError = "";
      if (!validForm) {
        // this.commentTextError = "Invalid data!";
        return;
      }

      if (this.isSubmitted) {
        return;
      }

      const REFRESHCOMMENTS = this.$store.getters.keywords.POD.COMMENT.REFRESHCOMMENTS;
      const CREATECOMMENT = this.$store.getters.keywords.POD.COMMENT.CREATECOMMENT;
      const UPDATECOMMENT = this.$store.getters.keywords.POD.COMMENT.UPDATECOMMENT;
      let VERB = CREATECOMMENT;
      let self = this;

      const data = {
        podID: this.pod.id,
        payload: {
          start: this.inTime ? this.videotimeToInt(this.inTime) : null,
          end: this.outTime ? this.videotimeToInt(this.outTime) : null,
          content: this.commentText,
          teamonly: this.$canBelongstoTeam() ? this.teamonly : false,
          // parentid: // parent comment id
        }
      };

      if (this.replyComment) {
        data.payload.parentid = this.replyComment.id;
      }

      if (this.editComment) {
        data.commentID = this.editComment.id;
        data.payload.parentid = this.editComment.parentid;
        VERB = UPDATECOMMENT;
      }

      this.isSubmitted = true;
      this.$store.dispatch(VERB, data)
      .then(function() {
        self.hideForm();
        return self.$store.dispatch(REFRESHCOMMENTS, self.pod.id);
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.commentTextError = error.response.data.error.message;
        } else {
          self.commentTextError = "Error!";
          console.log(error);   // unhandled error
        }

        self.isSubmitted = false;
      });
    }
  },
  computed: {
    outTimeComparison: function () {
      if (this.outTime && (this.videotimeToInt(this.inTime) >= this.videotimeToInt(this.outTime))) {
        return this.$t('routes.pod.menuComment.invalidOutTime');
      }
      return true;
    },
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    rawAsset: function() {
      if (this.pod &&
          this.pod.asset &&
          this.pod.asset.raw &&
          this.pod.asset.raw[0]) {
        return this.pod.asset.raw[0];
      }
      return null;
    },
    transcodedAsset: function() {
      if (!this.pod ||
          !this.pod.asset ||
          !this.pod.asset.web ||
          !Array.isArray(this.pod.asset.web)) {
        return null;
      }

      // Get first web asset with status COMPLETE
      for(let i = 0; i < this.pod.asset.web.length; i++) {
        if (this.pod.asset.web[i].state == this.getPodStates.COMPLETE) {
          return this.pod.asset.web[i];
        }
      }

      return null;
    },
    isFirstLevelComment: function() {
      if (!this.editComment && !this.replyComment) {
        return true;
      }

      if (this.editComment && !this.editComment.parentid) {
        return true;
      }

      return false;
    },
    playerCURRENTTIME: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    playerISPLAYING: function() {
      const ISPLAYING = this.$store.getters.keywords.PLAYER.ISPLAYING;
      return this.$store.getters[ISPLAYING];
    },
    getPodStates: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
    getCloseForms: function() {
      const GETCLOSEFORMSEVENT = this.$store.getters.keywords.POD.COMMENT.GETCLOSEFORMSEVENT;
      return this.$store.getters[GETCLOSEFORMSEVENT];
    }
  },
  watch: {
    playerCURRENTTIME: function(newTime) {
      const addingNewComment = !this.editComment && !this.replyComment;

      if (this.focusedInput === "in" || (addingNewComment && this.focusedInput !== "out")) {
        this.inTime = Vue.filter("toTimeMilliseconds")(newTime);
        if (this.outTime && this.inOutTimesLinked) {
          this.outTime = Vue.filter("toTimeMilliseconds")(newTime + 2);
        }
      }

      if (this.focusedInput === "out") {
        this.outTime = Vue.filter("toTimeMilliseconds")(newTime);
      }
    },
    getCloseForms: function() {
      // close the form
      this.hideForm();
    },
  }
}
</script>

<style>
.pod-comments-form {
  padding: 10px;
  border-radius: 20px;
}

.theme--light .pod-comments-form {
  background-color: #FFFFFF;
}
.theme--dark .pod-comments-form {
  background-color: #232b36;
}

.pod-comments-in-out > div { width: 33%;}
</style>
