<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog">
      <v-card>
        <v-card-title class="d-flex justify-space-between primary">
          <span>{{ $t('routes.recycleBin.setRetentionTime') }}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="form" v-on:submit.prevent="saveRetentionPeriod()">
          <v-card-text class="pt-5">
            <v-text-field
              v-model="currentRetentionPeriod"
              :rules="currentRetentionPeriodRules"
              :label="$t('routes.recycleBin.retentionPeriodDays')"
              @update:error="(val) => isRetentionPeriodValid = !val"
              required
              block
              dense
              solo
              flat
            />

            <div v-if="errorMessage" class="error">
              {{ errorMessage }}
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary" :disabled="inProgress || !isRetentionPeriodValid" class="btnOk">{{ $t('common.save') }}</v-btn>
            <v-btn text @click="dialog = false" class="btnCancel">{{ $t('common.cancel') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "RecycleBinSetRetentionPeriodDialog",
  props: {
    retentionPeriod: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      dialog: false,
      errorMessage: '',
      inProgress: false,
      isRetentionPeriodValid: true,

      currentRetentionPeriod: this.$props.retentionPeriod,
      currentRetentionPeriodRules: [
        (val) => {
          const parsedValue = Number(val);
          return !Number.isSafeInteger(parsedValue) || parsedValue <= 0 || parsedValue > 180
            ? this.$t('routes.recycleBin.invalidRetentionPeriod')
            : true;
        }
      ],
    };
  },
  watch: {
    dialog: function() {
      this.errorMessage = '';
      this.inProgress = false;
      this.currentRetentionPeriod = this.retentionPeriod;
    },
  },
  methods: {
    saveRetentionPeriod: async function () {
      this.inProgress = true;

      if (!this.$refs.form.validate()) {
        return;
      }

      const UPDATETENANTSETTINGS = this.$store.getters.keywords.TENANT.UPDATETENANTSETTINGS;
      const SETRETENTIONPERIOD = this.$store.getters.keywords.RECYCLEBIN.SETRETENTIONPERIOD;
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;

      const rootNodeId = this.$store.getters[ROOTNODEID];
      const retentionPeriod = Number(this.currentRetentionPeriod);

      try {
        await this.$store.dispatch(UPDATETENANTSETTINGS, {
          accessPointId: rootNodeId,
          settings: {
            recycle_bin_retention_period: retentionPeriod,
          },
        });

        this.$store.commit(SETRETENTIONPERIOD, retentionPeriod);
        this.dialog = false;
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          this.errorMessage = error.response.data.error.message;
        } else {
          this.errorMessage = this.$t('common.generalError');
        }
      } finally {
        this.inProgress = false;
      }
    }
  },
}
</script>

<style scoped>

</style>
