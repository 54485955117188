import API from '../AxiosInstance';

export default {
  getUsers: function(podId) {
    const body = {};
    return API.get(`pods/${podId}/reviewers/users`, body);
  },
  getReviewers: function(podId) {
    const body = {};
    return API.get(`pods/${podId}/reviewers`, body);
  },
  inviteReviewers: function(podId, payload) {
    const body = payload;

    return API.post(`pods/${podId}/reviewers/invite`, body);
  }
};
