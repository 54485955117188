<template>
  <div>
    <v-snackbar
      v-for="snack in getSnackbars"
      v-bind:key="snack.id"
      v-model="showSnackbar"
      v-bind:timeout="-1"
      v-bind:color="snack.color"
      style="z-index: 2000; margin-top: 70px; cursor: pointer;"
      top right
      >
      <span v-if="snack.message" v-on:click="clickSnackbar(snack)"> {{snack.message}} </span>
      <span v-if="snack.i18n" v-on:click="clickSnackbar(snack)"> {{$t(snack.i18n)}} </span>
      <span v-if="snack.replaceTCode" v-on:click="clickSnackbar(snack)">
      {{ $replaceT(snack.replaceTCode, snack.replaceTParams, snack.replaceTFallback) }}
      </span>


      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="closeSnackbar(snack)"
          data-cy="snackbar-close-btn"
        >
          <v-icon tile small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackbarsComponent",
  data: function() {
    return {
      showSnackbar: true,
    };
  },
  methods: {
    closeSnackbar: function(snack) {
      const REMOVESNACKBAR = this.$store.getters.keywords.MAIN.REMOVESNACKBAR;
      this.$store.commit(REMOVESNACKBAR, snack);
    },
    clickSnackbar: function(snack) {
      if (snack.showLimitsOnClick) {
        this.showLimitsOnClick(snack);
      }

      if (snack.showPodAssetsOnClick) {
        this.showPodAssetsOnClick(snack);
      }
    },

    showLimitsOnClick: function(snack) {
      if (!this.$canControlTenantConfig()) {
        return;
      }

      this.$router.push(`/console/subscription-plans`);
      this.closeSnackbar(snack);
    },
    showPodAssetsOnClick(snack) {
      this.$router.push(`/pod/${snack.pod.id}?menu=assets`);
      this.closeSnackbar(snack);
    }
  },
  computed: {
    getSnackbars: function() {
      const GETSNACKBARS = this.$store.getters.keywords.MAIN.GETSNACKBARS;
      return this.$store.getters[GETSNACKBARS];
    },
  }
}
</script>

<style>

</style>
