<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podFiles-tree-actions-exportAsset-activator">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-exportAsset-modal">
      <v-card-title class="primary d-flex justify-space-between">
        {{$t('routes.pod.exportAssetToPodTitle')}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text data-cy="dialog-exportAsset-text">
        <div class="body-1 mt-2">{{$t('routes.pod.exportAssetToPodDialogText').replace('{filename}', asset.name)}}</div>

        <div v-if="errorMessage" class="error mt-2" data-cy="dialog-exportAsset-error">
          {{errorMessage}}
        </div>

        <div class="error" v-if="errorTranslatable">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="promoteToPod" data-cy="dialog-exportAsset-ok-btn">{{$t('routes.pod.export')}}</v-btn>
        <v-btn text @click="dialog = false" data-cy="dialog-exportAsset-cancel-btn">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "ExportAssetDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: "",
      errorTranslatable: null,
    }
  },
  watch: {
    dialog: function(newValue) {
      if (newValue) {
        this.errorMessage = "";
      }
    }
  },
  methods: {
    promoteToPod: function() {
      this.errorMessage = "";
      this.errorTranslatable = null;
      const PROMOTETOPOD = this.$store.getters.keywords.POD.PROMOTETOPOD;

      let self = this;
      this.$store.dispatch(PROMOTETOPOD, this.asset)
      .then(function() {
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          // self.errorMessage = error.response.data.error.message;
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
  },
}
</script>

<style>

</style>
