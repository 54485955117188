import { parseSync, stringifySync } from 'subtitle'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    isPlaying: false,
    isSeeking: false,
    duration: 1,
    volume: 1,
    muted: false,
    speed: 1,
    fullscreen: false,

    currentTime: 0,
    seekTimeEvent: null,
    pauseEvent: null,

    activeSubtitle: null,
    vttSubtitles: [],
    srtSubtitles: [],

    activeLayer: 0,
    layersOptions: {
      COMMENTS: {},
      AWSDETECTEDTEXT: {},
      AWSDETECTEDLABELS: {},
    },
  },
  getters: {
    isPlaying: function(state) {
      return state.isPlaying;
    },
    isSeeking: function(state) {
      return state.isSeeking;
    },
    duration: function(state) {
      return state.duration;
    },
    volume: function(state) {
      return state.volume;
    },
    muted: function(state) {
      return state.muted;
    },
    speed: function(state) {
      return state.speed;
    },
    fullscreen: function(state) {
      return state.fullscreen;
    },

    currentTime: function(state) {
      return state.currentTime;
    },
    getSeekTimeEvent: function(state) {
      return state.seekTimeEvent;
    },
    getPauseEvent: function(state) {
      return state.pauseEvent;
    },
    getActiveSubtitle: function(state, getters, rootState, rootGetters) {
      getters; rootState;
      const GETDYNAMICVTTFILES = rootGetters.keywords.POD.TRANSCRIPT.GETDYNAMICVTTFILES;
      const dynamicVTTFiles = rootGetters[GETDYNAMICVTTFILES];

      for (let i = 0; i < dynamicVTTFiles.length; i++) {
        if (
          state.activeSubtitle &&
          state.activeSubtitle.isTranscript &&
          state.activeSubtitle.transcriptId == dynamicVTTFiles[i].transcriptId &&
          state.activeSubtitle.url != dynamicVTTFiles[i].url
        ) {
          return dynamicVTTFiles[i];
        }
      }

      return state.activeSubtitle;
    },
    availableSubtitles: function(state, getters, rootState, rootGetters) {
      getters; rootState;
      let availableSubs = [];

      availableSubs = availableSubs.concat(state.vttSubtitles).concat(state.srtSubtitles);

      availableSubs.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });

      const GETDYNAMICVTTFILES = rootGetters.keywords.POD.TRANSCRIPT.GETDYNAMICVTTFILES;
      const dynamicVTTFiles = rootGetters[GETDYNAMICVTTFILES];

      for (let i = 0; i < dynamicVTTFiles.length; i++) {
        availableSubs.push(dynamicVTTFiles[i])
      }

      if (availableSubs.length > 0) {
        availableSubs.push({label: "Off", language: 'en', url: null});
      }

      // console.log("Available subs", availableSubs);
      return availableSubs;
    },
    getLayersOptions: function(state) {
      return state.layersOptions;
    },
    getActiveLayer: function(state) {
      return state.activeLayer;
    },
    getAvailableLayers: function(state, getters, rootState, rootGetters) {
      getters; rootState;
      let availableLayers = {};

      const GETCOMMENTS = rootGetters.keywords.POD.COMMENT.GETCOMMENTS;
      const GETMLLABELS = rootGetters.keywords.POD.LABEL.GETMLLABELS;

      // TODO: filter the ML labels by type - text, label, face...

      GETCOMMENTS;
      // Uncomment this to enable comments layer
      // if (rootGetters[GETCOMMENTS]) {
      //   availableLayers.COMMENTS = 1;
      // }

      const mlLabels = rootGetters[GETMLLABELS] || [];

      if (mlLabels.find(x => x.service == 'aws-detecting-text')) {
        availableLayers.AWSDETECTEDTEXT = 2;
      }

      if (mlLabels.find(x => x.service == 'aws-detecting-labels')) {
        availableLayers.AWSDETECTEDLABELS = 3;
      }

      return availableLayers;
    }
  },
  mutations: {
    setIsPlaying: function (state, value) {
      state.isPlaying = value;
    },
    setIsSeeking: function (state, value) {
      state.isSeeking = value;
    },
    setDuration: function(state, duration) {
      state.duration = duration;
    },
    setVolume: function(state, volume) {
      state.volume = volume;
    },
    setMuted: function(state, muted) {
      state.muted = muted;
    },
    setSpeed: function(state, speed) {
      state.speed = speed;
    },
    setFullscreen: function(state, fullscreen) {
      state.fullscreen = fullscreen;
    },
    setCurrentTime: function(state, time) {
      state.currentTime = time;
    },
    setSeekTimeEvent: function(state, time) {
      state.seekTimeEvent = time;
    },
    setPauseEvent: function(state, time) {
      state.pauseEvent = time;
    },
    setActiveSubtitle: function(state, subtitle) {
      state.activeSubtitle = subtitle && subtitle.url ? subtitle : null;
    },
    resetPlayer: function(state) {
      state.isPlaying = false;
      state.isSeeking = false;
      state.duration = 1;
      state.volume = 1;
      state.muted = false;
      state.fullscreen = false;

      state.currentTime = 0;
      state.seekTimeEvent = null;
      state.pauseEvent = null;
      state.activeSubtitle = null;
      state.activeLayer = 0;
    },
    setVTTSubtitles: function(state, subtitles) {
      state.vttSubtitles = subtitles;
    },
    setSRTSubtitles: function(state, subtitles) {
      // revoke old objects to avoid memory leaks
      for (let i = 0; i < state.srtSubtitles.length; i++) {
        URL.revokeObjectURL(state.srtSubtitles[i].url);
      }

      state.srtSubtitles = subtitles;
    },
    refreshActiveSubtitle: function(state) {
      if (!state.activeSubtitle) return;

      if (state.activeSubtitle.id) {
        let subtitleAssets = [].concat(state.vttSubtitles).concat(state.srtSubtitles);
        state.activeSubtitle = subtitleAssets.find(x => x.id == state.activeSubtitle.id);
        return;
      }

      if (state.activeSubtitle.isTranscript) {
        // handled in the getter getActiveSubtitle
        return;
      }

      state.activeSubtitle = null;
    },
    setActiveLayer: function(state, layer) {
      if (state.activeLayer === layer) {
        state.activeLayer = 0;
      } else {
        state.activeLayer = layer;
      }
    },
    setLayersOptions: function(state, options) {
      for (let layerName in state.layersOptions) {
        if (state.layersOptions[layerName] &&
            options[layerName] &&
            typeof state.layersOptions[layerName] == 'object' &&
            typeof options[layerName] == 'object'
        ) {
          state.layersOptions[layerName] = options[layerName];
        }
      }
    }
  },
  actions: {
    refreshSubtitles: async function(context, pod) {
      // console.log("PlayerStore: Refresh subtitles action", pod);

      let prom1 = context.dispatch("refreshVTTSubtitles", pod);
      let prom2 = context.dispatch("refreshSRTSubtitles", pod);

      await Promise.all([prom1, prom2]);
      context.commit('refreshActiveSubtitle');
    },

    refreshVTTSubtitles: function(context, pod) {
      const ASSETSTATES = context.rootGetters.keywords.COMMON.ASSETSTATES;
      const STATECOMPLETE = context.rootGetters[ASSETSTATES].COMPLETE;

      let vttSubtitles = [];

      if (pod && pod.asset && pod.asset.vtt && pod.asset.vtt.length > 0) {
        for (let i = 0; i < pod.asset.vtt.length; i++) {
          if (pod.asset.vtt[i].state !== STATECOMPLETE) {
            continue;
          }

          let sub = {
            id: pod.asset.vtt[i].id,
            label: pod.asset.vtt[i].name,
            language: 'en',   // what else?
            url: pod.asset.vtt[i].url
          };
          vttSubtitles.push(sub);   // maybe do some kind of validation
        }
      }

      context.commit("setVTTSubtitles", vttSubtitles);
    },
    refreshSRTSubtitles: async function(context, pod) {
      const ASSETSTATES = context.rootGetters.keywords.COMMON.ASSETSTATES;
      const STATECOMPLETE = context.rootGetters[ASSETSTATES].COMPLETE;

      let srtSubtitles = [];
      let promises = [];

      if (pod && pod.asset && pod.asset.srt && pod.asset.srt.length > 0) {
        for (let i = 0; i < pod.asset.srt.length; i++) {
          if (!pod.asset.srt[i].url || pod.asset.srt[i].state !== STATECOMPLETE) {
            promises.push(new Promise(function(resolve) { return resolve(false); }));
            continue;
          }
          promises.push(axios.get(pod.asset.srt[i].url));
        }
      }

      // get the content of the SRT files and convert it to VTT
      let results = await Promise.all(promises);

      if (pod && pod.asset && pod.asset.srt && pod.asset.srt.length > 0) {
        for (let p = 0; p < pod.asset.srt.length; p++) {
          if (!results[p] || results[p].data === undefined)
            continue;
          let cuez = parseSync(results[p].data);
          let vttData = stringifySync(cuez, {format: 'WebVTT'});

          let blob = new Blob([vttData], {type: 'text/vtt'});
          let url = URL.createObjectURL(blob);

          let sub = {
            id: pod.asset.srt[p].id,
            label: pod.asset.srt[p].name,
            language: 'en',   // what else?
            url: url
          };
          srtSubtitles.push(sub);
        }
      }

      context.commit("setSRTSubtitles", srtSubtitles);
    },
    setActiveLayer: function(context, layer) {
      context.commit('setActiveLayer', layer);
    }
  }
}
