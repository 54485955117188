class GoogleReCaptcha {
  /**
   * @typedef {{
   *   siteKey: string,
   * }} PluginOptions
   */

  /**
   * Installs the plugin in current Vue instance.
   *
   * @param {Vue} Vue
   * @param {PluginOptions} options
   */
  static install(Vue, options) {
    const instance = new GoogleReCaptcha(options);
    const loadReCaptchaPromise = instance.initReCaptcha(options);

    Vue.prototype.$recaptchaLoaded = function () {
      return loadReCaptchaPromise;
    };

    Vue.prototype.$recaptcha = async function (action) {
      await this.$recaptchaLoaded();
      return instance.execute(action);
    };

    Vue.prototype.$recaptchaShowBadge = function () {
      instance.showBadge();
    };

    Vue.prototype.$recaptchaHideBadge = function () {
      instance.hideBadge();
    };

    Vue.prototype.$recaptchaOnError = function (callback) {
      instance.on('error', callback);
    };

    Vue.prototype.$recaptchaOnResponseExpire = function (callback) {
      instance.on('response-expire', callback);
    };
  }

  /**
   * @param {PluginOptions} options
   */
  constructor(options) {
    this.isLoading = false;
    this.isLoaded = false;
    /** @type {HTMLStyleElement} */
    this.styleContainer = null;
    this.reCaptchaId = null;

    Object.defineProperties(this, {
      options: { value: Object.freeze(options) },
      errorCallbackName: { value: `__grecaptcha_error_cb_${this.randomUUID()}` },
      expiredResponseCallbackName: { value: `__grecaptcha_expired_res_cb_${this.randomUUID()}` },
      onErrorCallbacks: { value: [] },
      onResponseExpireCallbacks: { value: [] },
    });
  }

  hideBadge() {
    if (!this.styleContainer) {
      this.styleContainer = document.createElement('style');
      this.styleContainer.innerText = '.grecaptcha-badge{ visibility:hidden !important; }';
    }

    document.head.appendChild(this.styleContainer);
  }

  showBadge() {
    if (this.styleContainer) {
      document.head.removeChild(this.styleContainer);
    }
  }

  initReCaptcha() {
    if (this.isLoading) throw new Error('reCaptcha is already loading');
    if (this.isLoaded) throw new Error('reCaptcha is already loaded');

    this.isLoading = true;

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
      script.addEventListener('error', () => {
        reject(new Error('ReCaptcha script has failed to load!'));
      });
      script.addEventListener('load', () => {
        this.waitForScriptToLoad().then(() => {
          this.renderReCaptcha();
          this.isLoaded = true;
          resolve();
        });
      });

      document.head.appendChild(script);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  renderReCaptcha() {
    const instance = this;

    Object.defineProperties(window, {
      [this.expiredResponseCallbackName]: {
        value: function() {
          for (const callback of instance.onResponseExpireCallbacks) {
            callback();
          }
        }
      },
      [this.errorCallbackName]: {
        value: function () {
          for (const callback of instance.onErrorCallbacks) {
            callback();
          }
        },
      },
    });

    this.reCaptchaId = window.grecaptcha.render({
      'sitekey': this.options.siteKey,
      'expired-callback': this.expiredResponseCallbackName,
      'error-callback': this.errorCallbackName,
    });
  }

  execute(action) {
    console.assert(this.reCaptchaId !== null, 'ReCAPTCHA is still not rendered!');
    return window.grecaptcha.execute(this.reCaptchaId, { action });
  }

  waitForScriptToLoad() {
    return new Promise((resolve) => {
      const pollWindowIntervalHandle = setInterval(() => {
        if (typeof window.grecaptcha !== 'undefined') {
          window.grecaptcha.ready(() => {
            resolve();
          });

          clearInterval(pollWindowIntervalHandle);
        }
      }, 25);
    });
  }

  randomUUID() {
    if (window.crypto && typeof window.crypto.randomUUID === 'function') {
      return window.crypto.randomUUID().replace(/-/g, '');
    }

    // Next best thing that could serve as "random" UUID
    let uuid = '';
    while (uuid.length <= 36) {
      uuid += Math.random().toString(16).substr(2, 9);
    }

    return uuid.slice(0, 36);
  }

  on(type, callback) {
    console.assert(typeof callback === 'function');

    switch (type) {
      case 'error':
        this.onErrorCallbacks.push(callback);
        break;
      case 'response-expire':
        this.onResponseExpireCallbacks.push(callback);
        break;
      default:
        throw new Error(`Unsupported event type '${type}'`);
    }
  }
}

export default GoogleReCaptcha;
