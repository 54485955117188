import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import router from "../routes/router";
import store from "../store/store";


const baseUrl = window.location.origin;
// const baseUrl = "http://dev.blackbear.bg";


window.regenerateCancelTokenSource = function() {
  window.cancelTokenSource = axios.CancelToken.source();
}



class AxiosSingleton {
  constructor() {
    if (!AxiosSingleton.instance) {
      this.axiosInstance = axios.create({
        baseURL: baseUrl + '/api/v1/', // Replace with your base URL
        // timeout: 1000,                      // Optional: set a timeout for requests
        headers: {
          // Optional: set default headers
          // 'X-Custom-Header': 'foobar'
        }
      });

      this.insertAuthCredentials();
      this.makeRequestsCancellable();

      this.handleContext();
      this.handleCorrectResponse();

      this.handleUnauthenticated();
      this.handleForbiddenNotFound();
      this.handleRatelimitExceeded();
      this.handleBandwidthExceeded();

      AxiosSingleton.instance = this;
    }

    return AxiosSingleton.instance;
  }

  insertAuthCredentials() {
    this.axiosInstance.interceptors.request.use(
      async function(config){

        try {
          let session = await fetchAuthSession();

          if (session && session.tokens) {
            config.headers['id-token'] = session.tokens.idToken.toString();
            config.headers['access-token'] = session.tokens.accessToken.toString();
          }
        } catch (error) {
          return config;
        }

        return config;
      }
    );
  }

  makeRequestsCancellable() {
    window.regenerateCancelTokenSource();

    this.axiosInstance.interceptors.request.use(function(request) {
      request.cancelToken = window.cancelTokenSource.token;
      return request;
    });
  }

  handleContext() {
    this.axiosInstance.interceptors.response.use(function(data) {
      if (
          data &&
          data.data &&
          data.data.context &&
          data.data.context.rootnodeid &&
          data.data.context.rootnodeid != window.localStorage.contextnodeid
        ) {
        // store the context node and use it later to evaluate the permissions
        // the context node id is also updated in the AuthStore.js file
        window.localStorage.contextnodeid = data.data.context.rootnodeid;
      }

      return Promise.resolve(data);
    });
  }

  handleCorrectResponse() {
    this.axiosInstance.interceptors.response.use(function(response) {
      if (response && response.data) {
        return Promise.resolve(response.data);
      }

      return Promise.resolve(response);
    });
  }


  // register interceptor for responses with status 401
  handleUnauthenticated() {
    this.axiosInstance.interceptors.response.use(undefined, function(error) {
      if (error && error.response && error.response.status == 401 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code == 1011) {
        if (!router.currentRoute.path.toLowerCase().startsWith('/account/login')) {
          router.push("/account/login");
        }
      }

      if (error && error.response && error.response.status == 401 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code == 1012) {
        if (!router.currentRoute.path.toLowerCase().startsWith('/expired')) {
          router.push("/expired");
        }
      }

      return Promise.reject(error);
    });
  }

  handleForbiddenNotFound() {
    this.axiosInstance.interceptors.response.use(undefined, function(error) {
      const CREATESNACKBAR = store.getters.keywords.MAIN.CREATESNACKBAR;
      if (error && error.response && error.response.status == 403) {
        const snack = {
          i18n: "common.snackbars.resourceForbidden",
          color: "error",
        };

        store.commit(CREATESNACKBAR, snack);
      }

      if (error && error.response && error.response.status == 404) {
        if (!error?.response?.data?.error?.message) {
          const snack = {
            i18n: "common.snackbars.resourceNotFound",
            color: "error",
          };

          store.commit(CREATESNACKBAR, snack);
        }
      }

      return Promise.reject(error);
    });
  }

  handleRatelimitExceeded() {
    this.axiosInstance.interceptors.response.use(undefined, function(error) {
      const CREATESNACKBAR = store.getters.keywords.MAIN.CREATESNACKBAR;
      if (error && error.response && error.response.status == 429) {
        const snack = {
          i18n: "common.snackbars.ratelimitExceeded",
          color: "error",
        };

        store.commit(CREATESNACKBAR, snack);
      }

      return Promise.reject(error);
    });
  }

  handleBandwidthExceeded() {
    this.axiosInstance.interceptors.response.use(undefined, function(error) {
      if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status == 509 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.status &&
          error.response.data.error.status == 509
        ) {

          if (!router.currentRoute.path.toLowerCase().startsWith('/account/bandwidth-exceeded')) {
            router.push("/account/bandwidth-exceeded");
          }
      }

      return Promise.reject(error);
    });
  }

  getInstance() {
    return this.axiosInstance;
  }
}

// Export a singleton instance
const instance = new AxiosSingleton();
Object.freeze(instance);
export default instance.getInstance();
