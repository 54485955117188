import API from '../AxiosInstance';

export default {
  getLabels: function(podID) {
    return API.get('pods/'+podID+'/labels');
  },

  createLabel: function(podID, payload) {
    return API.post('pods/'+ podID+'/labels', payload);
  },

  updateLabel: function(podID, labelID, payload) {
    return API.patch('pods/' + podID + '/labels/' + labelID, payload);
  },

  deleteLabel: function(podID, labelID) {
    return API.delete('pods/' + podID + '/labels/' + labelID);
  },

  getMlLabels: function(podID) {
    return API.get('pods/'+podID+'/mllabels');
  },

  updateMlLabel: function(podID, labelID, payload) {
    return API.patch('pods/' + podID + '/mllabels/' + labelID, payload);
  },

  deleteMlLabel: function(podID, labelID) {
    return API.delete('pods/' + podID + '/mllabels/' + labelID);
  },

  createManyLabels: function (podids, labels) {
    return API.post('pods/labels', { podids, labels });
  }
}
