<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" style="display: inline;" data-cy="comment-actions-----">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-deleteComment-modal">
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t('routes.pod.menuComment.inviteReviewers')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-form ref="form" data-cy="comment-inviteReviewer-form" >
          <div class="flex-grow-1">
            <v-select
                v-model="selectedReviewers"
                item-text="nameAndEmail"
                item-value="id"
                :items="users"
                :menu-props="{ maxHeight: '400' }"
                v-bind:label="$t('routes.pod.menuComment.selectUsers')"
                multiple
                solo flat
                persistent-hint
            ></v-select>
          </div>

          <div class="flex-grow-1">
            <v-textarea
              v-model="note"
              class="media-list-notes-textarea"
              v-bind:placeholder="$t('routes.pod.menuComment.reviewNote')"
              counter="2048"
              rows="3"
              required
              solo flat
              data-cy="commentsReviewNote-text"
            >
            </v-textarea>
          </div>

          <div class="flex-grow-1">

          </div>
        </v-form>

        <div v-if="reviewers.length > 0" class="mt-5">
          <div class="text-h5">{{$t('routes.pod.menuComment.invitedReviewers')}}</div>
          <div class="mt-4">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="reviewer in reviewers"
                      :key="reviewer.id"
                  >
                    <td>
                      <span class="text-sm-body-2 d-block">{{ reviewer.name }}</span>
                      <span class="text-sm-body-5 d-block font-italic">{{ reviewer.email }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <div v-if="errorMessage" class="error pa-2 mt-2" data-cy="form-inviteReviewers-error">
          <v-icon dense>mdi-alert-circle-outline</v-icon>
          {{errorMessage}}
        </div>

        <div v-if="successMessage" class="success pa-2 mt-2" data-cy="form-inviteReviewers-success">
          <v-icon dense>mdi-check-circle-outline</v-icon>
          {{successMessage}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="button" v-on:click="submitForm" elevation=0 data-cy="podMenu-comments-inviteReviewers" v-bind:disabled="submitDisabled">
          {{$t('routes.pod.menuComment.inviteReviewers')}}
        </v-btn>

        <v-btn text @click="dialog = false" data-cy="dialog---false">
          {{$t('common.cancel')}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "InviteReviewerDialog",
  data() {
    return {
      dialog: false,

      note: "",
      errorMessage: '',
      successMessage: '',
      submitDisabled: false,
      selectedReviewers: [],
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.refreshReviewers();
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.note = "";
      this.errorMessage = '';
      this.successMessage = '';
      this.submitDisabled = false;
      this.selectedReviewers = [];
    },
    refreshReviewers() {
      if (this.$canListPodService()) {
        const REFRESHUSERS = this.$store.getters.keywords.POD.REVIEWER.REFRESHUSERS;
        this.$store.dispatch(REFRESHUSERS, this.pod.id);

        const REFRESHREVIEWERS = this.$store.getters.keywords.POD.REVIEWER.REFRESHREVIEWERS;
        this.$store.dispatch(REFRESHREVIEWERS, this.pod.id);
      }
    },
    submitForm: function() {
      this.errorMessage = '';
      if (this.selectedReviewers.length === 0) {
        this.errorMessage = 'Please, select users.';
        return false;
      }

      this.submitDisabled = true;

      const INVITEREVIEWERS = this.$store.getters.keywords.POD.REVIEWER.INVITEREVIEWERS;
      const REFRESHREVIEWERS = this.$store.getters.keywords.POD.REVIEWER.REFRESHREVIEWERS;
      const REFRESHUSERS = this.$store.getters.keywords.POD.REVIEWER.REFRESHUSERS;

      const data = {
        podId: this.pod.id,
        payload: {
          reviewers: this.selectedReviewers,
          note: this.note,
        }
      };

      this.$store.dispatch(INVITEREVIEWERS, data)
          .then(function (response) {
            this.note = '';
            this.selectedReviewers = [];

            this.$store.dispatch(REFRESHREVIEWERS, this.pod.id);
            this.$store.dispatch(REFRESHUSERS, this.pod.id);

            if (response && response && response.message) {
              this.successMessage = this.$replaceT(response.message.code, response.message.params, response.message.content)
            }

            this.submitDisabled = false;
          }.bind(this))

          .catch(function (error) {
            console.log(error);

            if (error && error.response && error.response.data && error.response.data.error) {
              this.errorMessage = error.response.data.error.message.content || error.response.data.error.message;
            } else {
              this.errorMessage = 'Error!';
              console.log(error);   // unhandled error
            }

            this.submitDisabled = false;
          }.bind(this));
    },
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    users: function() {
      const GETUSERS = this.$store.getters.keywords.POD.REVIEWER.GETUSERS;
      const results = this.$store.getters[GETUSERS];

      const reworkedResults = [];
      for (const result of results) {
        reworkedResults.push({ ...result, nameAndEmail: `${result.name} ( ${result.email} )` });
      }

      return reworkedResults;
    },
    reviewers: function() {
      const GETREVIEWERS = this.$store.getters.keywords.POD.REVIEWER.GETREVIEWERS;
      return this.$store.getters[GETREVIEWERS];
    },
  }

}
</script>

<style>
</style>
