class localizeMessage {
  constructor() {
  }

  install(Vue) {
    Vue.prototype.$localizeMessage = function(message, defaultMessage = null) {
      let localizedMessage;
      if (!message) {
        localizedMessage = defaultMessage ?? this.$t('common.generalError');
      } else {
        if (typeof message === 'string') {
          localizedMessage = message;
        } else {
          if (message.code) {
            localizedMessage = this.$replaceT(message.code.toString(), message.params || {}, defaultMessage);
          } else {
            localizedMessage = message.message || message.description || defaultMessage;
          }
        }
      }

      return localizedMessage;
    }
  }
}

export default new localizeMessage();
