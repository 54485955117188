import API from '../AxiosInstance';

export default {
  getUploadLinksForNode: function(userId, nodeId, rootNodeId) {
    const queryParams = {
      params: {
        apid: rootNodeId,
      }
    }

    return API.get(`users/${userId}/uploadlinks/${nodeId}`, null, queryParams);
  },

  createUploadLink: function(payload) {
    const body = payload.formData

    return API.post(`uploadlinks`, body);
  },

  updateUploadLink: function(id, formData) {
    return API.patch(`uploadlinks/${id}`, formData);
  },

  deleteUploadLink: function(id) {
    const body = { };
    return API.delete(`uploadlinks/${id}`, body);
  },

  inviteUploadlink: function(payload) {
    const body = {
      receiver: payload.receiver,
      note: payload.note,
    };

    return API.post('uploadlinks/' + payload.linkId + '/invite', body);
  },

  // external api call
  getUploadAccessInfo: function(code) {
    const body = {};
    return API.get(`uploadaccess/${code}`, body);
  },

  // external api call
  authorizeUploadlink: function(data) {
    return API.post('uploadaccess/authorize', data);
  },

  checkStorage: function(accesstoken, units) {
    const options = {
      headers: {
        'vb-upload-token': accesstoken,
      },
    };
    return API.get('uploadaccess/resources/storage?units='+units, options);
  },

  createPod: function(data, accesstoken) {
    const body = data;
    const options = {
      headers: {
        'vb-upload-token': accesstoken,
      },
    };

    return API.post('uploadaccess/files/upload', body, options);
  },

  getNodeInfo: function(data) {
    const options = {
      headers: {
        'vb-upload-token': data.accesstoken,
      }
    };

    return API.get('uploadaccess/folders/info', options);
  },
}
