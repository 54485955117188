import API from '../AxiosInstance';

export default {
  getUsersReport: function() {
    const body = {};
    return API.get('reports/users', body);
  },
  getServicesReport: function() {
    const body = {};
    return API.get('reports/services', body);
  },
  getTenantsReport: function() {
    const body = {};
    return API.get('reports/tenants', body);
  },
}
