<template>
  <div>
    <div v-for="utilityPod in utilityPodsErrors" v-bind:key="utilityPod.pod.id" class="error">
      {{$replaceT(utilityPod.error.code, utilityPod.error.params, utilityPod.error.content)}}
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilityPodsErrors",
  mounted: function() {
    const RESETUTILITYPODS = this.$store.getters.keywords.SERVICE.RESETUTILITYPODS;
    this.$store.dispatch(RESETUTILITYPODS);
  },
  computed: {
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
  }
}
</script>

<style>

</style>
