import Api from '../../api/Api'
// import Vue from 'vue'

function sortLabels(a, b) {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
}

function fixTimeFormats(label) {

  if (!label.start && label.start !== 0) {
    return label;
  }

  label.start /= 1000;
  label.end /= 1000;
  let duration = label.end - label.start;
  label.duration = Number(duration.toFixed(1));

  if (label.content.length > 0) {
    label.charsPerSec = (label.content.length / duration).toFixed(1);
  } else {
    label.charsPerSec = '-';
  }

  return label;
}

export default {
  namespaced: true,
  state: {
    labels: [],
    mllabels: [],
  },
  getters: {
    getLabels: function(state) {
      return state.labels;
    },
    getMlLabels: function(state) {
      return state.mllabels;
    },
    getCombinedAWSDetectedLabels: function(state) {
      let awsDetectedLabels = state.mllabels.filter(x => x.service === 'aws-detecting-labels');
      let labelsByContent = {};
      let response = [];

      // separate the labels by content
      for (let i = 0; i < awsDetectedLabels.length; i++) {
        const content = awsDetectedLabels[i].content;
        if (!labelsByContent[content]) {
          labelsByContent[content] = [];
        }

        let labelCloned = JSON.parse(JSON.stringify(awsDetectedLabels[i]));
        labelsByContent[content].push(labelCloned);
      }

      // remove some similar labels
      for (let content in labelsByContent) {
        if (Object.prototype.hasOwnProperty.call(labelsByContent, content)) {
          let similarLabels = labelsByContent[content];

          for (let i = 0; i < similarLabels.length; i++) {
            if  (similarLabels[i+1] &&
                (similarLabels[i+1].start >= similarLabels[i].end) &&
                (similarLabels[i+1].start <= similarLabels[i].end + 1) )
            {
              labelsByContent[content][i].end = similarLabels[i+1].end;
              labelsByContent[content][i].duration = labelsByContent[content][i+1].end - labelsByContent[content][i].start;
              labelsByContent[content].splice(i+1, 1);
              i = -1;
            }
          }
        }
      }

      // merge the labels back
      for (let content in labelsByContent) {
        if (Object.prototype.hasOwnProperty.call(labelsByContent, content)) {
          response = response.concat(labelsByContent[content]);
        }
      }

      response.sort(sortLabels);
      return response;
    }
  },
  mutations: {
    setSources: function(state, sources) {
    //   state.comments = comments.map(changeComment);
    //   state.comments.sort(sortSubtitles);
        state.sources = sources;
    },
    setLabels: function(state, labels) {
        labels.map(fixTimeFormats);
        state.labels = labels;
        state.labels.sort(sortLabels);
    },
    addLabel: function(state, label) {
      // state.subtitles.push(fixTimeFormats(subtitle));
      state.labels.push(fixTimeFormats(label));
      state.labels.sort(sortLabels);
    },
    updateLabel: function(state, label) {
      for (let i = 0; i < state.labels.length; i++) {
        if (state.labels[i].id == label.id) {
          let fixedLabel = fixTimeFormats(label);
          Object.assign(state.labels[i], fixedLabel);
          break;
        }
      }

      state.labels.sort(sortLabels);
    },

    deleteLabel: function(state, labelID) {
      for (let i = 0; i < state.labels.length; i++) {
        if (state.labels[i].id == labelID) {
          state.labels.splice(i, 1);
          break;
        }
      }
    },

    updateMlLabel: function(state, label) {
      for (let i = 0; i < state.mllabels.length; i++) {
        if (state.mllabels[i].id == label.id) {
          let fixedLabel = fixTimeFormats(label);
          Object.assign(state.mllabels[i], fixedLabel);
          break;
        }
      }

      state.mllabels.sort(sortLabels);
    },
    deleteMlLabel: function(state, labelID) {
      for (let i = 0; i < state.mllabels.length; i++) {
        if (state.mllabels[i].id == labelID) {
          state.mllabels.splice(i, 1);
          break;
        }
      }
    },
    setMlLabels: function(state, mlLabels) {
      mlLabels.map(fixTimeFormats);
      state.mllabels = mlLabels;
      state.mllabels.sort(sortLabels);
    }
  },
  actions: {
    refreshLabels: function(context, podID) {
      return Api.podlabel.getLabels(podID)
      .then(function(response) {
        context.commit('setLabels', response.data);
        return response.data;
      });
    },

    createLabel: function(context, data) {
      return Api.podlabel.createLabel(data.podID, data.payload)
      .then(function(response) {
        context.commit("addLabel", response.data);
        return response.data;
      });
    },

    createLabels: function(context, data) {
      return Api.podlabel.createManyLabels(data.podids, data.labels)
        .then(function(response) {
          for (const label of response.data) {
            context.commit('addLabel', label);
          }
        })
    },

    updateLabel: function(context, data) {
      return Api.podlabel.updateLabel(data.podID, data.labelID, data.payload)
      .then(function(response) {
        context.commit("updateLabel", response.data);
        return response.data;
      });
    },

    deleteLabel: function(context, label) {
      return Api.podlabel.deleteLabel(label.podID, label.labelID)
      .then(function() {
        context.commit("deleteLabel", label.labelID);
      });
    },

    refreshMlLabels: function(context, podID) {
      return Api.podlabel.getMlLabels(podID)
      .then(function(response) {
        context.commit('setMlLabels', response.data);
        return response.data;
      });
    },

    updateMlLabel: function(context, data) {
      return Api.podlabel.updateMlLabel(data.podID, data.labelID, data.payload)
      .then(function(response) {
        context.commit("updateMlLabel", response.data);
        return response.data;
      });
    },

    deleteMlLabel: function(context, label) {
      return Api.podlabel.deleteMlLabel(label.podID, label.labelID)
      .then(function() {
        context.commit("deleteMlLabel", label.labelID);
      });
    },
  }
}
