import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

import axios from 'axios';

nprogress.configure({ trickleRate: 0.06, trickleSpeed: 600 });
let counter = 0;
// window.nprogress = nprogress;

axios.interceptors.request.use(function(config) {
  counter++;
  nprogress.start();
  return config;
});

axios.interceptors.response.use(stopProgressBar, stopProgressBarError);

function stopProgressBar(response) {
  setTimeout(function() {
    if (!counter) {
      nprogress.done();
    }
  }, 100);

  counter--;
  return response;
}

function stopProgressBarError(error) {
  setTimeout(function() {
    if (!counter) {
      nprogress.done();
    }
  }, 100);

  counter--;
  return Promise.reject(error);
}
