import API from '../AxiosInstance';

export default {
  getMe: function() {
    const body = {};
    return API.get('me', body);
  },

  getAllUsers: function(payload = {}) {
    const body = {};

    return API.get(`tenants/${payload.accessPointId}/users`, body);
  },

  getUsertypes: function() {
    const body = {};
    return API.get('usertypes', body);
  },

  createUser: function(userParams) {
    const body = userParams;
    return API.post('users', body);
  },

  updateUser: function(userParams) {
    const body = userParams;
    return API.patch('users/' + userParams.id, body);
  },

  deleteUser: function(payload) {
    const body = {};
    return API.delete(`users/${payload.userId}`, body);
  },

  detachUser: function(payload) {
    const body = {};
    return API.delete(`tenants/${payload.accessPointId}/users/${payload.userId}`, body);
  },

  savePermissions: function(payload) {
    const body = payload.permissions;
    return API.patch(`tenants/${payload.accessPointId}/users/${payload.userId}/permissions`, body);
  },

  saveSettings: function(id, settings) {
    const body = settings;
    return API.patch('users/' + id + '/settings', body);
  },

  changeRootNode: function(nodeid, users) {
    const body = {
        nodeid,
        users,
    };

    return API.patch('users/folders', body);
  },

  addAccesspoint: function(userid, nodeid) {
    const body = {
        userid,
        nodeid,
    };

    return API.post(`users/${userid}/nodes`, body);
  },

  updateAccesspoint: function(payload) {
    const body = {
      nodeid: payload.newAccessPointId,
    };

    return API.patch(`users/${payload.userId}/nodes/${payload.oldAccessPointId}`, body);
  },

  deleteAccesspoint: function(userid, nodeid) {
    const body = {};
    return API.delete(`users/${userid}/nodes/${nodeid}`, body);
  },

  pingUser: function(payload) {
    const body = {};
    return API.get(`tenants/${payload.nodeId}/users/${payload.userId}/ping`, body);
  },

  updatePassword: function(payload) {
    const body = {
      password: payload.password,
    };


    return API.patch(`tenants/${payload.nodeId}/users/${payload.userId}/password`, body);
  },

  sendTemporaryPasswordCode: function(payload) {
    const body = {
      email: payload.email,
    };

    return API.post(`temporary-password`, body);
  },

  resetTemporaryPassword: function(payload) {
    const body = {
      email: payload.email,
      code: payload.code,
    };

    return API.patch(`temporary-password`, body);
  },
}
