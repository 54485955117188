<template>
  <div>
    <div v-for="utilityPod in utilityNodesErrors" v-bind:key="utilityPod.pod.id" class="error">
      {{$replaceT(utilityPod.error.code, utilityPod.error.params, utilityPod.error.content)}}
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilityNodesErrors",
  mounted: function() {
    const RESETUTILITYNODES = this.$store.getters.keywords.SERVICE.RESETUTILITYNODES;
    this.$store.dispatch(RESETUTILITYNODES);
  },
  computed: {
    utilityNodesErrors: function() {
      const GETUTILITYNODESERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYNODESERRORS;
      return this.$store.getters[GETUTILITYNODESERRORS];
    },
  }
}
</script>

<style>

</style>
