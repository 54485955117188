<template>
  <div>
    <v-form ref="form" v-if="$canControlPodService()" data-cy="podMenu-transcript-form">
      <div class="transcripts-edit-form-header d-flex justify-space-between">
        <div class="pod-transcript-in-out d-flex " style="width: 60%;">
          <div class="transcripts-edit-form-label" style="">
            {{$t('routes.pod.menuTranscript.inTime')}}
          </div>
          <div style="width: 35%;">
            <v-text-field
              v-model="inTime"
              v-bind:rules="timeInputRules"
              block dense solo flat
              hide-details="auto"
              data-cy="podMenu-transcript-inTime">
            </v-text-field>
          </div>


          <div class="transcripts-edit-form-label" style="">
            {{$t('routes.pod.menuTranscript.outTime')}}
          </div>
          <div style="width: 35%;">
            <v-text-field
              v-model="outTime"
              v-bind:rules="timeInputRules.concat(outTimeComparison)"
              block dense solo flat
              hide-details="auto"
              data-cy="podMenu-transcript-outTime">
            </v-text-field>
          </div>
        </div>

        <div class="text-center" style="margin-top: 2px; line-height:1.2; color: #131313;" data-cy="podMenu-transcript-duration">
          <label style="font-size:0.75rem;">{{$t('routes.pod.menuTranscript.duration')}}</label><br/>
          {{calculatedDuration}} {{$t('routes.pod.menuTranscript.secondsShort')}}
        </div>

        <div class="d-flex">
          <v-btn tile icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>


      <div class="transcripts-edit-form-body">
        <div class="">
          <v-textarea
            v-model="subtitleLine"
            class="media-list-notes-textarea mt-0 pt-0"
            v-bind:rules="subtitleLineRules"
            v-bind:error-messages="subtitleLineError"
            v-bind:placeholder="$t('routes.pod.menuTranscript.addSubtitleLine')"
            rows="2"
            required
            dense solo flat
            data-cy="podMenu-transcript-addSubtitleLine"
          >
          </v-textarea>
        </div>


        <div v-if="$canControlPodService()" class="">
          <div class="pod-transcript-controls d-flex">
            <div>{{subtitleLine.length}} {{$t('routes.pod.menuTranscript.characterShort')}}</div>
            <div>{{calculatedCharsPerSec}} {{$t('routes.pod.menuTranscript.chsec')}}</div>
            <div>{{calculatedConfidence}}% {{$t('routes.pod.menuTranscript.confidenceShort')}}</div>
            <div class="text-right">

              <v-tooltip bottom z-index="1200" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn tile icon v-bind="attrs" v-on="on" v-on:click.prevent="closeForm()" data-cy="podMenu-transcript-cancel-btn">
                    <v-icon small color="primary">mdi-cancel</v-icon>
                  </v-btn>
                </template>

                <span>{{$t('common.cancel')}}</span>
              </v-tooltip>

              <v-tooltip bottom z-index="1200" v-if="editItem">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  tile icon  v-bind="attrs" v-on="on" v-on:click.prevent="deleteSubtitle()" data-cy="podMenu-transcript-delete-btn">
                    <v-icon small color="primary">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>

                <span>{{$t('common.delete')}}</span>
              </v-tooltip>

              <v-tooltip bottom z-index="1200" v-if="subtitleLine.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn tile icon v-bind="attrs" v-on="on" v-on:click.prevent="saveSubtitle()" data-cy="podMenu-transcript-save-btn">
                    <v-icon small color="primary">mdi-floppy</v-icon>
                  </v-btn>
                </template>

                <span>{{$t('common.save')}}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </v-form>

  </div>
</template>

<script>
import Vue from 'vue';


export default {
  name: "EditTranscriptForm",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
    },
    selectedTranscript: {
      type: Object,
      required: false,
    }
  },
  data: function() {
    let self = this;
    return {
      subtitleLine: "",
      subtitleLineError: "",
      subtitleLineRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.subtitleIsRequired'),
      ],

      inTime: "00:00.00",
      outTime: "00:00.01",
      timeInputRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.timeIsRequired'),
        v => /^\d{2,}:\d{2}\.\d{2}$/g.test(v) || this.$t('routes.pod.menuTranscript.invalidFormat'),
        function(v) {
            let seconds = (v || "").split(".")[0].split(":")[1];
            if (parseInt(seconds) >= 60) return self.$t('routes.pod.menuTranscript.invalidSeconds');
            return true;
        },
        function(v) {
            let videoDuration = (self.transcodedAsset && self.transcodedAsset.duration) ? parseFloat(self.transcodedAsset.duration) : 0;
            if (!videoDuration) return true;
            if ((self.videotimeToInt(v) / 1000) > videoDuration) return self.$t('routes.pod.menuTranscript.incorrectTime');
            return true;
        }
      ],
    }
  },
  mounted: function() {
    if (this.editItem) {
      this.subtitleLine = this.editItem.content;
      this.inTime = Vue.filter("toTimeMilliseconds")(this.editItem.start);
      this.outTime = Vue.filter("toTimeMilliseconds")(this.editItem.end);
    }
  },
  beforeDestroy: function() {
    this.closeForm();
  },
  // watch: {
  //   editItem: function(item) {
  //     this.subtitleLine = item.content;
  //     this.subtitleLineError = "";
  //     this.inTime = Vue.filter("toTimeMilliseconds")(item.start);
  //     this.outTime = Vue.filter("toTimeMilliseconds")(item.end);
  //   }
  // },
  methods: {
    closeForm: function() {
      this.$emit('visibility', false);
    },
    videotimeToInt: function(value) {
      let minutes = value.split(":")[0];
      let seconds = value.split(".")[0].split(":")[1];
      let milliseconds = value.split(".")[1];

      milliseconds = parseInt(parseFloat("0." + milliseconds) * 1000);
      seconds = parseInt(seconds) * 1000;
      minutes = parseInt(minutes) * 60000;
      return minutes + seconds + milliseconds;
    },
    saveSubtitle: function() {
      const validForm = this.$refs.form.validate();
      this.subtitleLineError = "";
      if (!validForm) {
        // this.subtitleLineError = "Invalid data!";
        return;
      }

      let data = {
        podID: this.pod.id,
        transcriptID: this.selectedTranscript ? this.selectedTranscript.id : null,
        payload: {
          start: this.videotimeToInt(this.inTime),
          end: this.videotimeToInt(this.outTime),
          content: this.subtitleLine,
        }
      };

      let self = this;
      let VERB = this.$store.getters.keywords.POD.TRANSCRIPT.CREATETRANSCRIPTLINE;

      if (this.editItem) {
        data.payload.id = this.editItem.id;
        VERB = this.$store.getters.keywords.POD.TRANSCRIPT.UPDATETRANSCRIPTLINE;
      }

      this.$store.dispatch(VERB, data)
      .then(function() {
        self.closeForm();
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.subtitleLineError = error.response.data.error.message;
        } else {
          self.subtitleLineError = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
    deleteSubtitle: function() {
      if (!this.editItem) {
        return;
      }

      const payload = {
        podID: this.pod.id,
        transcriptID: this.selectedTranscript.id,
        subtitleID: this.editItem.id,
      };

      let self = this;
      const DELETETRANSCRIPTLINE =  this.$store.getters.keywords.POD.TRANSCRIPT.DELETETRANSCRIPTLINE;
      this.$store.dispatch(DELETETRANSCRIPTLINE, payload)
      // .then(function() {
      //   // closeForm() is triggered on beforeDestroy()
      //   self.closeForm();
      // })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.subtitleLineError = error.response.data.error.message;
        } else {
          self.subtitleLineError = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
  },
  computed: {
    outTimeComparison: function () {
      return this.videotimeToInt(this.inTime) < this.videotimeToInt(this.outTime) ||
        this.$t('routes.pod.menuTranscript.invalidOutTime');
    },
    calculatedDuration: function() {
      return ((this.videotimeToInt(this.outTime) - this.videotimeToInt(this.inTime))/1000).toFixed(2)
    },
    calculatedCharsPerSec: function() {
      if (this.subtitleLine.length > 0) {
        let duration = (this.videotimeToInt(this.outTime) - this.videotimeToInt(this.inTime)) / 1000;
        return (this.subtitleLine.length / duration ).toFixed(1);
      }
      return "-";
    },
    calculatedConfidence: function() {
      if (this.editItem && this.editItem.confidence) {
        return (this.editItem.confidence * 100).toFixed(1);
      }

      return "-";
    },
    transcodedAsset: function() {
      if (!this.pod ||
          !this.pod.asset ||
          !this.pod.asset.web ||
          !Array.isArray(this.pod.asset.web)) {
        return null;
      }

      // Get first web asset with status COMPLETE
      for(let i = 0; i < this.pod.asset.web.length; i++) {
        if (this.pod.asset.web[i].state == this.getPodStates.COMPLETE) {
          return this.pod.asset.web[i];
        }
      }

      return null;
    },
    getPodStates: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
  }
}
</script>

<style>

.transcripts-edit-form-header {
  background-color: #3dabff;
  border-radius: 10px 10px 0px 0px;
  padding: 8px 0px;
}

.transcripts-edit-form-label {
  width: 15%;
  padding-top: 9px;
  padding-right: 4px;
  text-align:right;
  font-weight: 600;
  text-transform: uppercase;
}

.theme--dark .transcripts-edit-form-label {
  color: #333333;
}

.theme--light .transcripts-edit-form-label {
  color: #FFFFFF;
}

.transcripts-edit-form-body {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 0px 0px 10px 10px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
}

.theme--dark .transcripts-edit-form-body {
  border-color: #EBEBEB;
}
.theme--light .transcripts-edit-form-body {
  border-color: #EBEBEB;
}

/* .pod-transcript-in-out > div { width: 33%;} */

.pod-transcript-controls > div {width: 20%;}
.pod-transcript-controls > div:last-child {width: 40%; margin-top: -8px;}


</style>
