import API from '../AxiosInstance';

export default {
  onBoardTenant: function(params) {
    const body = params;
    return API.post('onboard', body);
  },

  getTenantEmails: function(contextNodeId) {
    // contextNodeId = contextNodeId;
    const body = {};
    return API.get(`tenants/${contextNodeId}/emails`, body);
  },

  saveTenantEmails: function(contextNodeId, body) {
    return API.patch(`tenants/${contextNodeId}/emails`, body);
  },

  validateTenantEmail: function(tenantId, code) {
    const body = {
      code,
    };
    return API.patch(`tenants/${tenantId}/email`, body);
  },

  getSubscription: function(contextNodeId) {
    const body = {};
    return API.get('tenants/'+contextNodeId+'/subscription', body);
  },

  subscribeForPlan: function(tenantId, params) {
    const body = params;
    return API.post('tenants/'+tenantId+'/subscribe', body);
  },

  buyAddons: function(tenantId, params) {
    const body = params;
    return API.post('tenants/'+tenantId+'/addons', body);
  },

  updateTenantMFA: function(payload) {
    const body = {
      mfarequired: payload.mfarequired
    };

    return API.patch(`tenants/${payload.accessPointId}/mfa`, body);
  },

  requestAccountDelete: function(payload) {
    return API.post('tenants/accountdelete', payload);
  },

  manageSubscriptionUrl: function(accessPointId) {
    const body = {
      redirecturl: document.location.href,
    };

    return API.post(`tenants/${accessPointId}/stripe`, body);
  },

  getBrandingInfo: function(nodeid) {
    const body = {};
    return API.get('nodes/'+nodeid+'/brand', body);
  },
  saveBrandingInfo: function(nodeid, body) {
    let formData = new FormData();

    for ( let key in body ) {
        formData.append(key, body[key]);
    }

    const options = {
      // headers, query parameters
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
    };

    return API.patch('nodes/'+nodeid+'/brand', formData, options);
  },
  deleteBrandLogo: function(nodeid) {
    const body = {};
    return API.delete('nodes/'+nodeid+'/brand/logo', body);
  }
}
