<template>
  <router-view>
  </router-view>
</template>

<script>
export default {
  name: "BlankLayout",
}
</script>

<style>
</style>