<template>
  <div class="scrollable pa-0 ma-0" style="flex-grow: 1; overflow-y: auto; overflow-x: auto; min-height: 100px;">
    <div v-show="metaLabels.length > 0" class="pa-0 ma-0" data-cy="podMenu-labels-metaLabels">

      <div class="d-flex justify-space-between" style="width: 99%;">
        <div>
          <h4 v-on:click="hideExpandMeta" data-cy="podMenu-labels-metaLabels-title">
            {{$t('routes.pod.menuEditLabels.metaLabels')}}
          </h4>
        </div>

        <div>
          <v-btn
            v-on:click="hideExpandMeta"
            class="labels-chevron"
            icon small
            data-cy="podMenu-labels-metaLabels-expand-btn"
          >
            <v-icon v-if="metaShown">mdi-chevron-up</v-icon>
            <v-icon v-else >mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="metaData" data-cy="podMenu-labels-metaLabels-data">
        <v-chip-group v-show="metaShown"
          v-model="chipsModel"
          active-class="primary--text"
          column
        >
          <v-chip
            class="v-chip"
            v-for="label in metaLabels"
            v-bind:key="label.id"
            v-on:click="selectItem(label)"
            v-on:dblclick="setEditItem(label)"
            v-on:contextmenu.prevent="contextMenuClick(label, $event)"
            v-bind:ref="'label' + label.id"
            v-bind:value="label.id"
            small
            data-cy="podMenu-labels-metaLabels-labelContent"
          >
            {{ label.content }}
          </v-chip>
        </v-chip-group>
      </div>
    </div>

    <hr>

    <div v-show="nonMetaLabels.length > 0" class="" data-cy="podMenu-labels-nonMetaLabels">
      <table class="mt-2 pod-labels-table" data-cy="podMenu-labels-nonMetaLabels-table">
        <thead>
          <tr>
            <td>{{$t('routes.pod.menuEditLabels.start')}}</td>
            <td>{{$t('routes.pod.menuEditLabels.durartionShort')}}</td>
            <td>{{$t('routes.pod.menuEditLabels.label')}}</td>
          </tr>
        </thead>
        <tbody active-class="primary--text">

          <tr
            v-for="label in nonMetaLabels"
            v-bind:key="label.id"
            v-bind:class="isActiveLabel(label)"
            v-on:click="selectItem(label)"
            v-on:dblclick="setEditItem(label)"
            v-on:contextmenu.prevent="contextMenuClick(label, $event)"
            ref="subtitleLineRef"
            class="prevent-select"
            data-cy="podMenu-labels-nonMetaLabels-row"
          >
            <td data-cy="podMenu-labels-nonMetaLabels-start">{{label.start | toTimeMilliseconds}}</td>
            <td data-cy="podMenu-labels-nonMetaLabels-duration">
              {{ label.duration.toFixed(2) }}{{$t('routes.pod.durationSecondsShort')}}
            </td>
            <td v-bind:ref="'label' + label.id" style="white-space: pre-wrap;" data-cy="podMenu-labels-nonMetaLabels-content">
              {{label.content}}
            </td>
        </tr>
        </tbody>
      </table>

      <v-menu
        v-model="showContextMenu"
        :position-x="positionX"
        :position-y="positionY"
        v-bind:close-on-content-click="false"
        close-on-click
        offset-y bottom
        absolute
        z-index="20"
      >
        <v-list data-cy="list-podItem-actions-list">
          <v-list-item @click="contextMenuEdit()">
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content data-cy="">
              <v-list-item-title>{{$t('routes.pod.editLabel')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="contextMenuDelete()">
            <v-list-item-icon>
              <v-icon small>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content data-cy="">
              <v-list-item-title>{{$t('routes.pod.deleteLabel')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>


    </div>
  </div>
</template>

<script>
export default {
  name: "PodCustomLabelsList",
  components: {
  },
  props: {
    pod: {
      type: Object,
      required: true,
    },
    value: String,
    labelsArray: Array,
  },
  data: function() {
    return {
      metaShown: true,
      chipsModel: null,

      showContextMenu: false,
      positionX: 0,
      positionY: 0,
      labelItem: null,
    };
  },
  methods: {
    hideExpandMeta: function() {
      this.metaShown = !this.metaShown;
    },
    selectItem: function(item) {
      if (item.start || item.start === 0) {
        const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
        this.$store.commit(SETSEEKTIMEEVENT, item.start);
      }
      this.$emit('input', item.id);
    },
    setEditItem: function(item) {
      this.$emit('setEditItem', item);
    },
    scrollToActiveLine: function() {
      if (!this.$refs.subtitleLineRef) {
        return;
      }

      for(let i = 0; i < this.$refs.subtitleLineRef.length; i ++) {
        const classList = Array.from(this.$refs.subtitleLineRef[i].classList);
        if (classList.indexOf('active-subtitle') >= 0) {
          this.$refs.subtitleLineRef[i].scrollIntoView(true);
          break;
        }
      }
    },
    contextMenuClick(item, event) {
      console.log("contextMenuClick", item, event);

      this.selectItem(item);

      this.labelItem = item;
      this.positionX = event.clientX;
      this.positionY = event.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },
    contextMenuEdit() {
      this.showContextMenu = false;
      this.setEditItem(this.labelItem);
    },
    contextMenuDelete() {
      this.showContextMenu = false;

      if (!this.labelItem) {
        return;
      }

      const payload = {
        podID: this.pod.id,
        labelID: this.labelItem.id,
      };

      let VERB = this.$store.getters.keywords.POD.LABEL.DELETELABEL;
      this.$store.dispatch(VERB, payload);
    },
  },
  computed: {
    metaLabels: function() {
      return this.labelsArray.filter(label => (!label.start && label.start !== 0) && (!label.end && label.end !== 0));
    },
    nonMetaLabels: function() {
      return this.labelsArray.filter(label => (label.start || label.start === 0) && (label.end || label.end === 0));
    },
    playerCurrentTime: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    isActiveLabel: function() {
      return function(item) {
        return {
            'active-subtitle':
                (this.playerCurrentTime >= item.start &&
                this.playerCurrentTime < item.end),
        };
      }
    },
  },
  watch: {
    chipsModel: function(newValue) {
      this.$emit('input', newValue);
    },
    value: function(newValue) {
      this.chipsModel = newValue;
    },
    playerCurrentTime: function(time) {
      time;
      // console.log("Time updated", time);
      this.scrollToActiveLine();
    },
  }
}
</script>

<style>

</style>
