const supportedByFCP = ["video/3gpp", "video/x-msvideo", "video/quicktime", "video/mp4", "audio/3gpp", 
                        "audio/3gpp", "video/x-m4v", "video/MP2T", "application/mxf", "image/tiff",
                        "image/tga", "image/x-dcraw", "image/vnd.adobe.photoshop", "image/png", "image/jpeg", "image/jpg",
                        "image/heic", "image/heif", "image/gif", "image/bmp", "audio/x-wav", "audio/aac",
                        "audio/mp4", "audio/mp3", "audio/mpeg", "audio/x-caf", "audio/x-wav", "audio/aiff"];

const supportedByAP = ["video/3gpp", "audio/3gpp", "video/3gpp2", "audio/3gpp2", "audio/aac", "audio/aiff", "video/x-ms-asf", "video/x-msvideo", 
                       "video/x-dv", "image/gif", "image/heif", "video/mpeg", "audio/m4a", "video/mp4", "audio/mpeg", "video/mpeg",
                       "audio/wav", "video/x-ms-wmv", "video/mp4", "video/quicktime", "audio/mp4", "audio/x-wav", "application/octet-stream", 
                       "video/mp2t", "model/vnd.mts", "application/mxf", "video/avi", "application/x-extension-exr", "image/x-exr", "image/x-r3d",
                       "image/x-rush", "application/pdf", "application/postscript", "image/bmp", "image/dib", "image/x-dpx", "image/gif", 
                       "image/vnd.microsoft.icon", "image/jpeg", "image/png", "image/vnd.adobe.photoshop", "application/octet-stream", "image/x-targa",
                       "application/icb", "application/x-icb", "image/icb", "application/vda", "image/tiff", "application/ttaf+xml", "text/plain", 
                       "application/sla", "application/xml", "text/xml", "application/vnd.audiograph", "text/csv", "application/x-powerbuilder",
                       "application/x-mpv-edl", "text/x-pascal", "application/x-gui-mui"];    

const MimetypeHelper = {
  isMimetypeVideo(pod) {

    return  pod &&
            pod.asset &&
            pod.asset.raw &&
            pod.asset.raw[0] &&
            this.isVideoAsset(pod.asset.raw[0]);
  },

  isMimetypeAudio(pod) {
    return  pod &&
            pod.asset &&
            pod.asset.raw &&
            pod.asset.raw[0] &&
            this.isAudioAsset(pod.asset.raw[0]);
  },

  isMimetypeImage(pod) {
    return  pod &&
            pod.asset &&
            pod.asset.raw &&
            pod.asset.raw[0] &&
            this.isImageAsset(pod.asset.raw[0]);
  },

  isMimetypeArchive(pod) {
    return  pod &&
            pod.asset &&
            pod.asset.raw &&
            pod.asset.raw[0] &&
            this.isArchiveAsset(pod.asset.raw[0]);
  },

  isVideoAsset(asset) {
    const videoFormats = ['application/mxf'];
    return  asset &&
            asset.mimetype &&
            (
              asset.mimetype.startsWith('video/') ||
              videoFormats.indexOf(asset.mimetype) >= 0
            );
  },
  isAudioAsset(asset) {
    return  asset &&
            asset.mimetype &&
            asset.mimetype.startsWith('audio/');
  },
  isImageAsset(asset) {
    return  asset &&
            asset.mimetype &&
            asset.mimetype.startsWith('image/');
  },
  isArchiveAsset(asset) {
    return  asset &&
            asset.mimetype && (
              asset.mimetype.startsWith('application/zip') ||
              asset.mimetype.startsWith('application/x-tar') ||
              // asset.mimetype.startsWith('application/x-7z') ||
              asset.mimetype.startsWith('application/x-rar-compressed')
            );
  },
  isSupportedByFCP(pod) {
    return pod &&
           pod.asset &&
           pod.asset.raw &&
           pod.asset.raw[0] &&
           pod.asset.raw[0].mimetype && 
           supportedByFCP.includes(pod.asset.raw[0].mimetype);
  },
  isSupportedByFCPAsset(asset) {
    if (asset && asset.mimetype) {
      return supportedByFCP.includes(asset.mimetype);
    }
    return false;
  },
  isSupportedByAP(pod) {
    return pod &&
           pod.asset &&
           pod.asset.raw &&
           pod.asset.raw[0] &&
           pod.asset.raw[0].mimetype && 
           supportedByAP.includes(pod.asset.raw[0].mimetype);
  }
}


export default MimetypeHelper
