export default {
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.dialog = false;
      }
    },
  },
}
