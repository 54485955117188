import API from '../AxiosInstance';

export default {
  downloadThings: function(payload) {

    let nodeids = payload.nodeids || [];
    let podids = payload.podids || [];
    let assetids = payload.assetids || [];

    const queryStringParameters = [
      ...nodeids.map(id => `nodeids[]=${encodeURIComponent(id)}`),
      ...podids.map(id => `podids[]=${encodeURIComponent(id)}`),
      ...assetids.map(id => `assetids[]=${encodeURIComponent(id)}`),
    ]
    .concat(payload.excludeAssets ? `excludeAssets=${encodeURIComponent(payload.excludeAssets)}` : [])
    .concat(payload['id-token'] ? `id-token=${encodeURIComponent(payload['id-token'])}` : [])
    .join('&');

    const endpoint = API.defaults.baseURL;
    const url = `${endpoint}download?${queryStringParameters}`;

    // console.log("endpoint is", endpoint);
    // console.log("url is", url);
    window.location = url;
  },

  sendSupportTicket: function(payload) {
    const body = payload;
    return API.post('support/send', body);
  },

  sendFeatureRequest: function(payload) {
    const body = payload;
    return API.post('support/feature/send', body);
  },

  getLanguages: function() {
    const body = {};
    return API.get('languages', body);
  },

  createFreeTrial: function(payload) {
    const body = payload;
    return API.post('accountcreate', body);
  },

  salesmanCreatesTenant: function(payload) {
    const body = payload;
    return API.post('salesmancreatestenant', body);
  },

  resetTemporaryPassword: function() {
    const options = {};
    return API.patch(`temporary-password`, options);
  },
}
