<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t("routes.node.FCPImport")}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text  v-if="assets.length == 0">
        <div class="error" data-cy="">
          {{$t("routes.node.FCPNoItems")}}
        </div>
      </v-card-text>

      <v-card-text>
        <div v-if="errorMessage" class="error"> {{ errorMessage }} </div>
      </v-card-text>

      <v-card-text>
        <li v-for="asset in this.assets" v-bind:key="asset.id">
          {{ asset.name }}
        </li>
      </v-card-text>

      <v-card-text class="text-center">
        <v-progress-circular v-if="loading"
            indeterminate
            color="primary">
        </v-progress-circular>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addToFCP">{{$t('common.import')}}</v-btn>
        <v-btn text @click="cancel">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<script>
import axios from "axios";
import { parseSync } from 'subtitle';
import Helper from '@/components/helper';
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "FCPSubtitleDialog",
  props: {
    assets: {
      type: Array,
      required: true
    },
  },
  data: function() {
    return {
      dialog: false,
      loading: false,
      errorMessage: "",
    }
  },
  mounted: function() {
  },
  methods: {
    addToFCP: function() {
        const subtitleStructures = [];
        let count = this.assets.length;
        for (const asset of this.assets) {
            let subtitles = this.availableSubtitles.filter((s) => s.id == asset.id);
            if (subtitles && subtitles.length > 0) {
                let subtitle = subtitles[0];
                let subURL = subtitle.url;
                let self = this;
                this.loading = true;
                axios.get(subURL)
                .then(function(res) {
                    let subs = parseSync(res.data);
                    let cues = [];

                    for (let p = 0; p < subs.length; p++) {
                        if (subs[p].type == 'cue') {
                            subs[p].id = p;
                            cues.push(subs[p]);
                        }
                    }

                    self.sortCues(cues);
                    const cuesData = cues.map((c) => c.data);
                    subtitleStructures.push({"subtitles": cuesData, "name": asset.name});
                    count -= 1;
                    if (count === 0) {
                        const sessionObject = JSON.stringify({action: "addSubtitles", subtitleStructures: subtitleStructures});
                        Helper.invokeFCPMessageHandler('subtitles', sessionObject);
                        self.cancel();
                    }
                })
                .catch(function(error) {
                    console.log("I catch the sheriff 2", error);
                    self.loading = false;
                    self.dialog = this.$t('routes.node.FCPSubtitlesError');
                });
            }
        }
    },
    cancel: function() {
      this.dialog = false;
      this.errorMessage = "";
      this.loading = false;
    },
    sortCues: function(cues) {
      cues.sort(function(a, b) {
        if (a.data.start < b.data.start) return -1;
        if (a.data.start > b.data.start) return 1;
        return 0;
      });
    },

  },
  computed: {
    availableSubtitles: function() {
      const AVAILABLESUBTITLES = this.$store.getters.keywords.PLAYER.AVAILABLESUBTITLES;
      return this.$store.getters[AVAILABLESUBTITLES];
    },
  }
}
</script>

<style>

</style>
