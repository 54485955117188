import API from '../AxiosInstance';

export default {
  getEmbedLinks: function(podID) {
    const body = {};
    return API.get('pods/' + podID + '/embedlinks', body);
  },

  createEmbedLink: function(data) {
    const body = {
        asset: data.asset,
    };

    return API.post('pods/' + data.podid + '/embedlinks', body);
  },

  deleteEmbedLink: function(podId, linkId) {
    const body = { };
    return API.delete('pods/' + podId + '/embedlinks/' + linkId, body);
  },

  getEmbedlinkInfo: function(code) {
    const body = {};
    return API.get('embedded/' + code, body);
  }
}
