import API from '../AxiosInstance';

export default {
  searchNodesAndPods: function(payload) {
    const options = {
        params: {
          nodeid: payload.nodeid,
          q: payload.searchStr,
          zone: payload.searchZone,
          wholeWords: payload.wholeWords ? 1 : 0,
          allWords: payload.allWords ? 1 : 0,
          includeAssets: payload.includeAssets ? 1 : 0,
          includeSubfolders: payload.includeSubfolders ? 1 : 0,
        }
    };

    return API.get('search', options);
  },
}
