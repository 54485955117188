import API from '../AxiosInstance';

export default {
  // get transcript subtitle lines
  getTranscriptLines: function(podID) {
    return API.get('pods/'+podID+'/transcripts');
  },

  // Create subtitle file from the transcript
  generateFile: function(podID, transcriptID, body) {
    return API.post('pods/'+podID+'/transcripts/' + transcriptID + '/file', body);
  },

  createTranscript: function(podID, payload) {
    return API.post('pods/' + podID + '/transcripts', payload);
  },

  updateTranscript: function(podID, transcriptID, payload) {
    return API.patch('pods/' + podID + '/transcripts/' + transcriptID, payload);
  },

  deleteTranscript: function(podID, transcriptID) {
    return API.delete('pods/' + podID + '/transcripts/' + transcriptID);
  },

  // Add new subtitle line to the transcript
  createTranscriptLine: function(podID, transcriptID, payload) {
    return API.post('pods/' + podID + '/transcripts/' + transcriptID + '/sentences', payload);
  },

  // update subtitle line from the transcript
  updateTranscriptLine: function(podID, transcriptID, payload) {
    return API.patch('pods/' + podID +  '/transcripts/' + transcriptID + '/sentences/' + payload.id, payload);
  },

  // delete subtitle line from the transcript
  deleteTranscriptLine: function(podID, transcriptID, subtitleID) {
    return API.delete('pods/' + podID + '/transcripts/' + transcriptID + '/sentences/' + subtitleID);
  }
};
