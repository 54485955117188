import Vue from 'vue'

import UtilityPodsErrors from './UtilityPodsErrors'
import UtilityNodesErrors from './UtilityNodesErrors'
import VuePlyr from './VuePlyr';


Vue.component('UtilityPodsErrors', UtilityPodsErrors);
Vue.component('UtilityNodesErrors', UtilityNodesErrors);
Vue.component('VuePlyr', VuePlyr)
