import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    tenants: [],
    selectedTenants: [],
  },
  getters: {
    getTenants: function(state) {
      return state.tenants;
    },
    getSelectedTenants: function(state) {
      return state.selectedTenants;
    }
  },
  mutations: {
    toggleSelection: function(state, tenant) {
      let index = state.selectedTenants.indexOf(tenant);

      if (index >= 0) {
        state.selectedTenants.splice(index, 1);
      } else {
        state.selectedTenants.push(tenant);
      }
    },
    resetSelectedUsers: function(state) {
      state.selectedTenants = [];
    },
  },
  actions: {
    fetchAllTenants: function() {
      return Api.tenant.getAllTenants()
        .then(function(result) {
          return result.data;
        })
        .catch(function(error) {
          console.error("Error getting tenants.", error);
        });
    },
    restoreTenant: function(state, payload) {
      return Api.tenant.restoreTenant(payload)
        .then(function(result) {
          return result.data;
        })
        .catch(function(error) {
          console.error("Error restoring a tenant.", error);
        });
    },
    getTenantSettings: function(state, accessPointId) {
      return Api.tenant.getTenantSettings(accessPointId).then((result) => {
        return result.data;
      }).catch((error) => {
        console.error("Error getting tenant settings", error);
      });
    },
    updateTenantSettings: function(state, { accessPointId, settings }) {
      return Api.tenant.updateTenantSettings(accessPointId, settings).catch((error) => {
        console.error("Error updating tenant settings for tenant", error);
      })
    }
  },
}
