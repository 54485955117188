<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :disabled="isDisabled"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="list-podNodeItem-actions-moveSelected-activator">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-moveSelected-modal">
      <v-card-title class="primary d-flex justify-space-between" data-cy="dialog-moveSelected-title">
        {{$tc("routes.node.moveDialogMoveSelected", itemsCount)}}

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable  mt-4">
        <div v-if="itemsCount > 0">
          <v-text-field
              :label="$t('routes.node.typeFolderName')"
              solo dense
              background-color="#383838"
              hide-details
              append-icon="mdi-magnify"
              class="mb-2"
              v-model="searchQuery"
          ></v-text-field>
        </div>

        <div v-if="itemsCount == 0  && shouldShowNoItems" class="error" data-cy="dialog-moveSelected-noItems">
          {{$t("routes.node.moveDialogNoItems")}}
        </div>

        <div v-if="errorMessage" class="error" data-cy="dialog-moveSelected-errorMessage">
          {{errorMessage}}
        </div>

        <div class="error" v-if="errorTranslatable">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>


        <v-list v-if="itemsCount > 0" dense>
          <v-subheader>
            <div v-if="browseNode" class="move-selected-dialog-list-subheader" data-cy="dialog-moveSelected-destination">
              {{ $t('routes.node.moveDialogTo') }} {{ browseNode.name }}
            </div>
            <div class="move-selected-dialog-list-subheader"  v-else>
              {{ $t('routes.node.moveDialogSelectDestination') }}
            </div>
          </v-subheader>

          <v-list-item-group v-if="nodeList.length > 0" v-model="listGroupSelected"
                             data-cy="dialog-moveSelected-listGroup">
            <v-list-item
                v-for="node in nodeList"
                v-bind:key="node.id"
                v-on:click="displayNode(node.id)"
                v-bind:disabled="isNodeDisabled(node)"
                data-cy="dialog-moveSelected-listItem">

              <v-list-item-icon>
                <v-icon v-text="getNodeIconName(node)"></v-icon>
              </v-list-item-icon>

              <v-list-item-content class="ml-1" data-cy="dialog-moveSelected-itemContent">
                <v-list-item-title v-text="node.name"></v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          </v-list-item-group>
          <v-list-item-group v-else>
            <v-list-item disabled>{{ $t('routes.node.moveDialogNoMoreSubFolders') }}</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>

      <v-card-text v-if="isArchived">
        <v-checkbox
            v-model="archiveItems"
            v-bind:label="$t('routes.node.archiveItems')"
            dense
            hide-details
        >
        </v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="browseNode ? true : false"
          v-on:click="displayNode(browseNode.parentid)"
          text data-cy="dialog-moveSelected-back-btn">
          <v-icon>mdi-arrow-left</v-icon>
          {{$t('routes.node.moveDialogBack')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation=0 v-bind:disabled="submitDisabled" @click="submit" data-cy="dialog-moveSelected-submit-btn">{{$t('common.move')}}</v-btn>
        <v-btn @click="dialog = false" text data-cy="dialog-moveSelected-cancel-btn">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "MoveSelectedDialog",
  props: {
    nodes: {
      type: Array,
      required: false,
    },
    pods: {
      type: Array,
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: '',
      errorTranslatable: null,
      browseNode: null,
      listGroupSelected: null,
      preventNoItems: false,
      archiveItems: false,
      preventDoubleClick: false,
      searchQuery: '',
    }
  },
  watch: {
    dialog: function(newValue) {
      // this.errorMessage = '';
      // this.errorTranslatable = '';
      this.resetDialog();
      if (!newValue) this.browseNode = null;
      if (newValue) this.preventNoItems = false;
    },
    browseNode: function (newValue) {
      if (!newValue) this.archiveItems = false;
      if (newValue && newValue.archived && newValue.archived === 1) {
        this.archiveItems = true;
      }
    },
  },
  methods: {
    resetDialog: function() {
      // this.dialog = false;
      this.errorMessage = '';
      this.errorTranslatable = null;
      this.listGroupSelected = null;
      this.preventDoubleClick = false;
    },
    displayNode: async function(nodeID) {
      this.listGroupSelected = null;

      // sometimes this.browseNode.parentid is null
      if (nodeID) {
        const GETNODEINFO = this.$store.getters.keywords.NODE.GETNODEINFO;
        this.browseNode = await this.$store.dispatch(GETNODEINFO, nodeID);
        this.listGroupSelected = null;
      } else {
        this.browseNode = null;
      }
    },
    submit: async function() {
      this.errorMessage = '';
      this.errorTranslatable = null;
      this.preventNoItems = true;
      if (!this.browseNode || this.itemsCount == 0 || this.preventDoubleClick) {
        return;
      }

      this.preventDoubleClick = true;

      let promises = [];
      const MOVEMANYPODS = this.$store.getters.keywords.POD.MOVEMANYPODS;
      const MOVEMANYNODES = this.$store.getters.keywords.NODE.MOVEMANYNODES;

      if (this.nodes.length > 0) {
        const payload = {
          targetnodeid: this.browseNode.id,     // destination
          nodeid: this.getCurrentNode.id,       // current directory
          nodes: this.nodes.map(x => x.id),
          archive: this.archiveItems ? 1 : 0,
        };

        promises.push(this.$store.dispatch(MOVEMANYNODES, payload));
      }

      if (this.pods.length > 0) {
        const payload = {
          targetnodeid: this.browseNode.id,     // destination
          nodeid: this.getCurrentNode.id,       // current directory
          pods: this.pods.map(x => x.id),
          archive: this.archiveItems ? 1 : 0,
        };

        promises.push(this.$store.dispatch(MOVEMANYPODS, payload));
      }

      try {
        await Promise.all(promises);
        this.dialog = false;
        this.preventNoItems = true;
        this.preventDoubleClick = false;
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          // this.errorMessage = error.response.data.error.message;
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            this.errorTranslatable = apiError;
          } else {
            this.errorMessage = apiError;
          }
        } else {
          this.errorMessage = "Error!";
        }
        this.preventNoItems = true;
      }
      // finally {
      //   1;
      // }
    }
  },
  computed: {
    nodeList: function() {
      if (this.browseNode) {
        if (!this.browseNode.children) {
          return [];
        }

        const childNodes = [];

        // filter out archived nodes
        if (Array.isArray(this.browseNode.children) && this.browseNode.children.length > 0) {
          for (let i = 0; i < this.browseNode.children.length; i++) {
            const childNode = this.browseNode.children[i];

            if (!childNode.archived) {
              childNodes.push(childNode);
            }
          }
        }

        // filter by search query
        if (this.searchQuery.length >= 1) {
          const cleanQuery = this.searchQuery.toLowerCase();

          const filteredNodes = childNodes.filter((node) => {
            return node.name.toLowerCase().includes(cleanQuery);
          });

          // sort the nodes by name
          return (filteredNodes.length > 0) ? filteredNodes.sort((a, b) => a.name.localeCompare(b.name)) : [];
        }

        return childNodes.sort((a, b) => a.name.localeCompare(b.name));
      }

      if (this.accessPoints && Array.isArray(this.accessPoints) && this.accessPoints.length > 0) {
        const accessPoints = this.accessPoints;
        return accessPoints.sort((a, b) => a.name.localeCompare(b.name)) || [];
      }

      return [];
    },
    accessPoints: function() {
      const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
      return this.$store.getters[ACCESSPOINTS];
    },
    getViewPod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getCurrentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE];
    },
    itemsCount: function() {
      return this.nodes.length + this.pods.length;
    },
    getNodeType: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      const TYPES = this.$store.getters[NODETYPES];
      let self = this;
      return function(node) {
        switch (node.type) {
          case TYPES.FOLDER: return self.$t('routes.node.typeFolder');
          case TYPES.PROJECT: return self.$t('routes.node.typeProject');
          case TYPES.CLIENT: return self.$t('routes.node.typeClient');
          case TYPES.TENANT: return self.$t('routes.node.typeTenant');
          default: return self.$t('routes.node.typeUnknown');
        }
      }
    },
    getNodeIconName: function() {
      return function(node) {
        let iconName;
        switch (node.type) {
          case 2: iconName = "mdi-briefcase-clock-outline"; break;
          case 3: iconName = "mdi-account-tie"; break;
          case 4: iconName = "mdi-home-assistant"; break;
          default: iconName = "mdi-folder-outline"; break;
        }

        if (node.archived == 1 || node.archived == 2) {
          iconName = "mdi-package-down";
        }

        return iconName;
      };
    },
    isNodeDisabled: function() {
      const ROOTFOLDERID = this.$store.getters.keywords.COMMON.ROOTFOLDERID;
      const rootNodeUUID = this.$store.getters[ROOTFOLDERID];

      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      const TYPES = this.$store.getters[NODETYPES];

      let nodeIDs = this.nodes.map(x => x.id);
      let selectedNodeTypes = this.nodes.map(x => x.type);

      return function(node) {
        // disable the selected nodes
        if (nodeIDs.indexOf(node.id) >= 0) {
          return true;
        }

        // disable the nodes based on their types:

        // The parent of the tenant node could be only the root node
        if (selectedNodeTypes.indexOf(TYPES.TENANT) >=0 && node.id != rootNodeUUID) {
          return true;
        }

        // The parent of the client node could be only a tenant
        if (selectedNodeTypes.indexOf(TYPES.CLIENT) >=0  && node.type != TYPES.TENANT && node.id != rootNodeUUID) {
          return true;
        }

        // The parent of the project node could be only a tenant or client
        // if (selectedNodeTypes.indexOf(TYPES.PROJECT) >=0 &&
        //     (node.type != TYPES.TENANT && node.type != TYPES.CLIENT) &&
        //     node.id != rootNodeUUID
        // ) {
        //   return true;
        // }

        return false;
      }
    },
    submitDisabled: function() {
      if (!this.browseNode || this.itemsCount == 0 || this.preventDoubleClick) return true;

      let parentID = this.pods.length > 0 ? this.pods[0].nodeid : this.nodes[0].parentid;
      if (parentID == this.browseNode.id) return true;    // cannot move in the current directory

      return false;
    },
    shouldShowNoItems: function() {
      return !this.errorMessage && !this.preventNoItems && !this.errorTranslatable;
    },
    isArchived: function() {
      return (this.browseNode?.archived === 1);
    },
  },
}
</script>

<style>
.v-subheader > .move-selected-dialog-list-subheader {
  font-size: 1rem;
}

.v-subheader.theme--light > .move-selected-dialog-list-subheader {
  color: rgba(0, 0, 0, 0.8);
}
</style>
