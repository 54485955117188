import Api from '../../api/Api'
// import Vue from 'vue'

function changeComment(comment) {
  if (comment.start !== null) comment.start /= 1000;
  if (comment.end !== null) comment.end /= 1000;
  return comment;
}

function sortSubtitles(a, b) {
  if (a.start < b.start) return -1;
  if (a.start > b.start) return 1;
  return 0;
}

export default {
  namespaced: true,
  state: {
    comments: [],
    closeForms: null,
    visibleForms: 0,
  },
  getters: {
    getComments: function(state) {
      // return state.comments;
      return function(parentID) {
        return state.comments.filter(x => x.parentid == parentID);
      }
    },
    getCommentsCount: function(state) {
      return state.comments.length;
    },
    getCloseFormsEvent: function(state) {
      return state.closeForms;
    },
    getVisibleForms: function(state) {
      return state.visibleForms;
    },
  },
  mutations: {
    setComments: function(state, comments) {
      state.comments = comments.map(changeComment);
      state.comments.sort(sortSubtitles);
    },
    like: function(state, payload) {
      const index = state.comments.findIndex((obj) => obj.id === payload.commentId);

      if (index >= 0) {
        state.comments[index].likesCount++;
        state.comments[index].likedBy.push(payload.userId);
      }
    },
    dislike: function(state, payload) {
      const index = state.comments.findIndex((obj) => obj.id === payload.commentId);

      if (index >= 0) {
        state.comments[index].likesCount--;

        const newCollection = state.comments[index].likedBy.filter((id) => id !== payload.userId);
        state.comments[index].likedBy = newCollection;
      }
    },
    check: function(state, payload) {
      const index = state.comments.findIndex((obj) => obj.id === payload.commentId);

      if (index >= 0) {
        state.comments[index].checked = true;
      }
    },
    uncheck: function(state, payload) {
      const index = state.comments.findIndex((obj) => obj.id === payload.commentId);

      if (index >= 0) {
        state.comments[index].checked = false;
      }
    },
    setCloseFormsEvent: function(state, value) {
      state.closeForms = value;
    },
    changeVisibleForms: function(state, delta) {
      state.visibleForms = state.visibleForms + delta;
    },
  },
  actions: {
    promptRefreshComments: function(context, podID) {
      const GETVIEWPOD = context.rootGetters.keywords.POD.GETVIEWPOD;

      const viewPod = context.rootGetters[GETVIEWPOD];
      if (viewPod && viewPod.id && viewPod.id == podID) {
        context.dispatch('refreshComments', podID);
      }
    },

    refreshComments: function(context, podID) {
      return Api.comment.getComments(podID)
      .then(function(response) {
        context.commit('setComments', response.data);
        return response.data;
      });
    },

    resetComments: function(context) {
      context.commit('setComments', []);
    },

    createComment: function(context, data) {
      return Api.comment.createComment(data.podID, data.payload)
      .then(function(response) {
        console.log("Created comment", response);
      });
    },

    updateComment: function(context, data) {
      return Api.comment.updateComment(data.podID, data.commentID, data.payload)
      .then(function(response) {
        console.log("comment updated", response);
      });
    },

    deleteComment: function(context, comment) {
      return Api.comment.deleteComment(comment.podid, comment.id)
      .then(function(response) {
        console.log("deleted comment response", response);
      });
    },

    submitComments: function(context, data) {
      return Api.comment.submitComments(data.podId, data.payload);
    },

    getCommentsStateless: function(context, podID) {
      return Api.comment.getComments(podID)
      .then(function(response) {
        return response.data;
      });
    },

    likeComment: function(context, data) {
      return Api.comment.likeComment(data.podId, data.payload.commentId)
        .then(function () {
          context.commit('like', data.payload);
        });
    },

    dislikeComment: function(context, data) {
      return Api.comment.dislikeComment(data.podId, data.payload.commentId)
        .then(function () {
          context.commit('dislike', data.payload);
        });
    },

    checkComment: function(context, data) {
      return Api.comment.checkComment(data.podId, data.payload.commentId)
        .then(function () {
          context.commit('check', data.payload);
        });
    },

    uncheckComment: function(context, data) {
      return Api.comment.uncheckComment(data.podId, data.payload.commentId)
        .then(function () {
          context.commit('uncheck', data.payload);
        });
    },

    setCloseFormsEvent: function(context, value) {
      context.commit('setCloseFormsEvent', value);
    },
    increaseVisibleForms: function(context) {
      context.commit('changeVisibleForms', +1);
    },
    decreaseVisibleForms: function(context) {
      context.commit('changeVisibleForms', -1);
    },
  }
}
