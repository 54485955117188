import Vue from 'vue'
// import moment from 'moment';


// For duration of video/audio files
// input is number like 1.23 (1 seconds and 230 milliseconds)
// output is in the format of  00:00
Vue.filter('videoDuration', function(value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw "videoDuration parameter must be a number of seconds";
  }

  let sign = '';

  if (value < 0) {
    sign = '-';
    value = Math.abs(value);
  }

  let floorValue = Math.floor(value);
  value = parseFloat(value);

  let seconds = floorValue % 60;
  let minutes = Math.floor(floorValue / 60);

  return  sign +
          (minutes < 10 ? "0"  : "") + minutes +
          (seconds < 10 ? ":0" : ":") + seconds;
});

// input is number like 1.23 (1 seconds and 230 milliseconds)
// output is in the format of 00:00.24  (the last one is the frame)
Vue.filter('toTimeFrames', function(value, framerate) {
    framerate = framerate || 24;
    let frametime = 1000 / framerate;
    let floorValue = Math.floor(value);
    value = parseFloat(value);


    let timeStr = Vue.filter('videoDuration')(value);
    // let seconds = floorValue % 60;
    // let minutes = Math.floor(floorValue / 60);

    let millisec = value - floorValue;
    let frames = parseInt((millisec * 1000) / frametime);

    return timeStr + (frames  < 10 ? "f0" : "f") + frames;
});

// input is number like 1.23 (1 seconds and 230 milliseconds)
// output is in the format of 00:01.23 (the last one is milliseconds)
Vue.filter('toTimeMilliseconds', function(value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw "toTimeMilliseconds parameter must be a number of seconds";
  }

  let timeStr = Vue.filter('videoDuration')(value);
  let millisec = value - Math.floor(value);
  let millisecInt = Math.floor(millisec * 100);

  // A problem with rounding of floats
  if (millisecInt >= 100) {
    millisecInt = 0;
  }

  return timeStr + (millisecInt < 10 ? '.0' : '.') + millisecInt;
});

// input is number like 1.234 (1 seconds and 234 milliseconds)
// output is in the format of 00:01.234 (the last one is milliseconds)
Vue.filter('toTimeMillisecondsFull', function(value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw "toTimeMilliseconds parameter must be a number of seconds";
  }

  let timeStr = Vue.filter('videoDuration')(value);
  let millisec = value - Math.floor(value);
  let millisecInt = Math.floor(millisec * 1000);

  // A problem with rounding of floats
  if (millisecInt >= 1000) {
    millisecInt = 0;
  }

  if (millisecInt < 100 && millisecInt >= 10) {
    return timeStr + ".0" + millisecInt;
  } else if (millisecInt < 10) {
    return timeStr + ".00" + millisecInt;
  }

  return timeStr + "." + millisecInt;
});
