<template>
  <v-menu
    v-model="contextMenuModel"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
  >

    <v-list style="overflow-y:auto;" data-cy="nodeSideMenu-contextMenu">

      <v-list-item @click="setSort('nameAsc')" data-cy="nodeSideMenu-contextMenu-sortByNameAsc">
        <v-list-item-content>
          <v-list-item-title>
            {{$t('routes.node.sortByNameAsc')}}
            <v-icon v-if="sortString=='nameAsc'" dense left>mdi-check</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="setSort('nameDesc')" data-cy="nodeSideMenu-contextMenu-sortByNameDesc">
        <v-list-item-content>
          <v-list-item-title>
            {{$t('routes.node.sortByNameDesc')}}
            <v-icon v-if="sortString=='nameDesc'" dense left>mdi-check</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="setSort('dateDesc')" data-cy="nodeSideMenu-contextMenu-sortByDateDesc">
        <v-list-item-content>
          <v-list-item-title>
            {{$t('routes.node.sortByDateDesc')}}
            <v-icon v-if="sortString=='dateDesc'" dense left>mdi-check</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="setSort('dateAsc')" data-cy="nodeSideMenu-contextMenu-sortByDateAsc">
        <v-list-item-content>
          <v-list-item-title>
            {{$t('routes.node.sortByDateAsc')}}
            <v-icon v-if="sortString=='dateAsc'" dense left>mdi-check</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

  </v-menu>
</template>

<script>
export default {
  name: "ExpansionpanelContextMenu",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
    sortString: {
      type: String,
      required: true,
    }
    // node: {
    //   type: Object,
    //   // required: true,
    // },
  },
  data() {
    return {};
  },
  methods: {
    setSort(sortString) {
      // console.log("Click on context menu!");
      this.$emit('setSort', sortString);
      this.$emit('input', false);
    },
  },
  computed: {
    contextMenuModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
}
</script>

<style>
</style>
