<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card >
      <v-card-title class="primary d-flex justify-space-between">
        <span>{{$t('routes.pod.createSubtitle')}}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable mt-4">

        <v-form ref="form" v-on:submit.prevent="formSubmit()" data-cy="dialog-exportSubtitle-modal">

          <div>
            <v-text-field
              type="text"
              v-model="filename"
              v-bind:rules="filenameRules"
              v-bind:label="$t('routes.pod.menuTranscript.exportDialogFilename')"
              required block
              solo flat
              data-cy="dialog-exportSubtitle-fileName"
            >
            </v-text-field>
          </div>

          <div>
            <v-radio-group
              v-model="selectedTranscript"
              :label="$t('routes.pod.createFromTranscript')"
              class="mt-0 pt-0"
              mandatory
              :value="-1"
            >
              <v-radio
                v-for="option in getTranscriptOptions"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              >
              </v-radio>

              <v-radio :value="-1" :key="-1" :label="$t('routes.pod.noneCreateEmptyFile')">
              </v-radio>
            </v-radio-group>
          </div>

          <div>

            <v-radio-group
              v-model="filetype"
              :label="$t('routes.pod.selectFileType')"
              mandatory
              class="mt-0 pt-0"
            >
              <v-radio label="SRT" value="srt" data-cy="dialog-createSubtitle-srtRadio"></v-radio>
              <v-radio label="VTT" value="vtt" data-cy="dialog-createSubtitle-vttRadio"></v-radio>
            </v-radio-group>
          </div>

          <div v-if="errorMessage" class="error" data-cy="dialog-exportSubtitle-fileTypeError">
            {{errorMessage}}
          </div>

          <div class="error" v-if="errorTranslatable">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="formSubmit()" elevation=0 data-cy="dialog-exportSubtitle-ok">{{$t('common.create')}}</v-btn>
        <v-btn text @click="dialog = false">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "CreateSubtitlesDialog",
  // props: {
  // },
  data() {
    return {
      dialog: false,

      filename: "",
      filenameRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.exportDialogInvalidName')
      ],

      selectedTranscript: -1,

      filetype: "srt",
      errorMessage: "",
      errorTranslatable: null,
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.resetForm();
      }

      // fixing strange behaviour
      this.selectedTranscript = -1;
    }
  },
  methods: {
    resetForm() {
      if(this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.filename = "";
        this.filetype = "srt";
        // this.selectedTranscript = -1;

        this.errorMessage = "";
        this.errorTranslatable = null;
      });
    },
    formSubmit: function() {
      this.errorMessage = "";
      this.errorTranslatable = null;

      const validForm = this.$refs.form.validate();
      if (!validForm) {
        return;
      }

      let regexp = new RegExp("\\." + this.filetype + "$", "gi");
      if (!regexp.test(this.filename)) {
        this.filename += "." + this.filetype;
      }

      if (this.selectedTranscript !== -1) {
        this.exportTranscriptToFile();
      } else {
        this.createSubtitleFile();
      }
    },
    exportTranscriptToFile: function() {
      // remove the file extension
      const filename = this.filename.slice(0, -4);

      const data = {
        id: this.selectedTranscript,
        podID: this.pod.id,
        payload: {
          filename: filename,
          filetype: this.filetype,
        }
      };

      let self = this;
      const GENERATEFILE = this.$store.getters.keywords.POD.TRANSCRIPT.GENERATEFILE;

      this.$store.dispatch(GENERATEFILE, data)
      .then(function() {
        const VIEWPOD = self.$store.getters.keywords.POD.VIEWPOD;
        return self.$store.dispatch(VIEWPOD, self.pod.id);
      })
      .then(function(pod) {
        const REFRESHSUBTITLES = self.$store.getters.keywords.PLAYER.REFRESHSUBTITLES;
        return self.$store.dispatch(REFRESHSUBTITLES, pod);
      })
      .then(function() {
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      })
      .finally(function() {
        self.$emit('subtitleCreated');
      });
    },
    createSubtitleFile: function() {
      let self = this;
      const data = {
        podID: this.pod.id,
        payload: {
          filename: this.filename,
          filetype: this.filetype,
        }
      };

      const CREATESUBTITLEASSET = this.$store.getters.keywords.POD.CREATESUBTITLEASSET;
      this.$store.dispatch(CREATESUBTITLEASSET, data)
      .then(function(createdAsset) {
        createdAsset;
        self.dialog = false;

        // self.$parent.setSelectedAsset(createdAsset);
        // self.$parent.setActiveSubtitle(createdAsset);
        // self.$parent.cues = [];
        // self.$parent.refreshCues();
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      })
      .finally(function() {
        self.$emit('subtitleCreated');
      });
    },
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getTranscripts: function() {
      const GETTRANSCRIPTS = this.$store.getters.keywords.POD.TRANSCRIPT.GETTRANSCRIPTS;
      return this.$store.getters[GETTRANSCRIPTS];
    },
    getTranscriptOptions() {
      let options = [];
      for (let i = 0; i < this.getTranscripts.length; i++) {
        options.push({
          id: this.getTranscripts[i].id,
          name: this.getTranscripts[i].name,
        });
      }

      // options.push({
      //   id: -1,
      //   name: this.$t('routes.pod.noneCreateEmptyFile'),
      // });

      return options;
    },
  }
}
</script>

<style>
</style>
