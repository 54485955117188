<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podFiles-tree-actions-setAssetLanguage-activator">
        <slot></slot>
      </div>
    </template>


    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>{{$t('routes.pod.setAssetLanguageDialogTitle')}}</span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-on:submit.prevent="formSubmit()" data-cy="dialog-setAssetLanguage-modal">
        <v-card-text class="pb-0" data-cy="dialog-setAssetLanguage-selectLanguage">
          <!-- v-on:change="selectedLanguageChanged" -->
          <v-select
              v-model="selectedLanguage"
              v-bind:items="languages"
              v-bind:rules="selectedLangRules"

              item-text="name"
              item-value="id"
              v-bind:label="$t('routes.pod.setAssetLanguageDialogSelect')"
              solo flat
              required
              >
          </v-select>

          <div v-if="errorMessage" class="error" data-cy="dialog-setAssetLanguage-error">
            {{errorMessage}}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" data-cy="dialog-setAssetLanguage-ok">{{$t('common.submit')}}</v-btn>
          <v-btn text @click="dialog = false" data-cy="dialog-setAssetLanguage-false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "AssetLanguageDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      dialog: false,

      selectedLanguage: null,
      selectedLangRules: [
        v => !!v || this.$t('routes.pod.setAssetLanguageDialogRequied'),
      ],

      errorMessage: "",
    };
  },
  mounted: function() {
    this.setSelectedLanguage();
  },
  watch: {
    dialog: function(newValue) {
      if (newValue) {
        this.setSelectedLanguage();
        this.errorMessage = "";
      }
    }
  },
  methods: {
    setSelectedLanguage: function() {
      if (this.asset && this.asset.language) {
        const theLang = this.languages.find(x => x.id == this.asset.language);
        this.selectedLanguage = theLang ? theLang.id : null;
      } else {
        this.selectedLanguage = null;
      }
    },
    formSubmit: function() {
      const validForm = this.$refs.form.validate();
      this.errorMessage = "";
      if (!validForm) {
        return;
      }

      let data = {
        podID: this.pod.id,
        assetID: this.asset.id,
        languageID: this.selectedLanguage,
      };

      let self = this;
      const SETASSETLANGUAGE = this.$store.getters.keywords.POD.SETASSETLANGUAGE;
      this.$store.dispatch(SETASSETLANGUAGE, data)
      .then(function() {
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
  },
  computed: {
    languages: function() {
      const DBLANGUAGES = this.$store.getters.keywords.COMMON.DBLANGUAGES;
      return this.$store.getters[DBLANGUAGES];
    }
  }
}
</script>

<style>

</style>
