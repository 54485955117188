class showMessage {
  constructor() {
  }

  install(Vue) {
    Vue.prototype.$showMessage = function(message, options = {}) {
      const snack = {
        message: message,
      };

      switch (options.type) {
        case 'info':
          snack.color = "info";
          break;
        case 'warning':
          snack.color = "warning";
          break;
        case 'error':
          snack.color = "error";
          break;

          default:
            snack.color = "success";
            break;
      }

      if (options.color) {
        snack.color = options.color;
      }

      const CREATESNACKBAR = this.$store.getters.keywords.MAIN.CREATESNACKBAR;
      this.$store.commit(CREATESNACKBAR, snack, {root: true});
    }
  }
}

export default new showMessage();
