<template>
  <v-dialog
      v-model="dialog"
      v-bind:disabled="isDialogDisabled"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-exportSubtitle-activator">
        <slot></slot>
      </div>
    </template>


    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t('routes.pod.menuTranscript.exportDialogTitle')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-on:submit.prevent="formSubmit()" data-cy="dialog-exportSubtitle-modal">
        <v-card-text class="pb-0">
          <div>
            {{$t('routes.pod.menuTranscript.exportDialogFilename')}}:
          </div>

          <div>
            <v-text-field
              type="text"
              v-model="filename"
              v-bind:rules="filenameRules"
              required block
              solo flat
              data-cy="dialog-exportSubtitle-fileName"
            >
            </v-text-field>
          </div>

          <div>
            <v-radio-group v-model="filetype" mandatory>
              <v-tooltip bottom z-index="1200">
                <template v-slot:activator="{ on, attrs }">
              <v-radio label="VTT" value="vtt" v-bind="attrs" v-on="on" data-cy="dialog-exportSubtitle-vttRadio"></v-radio>
                </template>
                <span>{{$t('routes.pod.menuTranscript.transcriptToVttFile')}}</span>
              </v-tooltip>
              <v-tooltip bottom z-index="1200">
                <template v-slot:activator="{ on, attrs }">
                  <v-radio label="SRT" value="srt" v-bind="attrs" v-on="on" data-cy="dialog-exportSubtitle-srtRadio"></v-radio>
                </template>
                <span>{{$t('routes.pod.menuTranscript.transcriptToSrtFile')}}</span>
              </v-tooltip>
              <v-tooltip bottom z-index="1200">
                <template v-slot:activator="{ on, attrs }">
                  <v-radio label="TXT" value="txt" v-bind="attrs" v-on="on" data-cy="dialog-exportSubtitle-txtRadio"></v-radio>
                </template>
                <span>{{$t('routes.pod.menuTranscript.transcriptToPlainTextFile')}}</span>
              </v-tooltip>
            </v-radio-group>
          </div>

          <div v-if="errorMessage" class="error" data-cy="dialog-exportSubtitle-fileTypeError">
            {{errorMessage}}
          </div>

          <div class="error" v-if="errorTranslatable">
            {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :disabled="formSubmitted" data-cy="dialog-exportSubtitle-ok">{{$t('common.export')}}</v-btn>
          <v-btn text @click="dialog = false" data-cy="dialog-exportSubtitle-false">{{$t('common.cancel')}}</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "TranscriptExportDialog",
  props: {
    transcript: {
      type: Object,
      required: false,
    },
  },
  data: function() {
    return {
      dialog: false,

      filename: "",
      filenameRules: [
        v => !!v || this.$t('routes.pod.menuTranscript.exportDialogInvalidName')
      ],

      filetype: "vtt",
      errorMessage: "",
      errorTranslatable: null,

      formSubmitted: false,
    }
  },
  mounted: function() {
    // not working, it's aways mounted
    this.filename = "";
    this.filetype = "vtt";
  },
  watch: {
    dialog() {
      this.resetForm();
    },
  },
  methods: {
    resetForm() {
      if(this.$refs.form) {
        this.$refs.form.reset();
      }

      this.formSubmitted = false;
      this.errorMessage = "";
      this.errorTranslatable = null;

      this.$nextTick(() => {
        this.filename = "";
        this.filetype = "vtt";
      });
    },
    formSubmit: function() {
      const validForm = this.$refs.form.validate();
      this.errorMessage = "";
      this.errorTranslatable = null;

      if (
          !Array.isArray(this?.transcript?.sentences) ||
          (Array.isArray(this?.transcript?.sentences) && this.transcript.sentences.length == 0)
      ) {
        this.errorMessage = this.$t('routes.pod.menuTranscript.emptyTranscriptError');
        return;
      }

      if (!validForm) {
        return;
      }

      this.formSubmitted = true;

      const data = {
        podID: this.pod.id,
        id: this.transcript.id,
        payload: {
          filename: this.filename,
          filetype: this.filetype,
        }
      };

      let self = this;
      const GENERATEFILE = this.$store.getters.keywords.POD.TRANSCRIPT.GENERATEFILE;

      this.$store.dispatch(GENERATEFILE, data)
      .then(function() {
        const VIEWPOD = self.$store.getters.keywords.POD.VIEWPOD;
        return self.$store.dispatch(VIEWPOD, self.pod.id);
      })
      .then(function(pod) {
        const REFRESHSUBTITLES = self.$store.getters.keywords.PLAYER.REFRESHSUBTITLES;
        return self.$store.dispatch(REFRESHSUBTITLES, pod);
      })
      .then(function() {
        self.dialog = false;
        self.formSubmitted = false;
      })
      .catch(function(error) {
        self.formSubmitted = false;

        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    }
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    isDialogDisabled: function() {
      return !this.transcript ||
             !this.transcript.sentences ||
             !Array.isArray(this.transcript.sentences);
    }
  }
}
</script>

<style>

</style>
