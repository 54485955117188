import API from '../AxiosInstance';

export default {
    getAllServices: function() {
      const body = {};

      return API.get('services', body);
    },

    getServiceOptions: function(serviceAlias) {
      const body = {};
      return API.get('services/' + serviceAlias + "/options", body);
    },

    getPodsUtility: function(serviceAlias, nodeIDs, podIDs, assetID, sourceLanguage, targetLanguage, action) {
      const options = {
        params: {
          nodes: nodeIDs.length > 0 ? nodeIDs : '',
          pods: podIDs.length > 0 ? podIDs : '',
          assetid: assetID,
          sourcelanguage: sourceLanguage,
          targetlanguage: targetLanguage,
          action: action,
        },
      };

      return API.get('services/' + serviceAlias, options);
    },

    runService: function(serviceAlias, nodeIDs, podIDs, params) {
      const body = {
        nodes: nodeIDs,
        pods: podIDs,
        params: params,
      };

      return API.post('services/' + serviceAlias, body);
    },



    // get list of the resources - limit and consumed units
    getResources: function(payload) {
      const body = {};
      return API.get(`tenants/${payload.accessPointId}/resources`, body);
    },

    checkResource: function(resourceName, units, contextNodeId) {
      const body = {};
      return API.get(`tenants/${contextNodeId}/resources/${resourceName}/status?units=${units}`, body);
    }
}
