import Api from '../../api/Api';

export default {
  namespaced: true,
  state: {
    nodes: [],
    selectedNode: null,
    retentionPeriod: 90,  // Mind you, this must be in sync with the backend!
    isRefreshingNodes: false,

    selectedPodIds: [],
  },
  getters: {
    getAllNodes: function (state) {
      return state.nodes;
    },


    getNodeById: function (state) {
      return (nodeId) => state.nodes.find((node) => node.id === nodeId);
    },
    getChildNodes: function (state) {
      return (parentNodeId) => state.nodes.filter((node) => node.parentid === parentNodeId);
    },
    selectedNode: function (state) {
      return state.selectedNode;
    },
    getRetentionPeriod: function (state) {
      return state.retentionPeriod;
    },
    getIsRefreshingNodes: function (state) {
      return state.isRefreshingNodes;
    },
    getAllPods(state) {
      let pods = [];

      for (let i=0; i < state.nodes.length; i++) {
        if (Array.isArray(state.nodes[i].pods)) {
          pods = pods.concat(state.nodes[i].pods);
        }
      }

      return pods
      .filter((pod) => pod.deletedat !== null)
      .filter((pod) => pod.parentpodid === null);
    },
    getSelectedPods(state, getters) {
      return getters.getAllPods.filter((pod) => state.selectedPodIds.includes(pod.id));
    },
    getDeletedPodVersions(state) {
      return function(podId) {
        let pods = [];

        for (let i=0; i < state.nodes.length; i++) {
          if (Array.isArray(state.nodes[i].pods)) {
            const matchedPods = state.nodes[i].pods
            .filter((pod) => pod.deletedat !== null)
            .filter((pod) => pod.parentpodid === podId || pod.id === podId);

            pods = pods.concat(matchedPods);
          }
        }

        return pods;
      }
    },
  },
  mutations: {
    setNodes: function(state, nodes) {
      state.nodes = nodes;
    },
    setRetentionPeriod: function (state, period) {
      state.retentionPeriod = period;
    },
    setIsRefreshingNodes: function (state, value) {
      console.assert(typeof value === 'boolean');
      state.isRefreshingNodes = value;
    },
    setSelectedNode: function (state, nodeId) {
      if (nodeId === null) {
        state.selectedNode = null;
      } else if (!state.nodes.some((node) => node.id === nodeId)) {
        console.error(`Unable to find node with id ${nodeId}`);
      } else {
        state.selectedNode = nodeId;
      }
    },
    updateRecycleBinNode(state, node) {
      for (let i = 0; i < state.nodes.length; i++) {
        if (state.nodes[i].id === node.id) {
          let nodePods = state.nodes[i].pods;
          Object.assign(state.nodes[i], node);
          state.nodes[i].pods = nodePods;
          break;
        }
      }
    },
    updateRecycleBinPod(state, pod) {
      for (let i = 0; i < state.nodes.length; i++) {
        if (Array.isArray(state.nodes[i].pods)) {
          for (let j = 0; j < state.nodes[i].pods.length; j++) {
            if (state.nodes[i].pods[j].id === pod.id) {
              Object.assign(state.nodes[i].pods[j], pod);
            }
          }
        }
      }

      if (!pod.deletedat) {
        state.selectedPodIds = state.selectedPodIds.filter((id) => id !== pod.id);
      }
    },
    removePodById(state, podId) {
      // Delete pods, but keep the nodes with empty pods array
      for (let i = 0; i < state.nodes.length; i++) {
        if (!Array.isArray(state.nodes[i].pods)) {
          continue;
        }

        for (let j = 0; j < state.nodes[i].pods.length; j++) {
          if (state.nodes[i].pods[j].id === podId) {
            state.nodes[i].pods.splice(j, 1);
            break;
          }
        }
      }

      state.selectedPodIds = state.selectedPodIds.filter((id) => id !== podId);
    },
    togglePodSelection(state, podId) {
      if (state.selectedPodIds.includes(podId)) {
        state.selectedPodIds = state.selectedPodIds.filter((id) => id !== podId);
      } else {
        state.selectedPodIds.push(podId);
      }
    },
    deselectAllPods(state) {
      state.selectedPodIds = [];
    },
  },
  actions: {
    refreshNodesList: function (context, payload) {
      if (!payload.silent) {
        context.commit('setIsRefreshingNodes', true);
      }

      return Api.recycleBin.listNodes(payload.rootNodeId).then((results) => {
        context.commit('setNodes', results.data);
      }).finally(() => {
        if (!payload.silent) {
          context.commit('setIsRefreshingNodes', false);
        }
      });
    },
    permanentlyDeletePods: function (context, podIds) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.permanentlyDeletePods(rootNodeId, podIds).then(() => {
        context.commit('removePodById', podIds);
      });
    },
    restorePods: function (context, podIds) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.restorePods(rootNodeId, podIds).then(() => {
        context.commit('removePodById', podIds);
      });
    },
    emptyRecycleBin: function (context) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.emptyRecycleBin(rootNodeId).then(() => {
        context.commit('setNodes', []);
      });
    },
    updateRecycleBinPod: function(context, pod) {
      context.commit('updateRecycleBinPod', pod);
    },
    updateRecycleBinNode(context, node) {
      context.commit('updateRecycleBinNode', node);
    },
    podPermanentlyDeletedNotification: function(context, podids) {
      for (let i = 0; i < podids.length; i++) {
        context.commit('removePodById', podids[i]);
      }
    },
    togglePodSelection: function (context, podId) {
      context.commit('togglePodSelection', podId);
    },
    deselectAllPods: function (context) {
      context.commit('deselectAllPods');
    },
  },
}
