<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :disabled="disabled"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-uploadFiles-activator">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-uploadFiles-modal">
      <v-card-title class="primary d-flex justify-space-between" data-cy="dialog-uploadFiles-title">
        <span>{{$t('menus.uploadFiles.uploadFilesAndFolders')}}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs v-model="activeTab" background-color="primary" dark>
        <v-tab>
          <v-icon left>mdi-upload-box-outline</v-icon>
          {{$t('menus.uploadFiles.uploadFiles')}}
        </v-tab>
        <v-tab v-if="willUploadFiles">
          <v-icon left>mdi-label</v-icon>
          {{$t('menus.uploadFiles.labels')}}

          ({{selectedLabelsCount}})
        </v-tab>
      </v-tabs>

      <v-card-text class="scrollable mt-4">
        <v-tabs-items v-model="activeTab">
          <v-tab-item  @dragenter.capture.stop="dragEnter">
            <!-- We don't care about the "dragover" event, but require it to present in order to indicate a drop zone -->
            <div v-if="isDragging"
                 class="upload-list-drop-zone d-flex flex-column justify-center align-center"
                 @dragleave.capture.stop="dragLeave"
                 @drop.stop.prevent="droppedFiles"
                 @dragover.stop.prevent="() => {}"
            >
              <div class="upload-list-drop-zone-icon">
                <i aria-hidden="true" class="v-icon notranslate mdi mdi-image-multiple-outline theme--light"/>
              </div>
              <div class="upload-list-drop-zone-label">{{ $t('routes.node.dropFilesHere') }}</div>
            </div>
            <v-list class=" text-left upload-list_list" flat dense >
              <v-list-group v-if="completedFiles.length > 0" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ $tc('menus.uploadFiles.NFilesUploaded', completedFiles.length) }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                    v-for="item in completedFiles"
                    :key="item.meta.fileuuid"
                    :ripple="false"
                    class="upload-list-item_item pl-4"
                    style="margin-left: 40px !important;"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!pod">
                        {{ item.meta.nodename }} /
                      </span>

                      <v-tooltip bottom z-index="1200">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{item.meta.filename}}</span>
                        </template>
                        <span>{{getFilenameTooltip(item)}}</span>
                      </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="upload-list-item_subtitle">
                      <div class="d-flex justify-space-between">
                        <div v-bind:class="{error: item.status == STATUSES.ERROR}" class="d-flex align-center upload-list-item_subtitle">
                          {{ getItemSubtitle(item) }}
                        </div>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon
                      v-show="showItemCancelButton(item)"
                      v-on:click="removeSelectedFile(item)"
                      class="ml-0">
                    <v-icon  color="primary">mdi-close</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>


              <v-list-item-group color="primary">
                <v-list-item
                    v-for="item in notCompletedFiles"
                    :key="item.meta.fileuuid"
                    :ripple="false"
                    class="upload-list-item_item"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!pod">
                        {{ item.meta.nodename }} /
                      </span>

                      <v-tooltip bottom z-index="1200">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{item.meta.filename}}</span>
                        </template>
                        <span>{{getFilenameTooltip(item)}}</span>
                      </v-tooltip>
                    </v-list-item-title>

                    <!-- Subtitle elements -->
                    <v-list-item-subtitle class="upload-list-item_subtitle">
                      <div class="d-flex justify-space-between">
                        <div v-if="showItemTeamonlyCheckbox(item) || showingShowNotesCheckbox(item)">
                          <v-checkbox
                            v-show="showItemTeamonlyCheckbox(item)"
                            v-bind:value="item.teamonly"
                            v-bind:label="$t('routes.node.teamonlyAccess')"
                            v-on:change="toggleTeamonly(item)"
                            dense
                            hide-details
                          >
                          </v-checkbox>

                          <v-checkbox
                            v-if="showingShowNotesCheckbox(item)"
                            v-model="showNotes[item.meta.fileuuid]"
                            v-bind:label="$t('menus.uploadFiles.showNotes')"
                            dense
                            hide-details
                          >
                          </v-checkbox>
                        </div>


                        <div v-bind:class="{error: item.status == STATUSES.ERROR}" class="d-flex align-center upload-list-item_subtitle">
                          {{ getItemSubtitle(item) }}
                        </div>
                      </div>


                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-show="item.status == STATUSES.UPLOADING" class="upload-list-item_subtitle">
                      <v-progress-linear v-bind:value="item.progress.percentage"></v-progress-linear>
                    </v-list-item-subtitle>

                    <!-- <v-list-item-subtitle v-bind:class="{error: item.status == STATUSES.ERROR}" class="upload-list-item_subtitle">
                      {{ getItemSubtitle(item) }}
                    </v-list-item-subtitle> -->
                    <!-- /Subtitle elements -->

                    <v-select
                        v-if="pod && item.status == STATUSES.ADDED"
                        v-model="typesModel[item.meta.fileuuid]"
                        v-on:change="setAssetTypes"
                        v-bind:items="assetTypes"
                        v-bind:label="$t('menus.uploadFiles.assetType')"
                        dense solo hide-details
                    >
                    </v-select>


                    <v-textarea
                    v-if="showingShowNotesCheckbox(item)"
                    v-show="showNotes[item.meta.fileuuid]"
                    @input="setNote(item, $event)"
                    :disabled="item.status !== STATUSES.ADDED"
                    flat solo
                    dense outlined
                    rows="2"
                    background-color="#373f48"
                    hide-details
                    label=""
                    value="">
                    </v-textarea>

                  </v-list-item-content>

                  <!-- Icons at the end -->
                  <v-list-item-icon
                      v-show="showItemCancelButton(item)"
                      v-on:click="removeSelectedFile(item)"
                      class="ml-0">
                    <v-icon  color="primary">mdi-close</v-icon>
                  </v-list-item-icon>

                </v-list-item>
              </v-list-item-group>


              <v-list-item-group color="primary">
                <v-list-item v-on:click="browseFiles" class="upload-list-action">
                  <v-list-item-icon class="mr-1">
                    <v-icon color="primary">mdi-attachment</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title color="primary">{{$t('menus.uploadFiles.browseFiles')}}</v-list-item-title>
                    <v-list-item-subtitle class="upload-list-item_subtitle" color="primary">
                      {{$t('menus.uploadFiles.clickToAddFiles')}}
                      <input type="file" ref="fileInput" v-on:change="filesSelected" style="display: none" multiple data-cy="dialog-uploadFiles-inputFiles">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-on:click="browseFolders" class="upload-list-action">
                  <v-list-item-icon class="mr-1">
                    <v-icon color="primary">mdi-attachment</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title color="primary">{{$t('menus.uploadFiles.browseFolders')}}</v-list-item-title>
                    <v-list-item-subtitle class="upload-list-item_subtitle" color="primary">
                      {{$t('menus.uploadFiles.clickToAddFolders')}}
                      <input type="file" ref="foldersInput" v-on:change="filesSelected" style="display: none" multiple webkitdirectory data-cy="dialog-uploadFiles-inputFolders">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>

          <v-tab-item v-if="willUploadFiles">
            <UploadDialogCustomLabels @labelsChanged="updateSelectedLabels"></UploadDialogCustomLabels>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <APPresetDialog v-if="$isAdobeExtension()" v-bind:data="{useruuid: USER.userId, nodeid: currentNode.id.toString() }" >
          <v-btn text>
            {{$t('menus.uploadFiles.addSequence')}}
          </v-btn>
        </APPresetDialog>

        <v-spacer></v-spacer>
        <v-btn
            v-show="hasAddedFiles"
            v-on:click="uploadAllFiles"
            color="primary"
            elevation="0"
            data-cy="dialog-uploadFiles-uploadAllButton"
        >
          {{$t('menus.uploadFiles.uploadAll')}}
        </v-btn>

        <v-btn text @click="dialog = false" data-cy="dialog-uploadFiles-closeButton">{{$t('common.close')}}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import Vue from 'vue';
import APPresetDialog from '@/routes/nodes2/components/dialogs/APPresetDialog.vue';
import UploadDialogCustomLabels from './components/UploadDialogCustomLabels.vue';
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "UploadFilesDialog",
  components: {
    APPresetDialog,
    UploadDialogCustomLabels,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    pod: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      typesModel: {},   // set's the type of the assets when uploading asset
      activeTab: 0, // To control the active tab
      labels: [],
      isDragging: false,
      showNotes: {},
    };
  },
  watch: {
    getMainviewDropfilesEvent(event) {
      if (event) {
        this.droppedFiles(event);
        this.dialog = true;


        const SETMAINVIEWDROPFILESEVENT = this.$store.getters.keywords.UPLOAD.SETMAINVIEWDROPFILESEVENT;
        this.$store.dispatch(SETMAINVIEWDROPFILESEVENT, null);
      }
    },
    dialog: function (val) {
      if (val) {
        this.resetState();
      }
    },
  },
  methods: {
    setAssetTypes: function() {
      const SETASSETTYPE = this.$store.getters.keywords.UPLOAD.SETASSETTYPE;

      for (const prop in this.typesModel) {
        if (Object.prototype.hasOwnProperty.call(this.typesModel, prop)) {
          let payload = {
            fileuuid: prop,
            value: this.typesModel[prop],
          };

          this.$store.dispatch(SETASSETTYPE, payload);
        }
      }
    },
    removeSelectedFile: function(item) {
      const REMOVEFILE = this.$store.getters.keywords.UPLOAD.REMOVEFILE;
      this.$store.dispatch(REMOVEFILE, item);
    },
    toggleTeamonly: function(item) {
      const TOGGLETEAMONLY = this.$store.getters.keywords.UPLOAD.TOGGLETEAMONLY;
      this.$store.dispatch(TOGGLETEAMONLY, item);
    },
    setNote(item, event) {
      let payload = {
        file: item,
        note: event,
      };
      const SETNOTE = this.$store.getters.keywords.UPLOAD.SETNOTE;
      this.$store.dispatch(SETNOTE, payload);
    },
    uploadAllFiles: async function() {
      const payload = {
        labels: this.labels,
      };

      const UPLOADALL = this.$store.getters.keywords.UPLOAD.UPLOADALL;
      await this.$store.dispatch(UPLOADALL, payload);

      const nodeId = this.node.id;

      // refresh the nodes and the subnodes if the uploaded files contains directory in its relative path
      if (nodeId === this.currentNode.id && this.needsToRefreshSubdirectories) {
        const VIEWNODE = this.$store.getters.keywords.NODE.VIEWNODE;
        this.$store.dispatch(VIEWNODE, nodeId);
      }
    },
    browseFiles: function() {
      this.$refs.fileInput.click();
    },
    browseFolders: function() {
      this.$refs.foldersInput.click();
    },
    filesSelected: function(event) {
      if (event.target.files && event.target.files.length > 0) {
        const ADDFILES = this.$store.getters.keywords.UPLOAD.ADDFILES;
        let payload = {
          nodeID: this.node.id,
          podID: this.pod ? this.pod.id : null,
          fileList: event.target.files,
        };
        this.$store.dispatch(ADDFILES, payload);
        this.$refs.fileInput.value = null;
        this.$refs.foldersInput.value = null;
      }
    },
    dragEnter: function (event) {
      if (event?.dataTransfer?.items?.length > 0) {
        this.isDragging = true;
      }
    },
    dragLeave: function () {
      this.isDragging = false;
    },
    droppedFiles: async function (event) {
      this.isDragging = false;

      const dropItems = [];
      let filesList = [];

      if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if ('getAsEntry' in event.dataTransfer.items[i] && typeof event.dataTransfer.items[i].getAsEntry === 'function') {
            /**
             * <Quote MDN>
             * Note: This function is implemented as webkitGetAsEntry() in non-WebKit browsers including Firefox at this time;
             * it may be renamed to getAsEntry() in the future, so you should code defensively, looking for both.
             * </Quote MDN>
             */
            dropItems.push(event.dataTransfer.items[i].getAsEntry());
          } else {
            dropItems.push(event.dataTransfer.items[i].webkitGetAsEntry());
          }
        }
      }

      // the async read of files is on a separate step to fix an issue with chrome
      for (let i = 0; i < dropItems.length; i++) {
        if (dropItems[i]) {
          let foundFiles = await this.readFilesystemItem(dropItems[i], '');
          filesList = filesList.concat(foundFiles);
        }
      }

      if (filesList.length > 0) {
        const ADDFILES = this.$store.getters.keywords.UPLOAD.ADDFILES;
        let payload = {
          nodeID: this.node.id,
          podID: this.pod ? this.pod.id : null,
          fileList: filesList,
        };
        this.$store.dispatch(ADDFILES, payload);
        this.$refs.fileInput.value = null;
        this.$refs.foldersInput.value = null;
      }
    },
    readFilesystemItem: async function(dropItem, fullPath) {
      let self = this;


      return new Promise(function(resolve, reject) {
        reject;

        // Return the file itself
        if (dropItem.isFile) {
          dropItem.file(function(fsFile) {

            // Fix the webkitRelativePath for the chrome browsers
            let filePath = fullPath + "/" + fsFile.name;
            if (filePath.startsWith('/')) {
              filePath = filePath.replace('/', '');
            }
            Object.defineProperty(fsFile, 'webkitRelativePath', {get: function() {return filePath}});

            resolve(fsFile);
          });
        }

        // Read the other directories recursively
        if (dropItem.isDirectory) {
          let dirReader = dropItem.createReader();
          dirReader.readEntries(async function(entries) {
            let filesArray = [];
            for (let i = 0; i < entries.length; i++) {
              let fileReaded = await self.readFilesystemItem(entries[i], dropItem.fullPath);
              filesArray = filesArray.concat(fileReaded);
            }

            resolve(filesArray);
          });
        }

      });
    },
    resetState() {
      this.errorMessage = '';
    },
    updateSelectedLabels(labels) {
      this.labels = labels;
    },
  },
  computed: {
    assetTypes: function() {
      let options = [
        {value: 8, text: this.$t('menus.uploadFiles.assetTypeAuto')},
        {value: 1, text: this.$t('menus.uploadFiles.assetTypeThumbnail')},
        {value: 7, text: this.$t('menus.uploadFiles.assetTypeSubtitle')}
      ];

      return options;
    },
    currentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE]
    },
    selectedFiles: function() {
      const FILES = this.$store.getters.keywords.UPLOAD.FILES;
      const storedFiles = this.$store.getters[FILES];
      let selectedFiles = [];

      for (let i = 0; i < storedFiles.length; i++) {
        if (this.pod && storedFiles[i].podid && storedFiles[i].podid == this.pod.id) {
          selectedFiles.push(storedFiles[i]);
          continue;
        }

        if (!this.pod && !storedFiles[i].podid && storedFiles[i].status == this.STATUSES.ADDED) {
          selectedFiles.push(storedFiles[i]);
          continue;
        }

        if (!this.pod && !storedFiles[i].podid && this.node) {
          selectedFiles.push(storedFiles[i]);
          continue;
        }

        // if (!this.pod && !storedFiles[i].podid && this.node && (this.node.id == storedFiles[i].meta.nodeid)) {
        //   selectedFiles.push(storedFiles[i]);
        //   continue;
        // }
      }

      return selectedFiles;
    },
    completedFiles: function() {
      return this.selectedFiles.filter(item => item.status === this.STATUSES.FINISHED);
    },
    notCompletedFiles: function() {
      return this.selectedFiles.filter(item => item.status !== this.STATUSES.FINISHED);
    },
    STATUSES: function() {
      const STATUSES = this.$store.getters.keywords.UPLOAD.STATUSES;
      return this.$store.getters[STATUSES];
    },
    USER: function() {
      const GETUSER = this.$store.getters.keywords.AUTH.GETUSER;
      return this.$store.getters[GETUSER];
    },
    hasAddedFiles: function() {
      for(let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].status === this.STATUSES.ADDED) {
          return true;
        }
      }

      return false;
    },
    showItemTeamonlyCheckbox: function() {
      return function(item) {
        return  !this.pod &&
            item.status == this.STATUSES.ADDED &&
            this.$canBelongstoTeam();
      }
    },
    showingShowNotesCheckbox: function() {
      return function(item) {
        return !this.pod && item.status == this.STATUSES.ADDED;
      }
    },
    showItemCancelButton: function() {
      return function(item) {
        return  item.status == this.STATUSES.ADDED ||
            item.status == this.STATUSES.FINISHED ||
            item.status == this.STATUSES.ERROR;
      }
    },
    getItemSubtitle: function() {
      return function(item) {
        let text = "";
        switch (item.status) {
          case this.STATUSES.ADDED: text = "("+ Vue.filter('prettyBytes')(item.meta.filesize, 0, false) +")"; break;
          case this.STATUSES.WAITING: text = this.$t('menus.uploadFiles.statusWaiting'); break;
          case this.STATUSES.UPLOADING:
            text = "" + Vue.filter('prettyBytes')(item.progress.uploaded, 0, false);
            text += " of " + Vue.filter('prettyBytes')(item.meta.filesize, 0, false);
            break;
          case this.STATUSES.FINISHED: text = this.$t('menus.uploadFiles.statusFinished'); break;
          case this.STATUSES.ERROR: text = item.errorMessage ? this.$t(item.errorMessage) : this.$t('menus.uploadFiles.statusError'); break;
          default: text = this.$t('menus.uploadFiles.statusUnknown'); break;
        }
        return text;
      }
    },
    getFilenameTooltip: function() {
      return function(item) {
        if (item && item.meta && item.meta.webkitRelativePath) {
          return item.meta.webkitRelativePath;
        }
        return item.meta.filename;
      }
    },
    getViewPod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    needsToRefreshSubdirectories: function() {
      if (this.pod || this.getViewPod) {
        return false;
      }

      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i] &&
            this.selectedFiles[i].file &&
            this.selectedFiles[i].file.webkitRelativePath &&
            this.selectedFiles[i].file.webkitRelativePath.indexOf('/') >= 0) {
          return true;
        }
      }
      return false;
    },
    getMainviewDropfilesEvent() {
      const GETMAINVIEWDROPFILESEVENT = this.$store.getters.keywords.UPLOAD.GETMAINVIEWDROPFILESEVENT;
      return this.$store.getters[GETMAINVIEWDROPFILESEVENT];
    },
    willUploadFiles: function() {
      // If the pod is not set, then the user is uploading assets to the pod.
      return !this?.pod?.id;
    },
    selectedLabelsCount: function() {
      return this.labels.length;
    },
  },
}
</script>

<style scoped lang="scss">
.upload-list_list {
  width: 100%;
  border: 1px solid #3dabff      !important;
  border-radius: 6px !important;
}

.theme--dark.upload-list_list {
  background-color: #2f3640 !important;
}
.theme--light.upload-list_list {
  background-color: #e6ecf1 !important;
}


.upload-list-item_subtitle {
  white-space: unset !important;
  text-overflow: unset !important;
  overflow: unset !important;
}

.upload-list-item_item {
  margin: 4px;
  border: 1px #a1a1a1 inset;
  border-radius: 6px;
}

.upload-list_list .v-list-item__title {
  font-size: large !important;
}

.upload-list_list .v-list-item__subtitle {
  font-size: small !important;
  font-style: italic !important;
  margin-top: 3px !important;
}

.upload-list_list .upload-list-action {
  margin-top: 10px;
}

.upload-list-drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 1.5rem;

  &-icon > i {
    font-size: 2.5rem;
    pointer-events: none;
  }

  &-label {
    margin-top: 0.75rem;
    font-weight: 400;
    color: rgb(0, 0, 0, 0.5);
    pointer-events: none;
  }
}
</style>
