// import ... API

export default {
  namespaced: true,
  state: {
    isDarkMode: window.localStorage.isDarkMode !== undefined ? Boolean(parseInt(window.localStorage.isDarkMode)) : true,
    sidemenuToggle: null,

    layout: 'div',
    activeMenu: '',
    activeMenuView: '',
    podRightMenu: '',
    snackbars: [],
  },
  getters: {
    getDarkMode(state) {
      return state.isDarkMode;
    },
    getSidemenuToggle(state) {
      return state.sidemenuToggle;
    },

    layout: function (state) {
      return state.layout;
    },

    getActiveMenu: function(state) {
      return state.activeMenu;
    },

    getPodRightMenu: function(state) {
      return state.podRightMenu;
    },

    getSnackbars: function(state) {
      return state.snackbars;
    },

    getActiveMenuView: function(state) {
      return state.activeMenuView;
    },
  },
  mutations: {
    setDarkMode(state, mode) {
      const isDarkMode = Boolean(mode);
      state.isDarkMode = isDarkMode;
      window.localStorage.isDarkMode = isDarkMode ? 1 : 0;
    },
    toggleSidemenu(state) {
      state.sidemenuToggle = !state.sidemenuToggle;
    },

    setLayout: function(state, layoutName) {
      if (state.layout !== layoutName) {
        state.layout = layoutName;
      }
    },

    setActiveMenu: function(state, menuName) {
      state.activeMenu = menuName;
    },

    setPodRightMenu: function(state, menuName) {
      state.podRightMenu = menuName;
    },

    createSnackbar: function(state, snack) {
      // allow snackbars with unique texts only
      if (state.snackbars.find(x => x.i18n === snack.i18n) && state.snackbars.find(x => x.replaceTCode === snack.replaceTCode)) {
        return;
      }

      snack.id = new Date().getTime();

      // create snackbar object
      // const snackbar = {
      //   id: new Date().getTime(),
      //   i18n: snack.i18n,
      //   replaceTCode: snack.replaceTCode,
      //   replaceTParams: snack.replaceTParams,
      //   replaceTFallback: snack.replaceTFallback,
      //   color: snack.color,
      // };

      state.snackbars.push(snack);
    },

    removeSnackbar: function(state, snackbarData) {
      state.snackbars = state.snackbars.filter(x => x.id !== snackbarData.id);
    },

    setActiveMenuView: function(state, viewName) {
      state.activeMenuView = viewName;
    },
  },
};
