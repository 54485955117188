import Vue from 'vue'
import moment from 'moment';

// input is integer timestamp
Vue.filter('toDateTime', function(value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw "toDate parameter must be unix timestamp.";
  }

  return moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
});

// input is javascript ISO date string
Vue.filter('isoToDatetime', function(value) {
  if (typeof value !== 'string') {
    throw "toDate parameter must be unix timestamp.";
  }

  // if (value.length == 0) {
  //   return '';
  // }

  return moment(value).format('YYYY-MM-DD HH:mm:ss');
});

// input is javascript ISO date string
Vue.filter('isoToDate', function(value) {
  if (typeof value === 'number') {
    return moment.unix(value).format('YYYY-MM-DD');
  }

  if (typeof value !== 'string') {
    throw "toDate parameter must be datetime string or timestamp.";
  }

  // if (value.length == 0) {
  //   return '';
  // }

  return moment(value).format('YYYY-MM-DD');
});
