import API from '../AxiosInstance';

export default {
  getUsersData: function() {
    return API.get('logs/users');
  },
  getUserUsage: function(userId) {
    return API.get('logs/users/' + userId);
  },


  getTenantsData: function() {
    return API.get('logs/tenants');
  },
  getTenantUsage: function(tenantId) {
    return API.get('logs/tenants/' + tenantId);
  },
}
