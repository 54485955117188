<template>
  <v-app dark id="loading-layout">

    <v-main>
      <v-container fluid style="min-height: 100%; display: flex; justify-content: center;">
        <div class="d-flex align-center" style="">

          <v-progress-circular
          :size="80"
          :width="10"
          indeterminate
          color="primary"
          ></v-progress-circular>


            <router-view></router-view>

        </div>
      </v-container>
    </v-main>


  </v-app>
</template>

<script>
export default {
  name: "LoadingLayout",
}
</script>

<style>
</style>