import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    subscription: null,
  },
  getters: {
    getSubscription: function(state) {
      return state.subscription;
    },
  },
  mutations: {
    setSubscription: function(state, subscription) {
      state.subscription = subscription;
    },
  },
  actions: {
    onBoardTenant: function(context, payload) {
      return Api.system.onBoardTenant(payload);
    },
    getTenantEmails: function(context, contextNodeId) {
      return Api.system.getTenantEmails(contextNodeId);
    },
    saveTenantEmails: function(context, payload) {
      return Api.system.saveTenantEmails(payload.contextNodeId, payload.body);
    },
    validateTenantEmail: function(context, payload) {
      return Api.system.validateTenantEmail(payload.id, payload.code);
    },

    manageSubscriptionUrl: function(context, accessPointId) {
      context;
      return Api.system.manageSubscriptionUrl(accessPointId);
    },

    getBrandingInfo: function(context, payload) {
      return Api.system.getBrandingInfo(payload.nodeid);
    },
    saveBrandingInfo: function(context, payload) {
      return Api.system.saveBrandingInfo(payload.nodeid, payload.body);
    },
    deleteBrandLogo: function(context, payload) {
      return Api.system.deleteBrandLogo(payload.nodeid);
    },

    refreshSubscription: function(context, contextNodeId) {
      return Api.system.getSubscription(contextNodeId)
      .then(function(response) {
        context.commit('setSubscription', response.data);
        return response.data;
      });
    },
    subscribeForPlan(context, payload) {
      return Api.system.subscribeForPlan(payload.contextNodeId, payload.body);
    },
    buyAddons: function(context, payload) {
      return Api.system.buyAddons(payload.contextNodeId, payload.body);
    },

    updateTenantMFA: function(context, payload) {
      return Api.system.updateTenantMFA(payload);
    },
  },
}
