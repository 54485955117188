import API from '../AxiosInstance';

export default {
  fetchContent: function(podId, assetId) {
    const body = {};

    return API.get('/pods/' + podId + '/subtitles/' + assetId, body);
  },

  renameSubtitleAsset: function(data) {
    const body = data.payload;

    return API.patch('/pods/' + data.podId + '/subtitles/' + data.assetId + '/rename', body);
  },

  deleteSubtitleAsset: function(data) {
    const body = {};

    return API.delete('/pods/' + data.podId + '/subtitles/' + data.assetId, body);
  },
};
