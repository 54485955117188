import API from '../AxiosInstance';

export default {
  getComments: function(podID) {
    return API.get('pods/'+podID+'/comments');
  },

  createComment: function(podID, payload) {
    return API.post('/pods/' + podID + '/comments', payload);
  },

  updateComment: function(podID, commentID, payload) {
    return API.patch('/pods/' + podID + '/comments/' + commentID, payload);
  },

  deleteComment: function(podID, commentID) {
    return API.delete('/pods/' + podID + '/comments/' + commentID);
  },

  submitComments: function(podID, payload) {
    const body = {
      note: payload.note,
    };
    return API.post('/pods/' + podID + '/comments/submit', body);
  },

  likeComment: function(podID, commentId) {
    return API.post(`/pods/${podID}/comments/${commentId}/like`);
  },

  dislikeComment: function(podID, commentId) {
    return API.post(`/pods/${podID}/comments/${commentId}/dislike`);
  },

  checkComment: function(podID, commentId) {
    return API.post(`/pods/${podID}/comments/${commentId}/check`);
  },

  uncheckComment: function(podID, commentId) {
    return API.post(`/pods/${podID}/comments/${commentId}/uncheck`);
  },
}
