<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="290"
      style="z-index: 1100;"
      v-bind:disabled="isDisabledSelectingNode"
    >


    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot v-bind:selectedNode="selectedNode"></slot>
      </div>
    </template>

    <v-card v-cloak data-cy="dialog---">
      <v-card-title v-if="browseNode" class="primary d-flex justify-space-between" data-cy="dialog---">
        <span>{{browseNode.name}}</span>

        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-title v-else class="primary d-flex justify-space-between" data-cy="dialog---">
        {{$t('routes.users.selectFolder')}}

        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text scrollable class="scrollable" data-cy="dialog------">
        <v-list v-if="(nodeList && nodeList.length > 0) || (podsList && podsList.length > 0)">
          <v-list-item-group v-model="listGroupSelected" data-cy="dialog-copySelected-listGroup">
            <v-list-item
              v-for="node in nodeList"
              v-bind:key="node.id"
              v-on:click="displayNode(node.id)"
              data-cy="dialog-copySelected-listItem">

              <v-list-item-icon>
                <v-tooltip bottom z-index="2000" open-delay="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="getNodeIconName(node)"></v-icon>
                  </template>
                  <span>{{getNodeType(node)}}</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content data-cy="dialog-copySelected-itemContent">
                <v-tooltip bottom z-index="2000" open-delay="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on" v-text="node.name"></v-list-item-title>
                  </template>
                  <span>{{node.name}}</span>
                </v-tooltip>
              </v-list-item-content>

            </v-list-item>
          </v-list-item-group>

          <v-list-item-group v-model="listGroup2Selected" multiple data-cy="dialog----">
            <v-list-item v-for="pod in podsList" :key="pod.id" :value="pod.id">
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ pod.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div v-if="(!nodeList || nodeList.length == 0) && (!podsList || podsList.length === 0)" class="mt-4">
          {{$t('routes.node.noFolders')}}

        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="browseNode ? true : false"
          v-on:click="displayNode(browseNode.parentid)"
          text data-cy="dialog-copySelected-back-btn">
          <v-icon>mdi-arrow-left</v-icon>
          {{$t('common.back')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit()" v-bind:disabled="okDisabled" data-cy="dialog---">{{$t('common.select')}}</v-btn>
        <v-btn text @click="cancel()" data-cy="dialog---">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "SelectPodsDialog",
  props: {
    // value: {
    //   type: String,
    //   // required: true,
    // },
    isDisabledSelectingNode: {
      type: Boolean,
    }
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: '',
      browseNode: null,
      selectedNode: null,
      listGroupSelected: null,
      listGroup2Selected: [],
    }
  },
  mounted: function() {
    // if (this.value) {
    //   this.displayNode(this.value, true);
    // }
  },
  watch: {
    // value: function(newValue, oldValue) {
    //   newValue; oldValue;
    //   // if somebody updates the value from outside
    //   // then update the selectedNode and browseNode
    //   this.displayNode(this.value, true);
    // }
  },
  methods: {
    cancel: function() {
      this.dialog = false;
      this.errorMessage = '';
      this.listGroupSelected = null;
      this.listGroup2Selected = [];

      this.browseNode = this.selectedNode;
    },
    displayNode: async function(nodeID, updateSelectedNode = false) {
      this.listGroupSelected = null;
      this.listGroup2Selected = [];

      // sometimes this.browseNode.parentid is null
      if (nodeID) {
        const GETNODEINFO = this.$store.getters.keywords.NODE.GETNODEINFO;
        this.browseNode = await this.$store.dispatch(GETNODEINFO, nodeID);
        this.listGroupSelected = null;
        this.listGroup2Selected = [];

        const FETCHPODSBYNODEID = this.$store.getters.keywords.POD.FETCHPODSBYNODEID;
        this.$store.dispatch(FETCHPODSBYNODEID, nodeID);

      } else {
        this.browseNode = null;
      }

      if (updateSelectedNode) {
        this.selectedNode = this.browseNode;
      }
    },
    submit: function() {
      // console.log("listGroup2Selected", this.listGroup2Selected)

      if (this.browseNode) {
        this.$emit('selectedPods', this.listGroup2Selected);
        this.selectedNode = this.browseNode;

        this.listGroup2Selected = [];
      }

      this.dialog = false;
    },
  },
  computed: {
    nodeList: function() {
      if (this.browseNode) return this.browseNode.children || [];
      return this.accessPoints || [];
    },
    podsList: function() {
      if (this.browseNode && this.browseNode.id) {
        const GETPODSBYNODEID = this.$store.getters.keywords.POD.GETPODSBYNODEID;
        return this.$store.getters[GETPODSBYNODEID](this.browseNode.id);
      }

      return [];
    },
    accessPoints: function() {
      const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
      return this.$store.getters[ACCESSPOINTS];
    },
    getNodeType: function() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      const TYPES = this.$store.getters[NODETYPES];
      let self = this;
      return function(node) {
        switch (node.type) {
          case TYPES.FOLDER: return self.$t('routes.node.typeFolder');
          case TYPES.PROJECT: return self.$t('routes.node.typeProject');
          case TYPES.CLIENT: return self.$t('routes.node.typeClient');
          case TYPES.TENANT: return self.$t('routes.node.typeTenant');
          default: return self.$t('routes.node.typeUnknown');
        }
      }
    },
    getNodeIconName: function() {
      return function(node) {
        let iconName;
        switch (node.type) {
          case 2: iconName = "mdi-briefcase-clock-outline"; break;
          case 3: iconName = "mdi-account-tie"; break;
          case 4: iconName = "mdi-home-assistant"; break;
          default: iconName = "mdi-folder-outline"; break;
        }
        return iconName;
      };
    },
    okDisabled: function() {
      // return !this.browseNode || !(this.listGroup2Selected >= 0);
      return this.listGroup2Selected === null || this.listGroup2Selected === undefined;
    },
  }
}
</script>
