import Api from '../../api/Api'
import Vue from 'vue'

function fixTimeFormats(subtitle) {
  subtitle.start /= 1000;
  subtitle.end /= 1000;
  let duration = subtitle.end - subtitle.start;
  subtitle.duration = Number(duration.toFixed(1));

  if (subtitle.content.length > 0) {
    subtitle.charsPerSec = (subtitle.content.length / duration).toFixed(1);
  } else {
      subtitle.charsPerSec = '-';
  }

  return subtitle;
}

function sortSubtitles(a, b) {
  if (a.start < b.start) return -1;
  if (a.start > b.start) return 1;
  return 0;
}



export default {
  namespaced: true,
  state: {
    transcripts: [],
    dynamicVTTFiles: [],
  },
  getters: {
    getTranscripts: function(state) {
      return state.transcripts;
    },
    getDynamicVTTFiles: function(state) {
      return state.dynamicVTTFiles;
    }
  },
  mutations: {
    setTranscripts: function(state, transcripts) {
      for (let i = 0; i < transcripts.length; i++) {
        if (!Array.isArray(transcripts[i].sentences)) {
          transcripts[i].sentences = [];
        }

        transcripts[i].sentences.map(fixTimeFormats);
        transcripts[i].sentences.sort(sortSubtitles);
      }

      state.transcripts = transcripts;
    },
    createTranscript: function(state, transcript) {
      if (!Array.isArray(!transcript.sentences)) {
        transcript.sentences = [];
      }

      state.transcripts.push(transcript);
    },
    updateTranscript: function(state, transcript) {
      if (!Array.isArray(transcript.sentences)) {
        transcript.sentences = [];
      }

      for (let p = 0; p < transcript.sentences.length; p++) {
        Object.assign(transcript.sentences[p], fixTimeFormats(transcript.sentences[p]));
      }

      for (let i = 0; i < state.transcripts.length; i++) {
        if (state.transcripts[i].id === transcript.id) {
          Object.assign(state.transcripts[i], transcript);
          break;
        }
      }
    },
    deleteTranscript: function(state, id) {
      for (let i = 0; i < state.transcripts.length; i++) {
        if (state.transcripts[i].id == id) {
          state.transcripts.splice(i, 1);
        }
      }
    },
    createTranscriptLine: function(state, sentence) {
      for (let i = 0; i < state.transcripts.length; i++) {
        if (state.transcripts[i].id == sentence.transcriptid) {
          state.transcripts[i].sentences.push(fixTimeFormats(sentence));
          state.transcripts[i].sentences.sort(sortSubtitles);
          break;
        }
      }
    },
    updateTranscriptLine: function(state, sentence) {
      for (let p = 0; p < state.transcripts.length; p++) {
        if (state.transcripts[p].id !== sentence.transcriptid) {
          continue;
        }

        for (let i = 0; i < state.transcripts[p].sentences.length; i++) {
          if (state.transcripts[p].sentences[i].id == sentence.id) {
            Object.assign(state.transcripts[p].sentences[i], fixTimeFormats(sentence));
            Vue.delete(state.transcripts[p].sentences[i], 'mldata');
            break;
          }
        }
        state.transcripts[p].sentences.sort(sortSubtitles);
        break;
      }
    },
    deleteTranscriptLine: function(state, sentenceID) {
      for (let p = 0; p < state.transcripts.length; p++) {
        for (let i = 0; i < state.transcripts[p].sentences.length; i++) {
          if (state.transcripts[p].sentences[i].id == sentenceID) {
            state.transcripts[p].sentences.splice(i, 1);
            break;
          }
        }
      }
    },
    regenerateDynamicVTTFiles: function(state) {
      for (let i = 0; i < state.dynamicVTTFiles.length; i++) {
        URL.revokeObjectURL(state.dynamicVTTFiles[i].url);
      }
      state.dynamicVTTFiles = [];

      for (let i = 0; i < state.transcripts.length; i++) {
        let str =  "WEBVTT\n\n";
        let sentences = state.transcripts[i].sentences || [];
        for (let p = 0; p < sentences.length; p++) {
          str += Vue.filter("toTimeMilliseconds")(sentences[p].start) + "0";
          str += " --> ";
          str += Vue.filter("toTimeMilliseconds")(sentences[p].end) + "0\n";
          str += sentences[p].content + "\n\n";
        }

        let blob = new Blob([str], {type: 'text/vtt'});
        let vttFileStructure = {
          label: state.transcripts[i].name,
          language: "en",
          url: URL.createObjectURL(blob),
          isTranscript: true,
          transcriptId: state.transcripts[i].id,
        };
        state.dynamicVTTFiles.push(vttFileStructure);
      }
    }
  },
  actions: {
    refreshTranscriptLines: function(context, podID) {
      return Api.transcript.getTranscriptLines(podID)
      .then(function(result) {
        const transcripts = result.data.transcripts || [];
        context.commit("setTranscripts", transcripts);
        context.commit("regenerateDynamicVTTFiles");

        return transcripts;
      });
    },
    generateFile: function(context, data) {
      return Api.transcript.generateFile(data.podID, data.id, data.payload)
      .then(function(result) {
        console.log("GENERATE VTT result", result);
        return result;
      });
    },
    createTranscript: function(context, data) {
      return Api.transcript.createTranscript(data.podID, data.payload)
      .then(function(result) {
        context.commit("createTranscript", result.data);
        context.commit("regenerateDynamicVTTFiles");
        return result.data;
      });
    },
    updateTranscript: function(context, data) {
      return Api.transcript.updateTranscript(data.podID, data.id, data.payload)
      .then(function(result) {
        context.commit("updateTranscript", result.data);
        context.commit("regenerateDynamicVTTFiles");
        return result.data;
      });
    },
    deleteTranscript: function(context, data) {
      return Api.transcript.deleteTranscript(data.podID, data.id)
      .then(function(result) {
        context.commit("deleteTranscript", data.id);
        context.commit("regenerateDynamicVTTFiles");
        return result.data;
      });
    },
    createTranscriptLine: function(context, data) {
      return Api.transcript.createTranscriptLine(data.podID, data.transcriptID, data.payload)
      .then(function(result) {
        context.commit("createTranscriptLine", result.data);
        context.commit("regenerateDynamicVTTFiles");
        return result.data;
      });
    },
    updateTranscriptLine: function(context, data) {
      return Api.transcript.updateTranscriptLine(data.podID, data.transcriptID, data.payload)
      .then(function(result) {
        context.commit("updateTranscriptLine", result.data);
        context.commit("regenerateDynamicVTTFiles");
        return result.data;
      });
    },
    deleteTranscriptLine: function(context, data) {
      return Api.transcript.deleteTranscriptLine(data.podID, data.transcriptID, data.subtitleID)
      .then(function() {
        context.commit("deleteTranscriptLine", data.subtitleID);
        context.commit("regenerateDynamicVTTFiles");
      });
    }
  },
};
