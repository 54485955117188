import Api from '../../api/Api'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

import PodTranscriptStore from './PodTranscriptStore'
import PodCommentStore from './PodCommentStore'
import PodLabelStore  from './PodLabelStore'
import PodReviewerStore  from './PodReviewerStore'

import MimetypeHelper from '../../components/MimetypeHelper'



export default {
  namespaced: true,
  modules: {
    transcript: PodTranscriptStore,
    comment: PodCommentStore,
    label: PodLabelStore,
    reviewer: PodReviewerStore,
  },
  state: {
    viewPodMeta: [],
    viewPodActions: [],
    viewPodMoved: false,
    viewPodDeleted: false,
    viewPodArchived: false,
    activeToolbar: 0,     // active toolbar on the side menu, int

    viewPodId: null,
    allPods: [],
    selectedPodIds: [],
    selectedAssetIds: [],
  },
  getters: {
    getPodsByNodeId: function(state) {
      return function (nodeID) {
        return state.allPods.filter(x => x.nodeid === nodeID);
      }
    },
    getPodById: function(state) {
      return function (podID) {
        return state.allPods.find(x => x.id === podID);
      }
    },
    getPods: function(state, getters, rootState, rootGetters) {
      // return state.pods;
      const CURRENTNODE = rootGetters.keywords.NODE.CURRENTNODE;
      const currentNode = rootGetters[CURRENTNODE];

      if (currentNode && currentNode.id) {
        return state.allPods
        .filter(x => x.nodeid === currentNode.id)
        // .sort(function(a, b) {
        //   return a.name.localeCompare(b.name)
        // });
      }
      return [];
    },
    getPodVersions: function(state, getters) {
      return function(podId) {
        let relatedPods = [];
        let pod = getters.getPods.find(x => x.id == podId);

        if (!pod) {
          return relatedPods;
        }

        let parentPodId = pod.parentpodid ? pod.parentpodid : pod.id;

        let parentPod = getters.getPods.find(x => x.id == parentPodId);
        relatedPods.push(parentPod);

        let otherPods = getters.getPods.filter(x => x.parentpodid == parentPod.id);
        relatedPods = relatedPods.concat(otherPods);

        relatedPods.sort(function(a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });

        return relatedPods;
      }
    },
    getViewPod: function(state) {
      if (!state.viewPodId) {
        return null;
      }
      return state.allPods.find(x => x.id === state.viewPodId);
    },
    getSelectedPods: function(state) {
      return state.allPods.filter(x => state.selectedPodIds.indexOf(x.id) >= 0);
    },
    getSelectedPodIds: function (state) {
      return state.selectedPodIds;
    },
    getSelectedVideoPods: function(state, getters) {
      return getters.getSelectedPods.filter(item => MimetypeHelper.isMimetypeVideo(item));
    },
    getSelectedAudioPods: function(state, getters) {
      return getters.getSelectedPods.filter(item => MimetypeHelper.isMimetypeAudio(item));
    },
    getSelectedImagePods: function(state, getters) {
      return getters.getSelectedPods.filter(item => MimetypeHelper.isMimetypeImage(item));
    },
    getSelectedAssets: function(state, getters) {
      if (!getters.getViewPod || !getters.getViewPod.asset) {
        return [];
      }

      let selectedAssets = [];

      // we assume that the selected assets are part of the view pod
      for (const assetType in getters.getViewPod.asset) {
        let selectedItems = getters.getViewPod.asset[assetType].filter(x => state.selectedAssetIds.indexOf(x.id) >= 0);
        selectedAssets = selectedAssets.concat(selectedItems);
      }

      return selectedAssets;
    },
    getPodMeta: function(state) {
      return state.viewPodMeta;
    },
    getPodActions: function(state) {
      return state.viewPodActions;
    },
    isViewPodMoved: function(state) {
      return state.viewPodMoved;
    },
    isViewPodDeleted: function(state) {
      return state.viewPodDeleted;
    },
    isViewPodArchived: function(state) {
      return state.viewPodArchived;
    },
    isVideoPod: function(state, getters) {
      return getters.getViewPod && MimetypeHelper.isMimetypeVideo(getters.getViewPod);
    },
    isImagePod: function(state, getters) {
      return getters.getViewPod && MimetypeHelper.isMimetypeImage(getters.getViewPod);
    },
    isAudioPod: function(state, getters) {
      return getters.getViewPod && MimetypeHelper.isMimetypeAudio(getters.getViewPod);
    },
    isArchivePod: function(state, getters) {
      return getters.getViewPod && MimetypeHelper.isMimetypeArchive(getters.getViewPod);
    },
    // this will be used later on when we can handle unknown types
    // isUnknownPod: function(state) {
    //   return !this.isVideoPod() && !this.isImagePod();
    // }
    getSubtitleAssets: function(state, getters, rootState, rootGetters) {
      if (!getters.getViewPod || !getters.getViewPod.asset) {
        return [];
      }

      let assets = []
      const types = ['srt', 'vtt'];
      const ASSETSTATES = rootGetters.keywords.COMMON.ASSETSTATES;
      const STATECOMPLETE = rootGetters[ASSETSTATES].COMPLETE;

      for (let type of types) {
        if (!Array.isArray(getters.getViewPod.asset[type])) {
          continue;
        }

        const completedAssests = getters.getViewPod.asset[type].filter(x => x.state == STATECOMPLETE);
        assets = assets.concat(completedAssests);
      }

      assets.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });

      return assets;
    },
    getActiveToolbar: function(state) {
      return state.activeToolbar;
    },
    hasSelectedPods: function(state) {
      return state.selectedPodIds.length > 0;
    }
  },
  mutations: {
    addOrUpdatePods: function(state, pods) {
      if (!Array.isArray(pods)) {
        pods = [pods];
      }

      const allPodsMap = new Map(state.allPods.map((x) => [x.id, x]));

      for (let i = 0; i < pods.length; i++) {
        if (allPodsMap.has(pods[i].id)) {
          allPodsMap.set(pods[i].id, pods[i]);
          continue;
        }

        allPodsMap.set(pods[i].id, pods[i]);
      }

      if (allPodsMap.size > 0) {
        state.allPods = Array.from(allPodsMap.values());
      }
    },
    setViewPodId: function(state, podid) {
      state.viewPodId = podid;
      state.selectedPodIds = [];
      state.selectedAssetIds = [];
    },

    setViewPodArchived: function(state, isArchived) {
      state.viewPodArchived = isArchived;
    },
    setViewPodMoved: function(state, isMoved) {
      state.viewPodMoved = isMoved;
    },
    setViewPodDeleted: function(state, isDeleted) {
      state.viewPodDeleted = isDeleted;
    },

    setViewPodMeta: function(state, meta) {
      state.viewPodMeta = meta;
    },
    setViewPodActions: function(state, actions) {
      actions.sort(function(a, b) {
        let dateA = new Date(a.createdat);
        let dateB = new Date(b.createdat);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });

      state.viewPodActions = actions;
    },
    movePod: function(state, data) {
      if (state.viewPodId && state.viewPodId == data.pod.id) {
        state.viewPodMoved = true;
      }
    },
    archivePod: function(state, data) {
      if (state.viewPodId && state.viewPodId == data.pod.id) {
        state.viewPodArchived = true;
      }
    },
    deletePods: function(state, podIDs) {
      if (state.viewPodId && podIDs.indexOf(state.viewPodId) >= 0) {
        state.viewPodDeleted = true;
      }

      state.allPods = state.allPods.filter(x => podIDs.indexOf(x.id) < 0);
      state.selectedPodIds = state.selectedPodIds.filter(x => podIDs.indexOf(x) < 0);
    },
    addPodAsset: function(state, data) {
      console.log("CREATED POD ASSET", data);

      const assetTypes = {
        0: 'raw',
        1: 'thumbnail',
        2: 'web',
        3: 'seechtotext',
        4: 'audio',
        5: 'livepreview',
        6: 'vtt',
        7: 'srt',
        8: 'external',
        9: 'frame',
        10: 'subtitles',
      };

      const typeKey = assetTypes[data.type];

      for (let i = 0; i < state.allPods.length; i++) {
        if (state.allPods[i].id == data.podid) {
          if (!Array.isArray(state.allPods[i].asset[typeKey])) {
            Vue.set(state.allPods[i].asset, typeKey, []);
          }

          let sameAsset = state.allPods[i].asset[typeKey].find(x => x.id == data.id);
          if (!sameAsset) {
            state.allPods[i].asset[typeKey].unshift(data);
          }
        }
      }
    },
    updatePodAsset: function(state, data) {

      for (let i = 0; i < state.allPods.length; i++) {
        for (const property in state.allPods[i].asset) {

          for (let o = 0; o < state.allPods[i].asset[property].length; o++) {
            if (state.allPods[i].asset[property][o].id == data.id) {
              // Object.assign(state.allPods[i].asset[property][o], data);
              Vue.set(state.allPods[i].asset[property], o, data);

              // no breaks here...
            }
          }
        }
      }
    },
    deletePodAsset: function(state, payload) {
      const asset = payload.asset;

      for (let i = 0; i < state.allPods.length; i++) {
        for (const assetType in state.allPods[i].asset) {
          // creates a new collection of assets without the deleted asset.
          state.allPods[i].asset[assetType] = state.allPods[i].asset[assetType].filter((x) => x.id !== asset.id);

          // if the array type is empty, delete it.
          if (state.allPods[i].asset[assetType].length === 0) {
            Vue.delete(state.allPods[i].asset, assetType);
          }
        }
      }

      state.selectedAssetIds = state.selectedAssetIds.filter((x) => x !== asset.id);
    },
    deletePodAssets: function(state, deletedAssetIds) {
      for (let i = 0; i < state.allPods.length; i++) {
        for (const assetType in state.allPods[i].asset) {
          state.allPods[i].asset[assetType] =
          state.allPods[i].asset[assetType].filter(x => deletedAssetIds.indexOf(x.id) < 0);

          if (state.allPods[i].asset[assetType].length == 0) {
            Vue.delete(state.allPods[i].asset, assetType);
          }
        }
      }

      state.selectedAssetIds = state.selectedAssetIds.filter(x => deletedAssetIds.indexOf(x) < 0);
    },
    toggleSelectedPod: function(state, pod) {
      let idIndex = state.selectedPodIds.indexOf(pod.id);
      if (idIndex >= 0) {
        state.selectedPodIds.splice(idIndex, 1);
      } else {
        state.selectedPodIds.push(pod.id);
      }
    },
    deselectAllPods: function(state) {
      state.selectedPodIds = [];
    },
    toggleSelectedAsset: function(state, asset) {
      let idIndex = state.selectedAssetIds.indexOf(asset.id);
      if (idIndex >= 0) {
        state.selectedAssetIds.splice(idIndex, 1);
      } else {
        state.selectedAssetIds.push(asset.id);
      }
    },
    removeVersion: function(state, removeid) {
      for (let i = 0; i < state.allPods.length; i++) {
        if (state.allPods[i].id == removeid) {
          state.allPods[i].parentpodid = null;
          state.allPods[i].order = null;
          state.allPods[i].versiontext = null;
        }
      }
    },
    updateStateMessage: function (state, payload) {
      const index = state.allPods.findIndex(x => x.id === payload.podid);
      if (index >= 0) {
        // state.pods[index].statemessage = payload.message;
        Vue.set(state.allPods[index], 'statemessage', payload.message);
      }
    },
    addLaunchedService: function(state, payload) {
      const index = state.allPods.findIndex(x => x.id === payload.podid);
      if (index >= 0) {
        if (!state.allPods[index].launchedServices) {
          Vue.set(state.allPods[index], 'launchedServices', []);
        }

        const messageString = JSON.stringify(payload.message);
        const isExisting = state.allPods[index].launchedServices.find(function(x) {
          return JSON.stringify(x) === messageString;
        });

        if (!isExisting) {
          state.allPods[index].launchedServices.push(payload.message);
        }
      }

    },
    updateLaunchedServices: function(state, payload) {
      const index = state.allPods.findIndex(x => x.id === payload.podid);
      if (index >= 0) {
        state.allPods[index].launchedServices = payload.launchedServices;
      }
    },
    removeLaunchedServices: function(state, payload) {
      const index = state.allPods.findIndex(x => x.id === payload.podid);
      if (index >= 0 && state.allPods[index]) {
        if (!state.allPods[index].launchedServices) {
          state.allPods[index].launchedServices = [];
        }

        state.allPods[index].launchedServices = state.allPods[index].launchedServices.filter(x => x.code !== payload.code);
      }
    },
    setActiveToolbar(state, toolbarID) {
      state.activeToolbar = toolbarID;
    },
    selectFilesInFolder(state, payload) {
      const { nodeId } = payload;

      if (nodeId) {
        const selectedPodIds = [];

        for (let i = 0; i < state.allPods.length; i++) {
          if (state.allPods[i].nodeid === nodeId) {
            selectedPodIds.push(state.allPods[i].id);
          }
        }

        state.selectedPodIds = selectedPodIds;
      }
    }
  },
  actions: {
    updateLaunchedServices: function(context, payload) {
      if (window.isNodeTransitioning) {
        return;
      }

      context.commit('updateLaunchedServices', payload);

      if (payload.queue === 'end') {
        setTimeout(() => {
          context.commit('removeLaunchedServices', payload);
        }, 3000);
      }
    },
    addLaunchedService: function(context, payload) {
      if (window.isNodeTransitioning) {
        return;
      }

      context.commit('addLaunchedService', payload);
    },
    updateStateMessage: function(context, payload) {
      if (window.isNodeTransitioning) {
        return;
      }

      context.commit('updateStateMessage', payload);

      if (payload.queue === 'end') {
        payload.message = null;

        setTimeout(() => {
          context.commit('updateStateMessage', payload);
        }, 3000);
      }
    },
    refreshPods: function(context, nodeID) {

      // When we have directory tree set entry point as header
      // Api.setEntryPoint("qqqqqqqqqqqqqq");
      // but that should be from the DirectoryStore...

      context.commit('setViewPodId', null);

      return Api.pod.getPodsByNodeID(nodeID)
      .then(function(result) {
        // console.log("API CALL");
        // console.log(result);
        context.commit('addOrUpdatePods', result.data);
      })
      .catch(function(error) {
        console.log("Error getting pods by node ID:")
        console.log(error);
      });
    },
    fetchPodById(context, podId) {
      return Api.pod.getPodByID(podId)
      .then(function(response) {
        const pod = response.data;
        context.commit('addOrUpdatePods', pod);

        return pod;
      });
    },
    fetchPodsByNodeId: function(context, nodeID) {
      return Api.pod.getPodsByNodeID(nodeID)
      .then(function(result) {
        context.commit('addOrUpdatePods', result.data);
        return result.data;
      });
    },
    resetPods: function(context) {
      context.commit('setViewPodMeta', []);
      context.commit('setViewPodActions', []);
      context.commit('setActiveToolbar', 0);

      context.commit('setViewPodId', null);

      const RESETCOMMENTS = context.rootGetters.keywords.POD.COMMENT.RESETCOMMENTS;
      context.dispatch(RESETCOMMENTS, null, {root: true});
    },
    setViewPodMoved: function(context, isMoved) {
      context.commit('setViewPodMoved', isMoved);
    },
    setViewPodDeleted: function(context, isDeleted) {
      context.commit('setViewPodDeleted', isDeleted);
    },
    setViewPodArchived: function(context, isArchived) {
      context.commit('setViewPodArchived', isArchived);
    },
    refreshPodMeta: function(context, podID) {
      return Api.pod.getPodMeta(podID)
      .then(function(response) {
        context.commit('setViewPodMeta', response.data);
        return response.data;
      });
    },
    viewPod: function(context, podID) {
      let pod;
      return Api.pod.getPodByID(podID)
      .then(function(response) {
        pod = response.data;

        context.commit('setViewPodId', pod.id);
        context.commit('addOrUpdatePods', pod);

        const REFRESHPODMETA = context.rootGetters.keywords.POD.REFRESHPODMETA;

        return Promise.all([
          context.dispatch(REFRESHPODMETA, podID, {root: true}),
          Api.pod.getPodsByNodeID(pod.nodeid),
        ]);
      })
      .then(function(response) {
        context.commit('toggleSelectedPod', pod);
        context.commit('addOrUpdatePods', response[1].data);

        return pod;
      })
      .catch(function(error) {
        console.log("Error getting pod by id.");
        console.log(error);
      });
    },
    createPod: function(context, podData) {
      return Api.pod.createPod(podData)
      .then(function(result) {
        const pod = result.data;
        context.commit('addOrUpdatePods', pod);

        return pod;
      })
      .catch(function(error) {
        console.log("Error creating pod:");
        console.log(error);
        return Promise.reject(error);
      });
    },
    updatePod: function(context, podData) {
      return Api.pod.updatePod(podData)
      .then(function(result) {
        context.commit('addOrUpdatePods', result.data);
        context.dispatch('updateOtherStoresPod', result.data);
        return result.data;
      })
      // .catch(function(error) {
      //   console.log("Error updating pod:");
      //   console.log(error);
      // });
    },
    updatePodNote: function(context, payload) {
      return Api.pod.updatePodNote(payload)
        .then(function(result) {
          context.commit('addOrUpdatePods', result.data);
          context.dispatch('updateOtherStoresPod', result.data);
          return result.data;
        })
    },
    updateOtherStoresPod(context, pod) {
      const UPDATESEARCHRESULTPOD = context.rootGetters.keywords.SEARCH.UPDATESEARCHRESULTPOD;
      context.dispatch(UPDATESEARCHRESULTPOD, pod, { root: true });

      const UPDATERECYCLEBINPOD = context.rootGetters.keywords.RECYCLEBIN.UPDATERECYCLEBINPOD;
      context.dispatch(UPDATERECYCLEBINPOD, pod, { root: true });
    },
    transcodeFile: function(context, data) {
      return Api.pod.transcodeFile(data)
      .then(function(result) {
        return result.data;
      });
    },
    extractArchive: function(context, data) {
      return Api.pod.extractArchive(data)
      .then(function(result) {
        return result.data;
      });
    },
    generateAudio: function(context, data) {
      return Api.pod.generateAudio(data)
      .then(function(result) {
        return result.data;
      });
    },
    deletePods: async function(context, podIDs) {
      let promises = podIDs.map(x => Api.pod.deletePod(x));
      await Promise.all(promises);

      // console.log("Result of deleted pods", results);
      context.commit("deletePods", podIDs);
    },
    createPodAsset: function(context, data) {
      return Api.pod.createPodAsset(data)
      .then(function(result) {
        context.commit('addPodAsset', result.data);
      })
      .catch(function(error) {
        console.log("Error creating podAsset:");
        console.log(error);
        return Promise.reject(error);
      });
    },
    updatePodAsset: async function(context, data) {
      let result = await Api.pod.updatePodAsset(data);
      context.commit('updatePodAsset', result.data);

      const REFRESHSUBTITLES = context.rootGetters.keywords.PLAYER.REFRESHSUBTITLES;
      await context.dispatch(REFRESHSUBTITLES, context.getters.getViewPod, { root: true });

      return result.data;
    },
    deletePodAssets: async function(context, data) {
      let promises = data.map(x => Api.pod.deletePodAsset(x.podid, x.id));
      let results = await Promise.all(promises);
      // results[i].data is a pod
      results;

      const assetIds = data.map(x => x.id);
      context.commit("deletePodAssets", assetIds);

      // remove srt/vtt active subtitle
      const REFRESHSUBTITLES = context.rootGetters.keywords.PLAYER.REFRESHSUBTITLES;
      await context.dispatch(REFRESHSUBTITLES, context.getters.getViewPod, { root: true });
    },
    createSubtitleAsset: async function(context, data) {
      let result = await Api.pod.createSubtitleAsset(data);
      context.commit('addPodAsset', result.data);

      const REFRESHSUBTITLES = context.rootGetters.keywords.PLAYER.REFRESHSUBTITLES;
      await context.dispatch(REFRESHSUBTITLES, context.getters.getViewPod, { root: true });

      return result.data;
    },
    updateSubtitleAsset: async function(context, data) {
      try {
        let result = await Api.pod.updateSubtitleAsset(data);
        context.commit('updatePodAsset', result.data);

        const REFRESHSUBTITLES = context.rootGetters.keywords.PLAYER.REFRESHSUBTITLES;
        await context.dispatch(REFRESHSUBTITLES, context.getters.getViewPod, { root: true });

        return result.data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    setAssetLanguage: function(context, data) {
      return Api.pod.setAssetLanguage(data.podID, data.assetID, data.languageID)
      .then(function(response) {
        // console.log("Updated asset language", response);
        context.commit('updatePodAsset', response.data);
      });
    },
    toggleSelectedPod: function(context, pod) {
      context.commit('toggleSelectedPod', pod);
    },
    deselectAllPods: function(context) {
      context.commit('deselectAllPods');
    },
    refreshPodActions: async function(context, podId) {
      let response = await Api.pod.getPodActions(podId)
      context.commit('setViewPodActions', response.data);
      return response.data;
    },
    toggleSelectedAsset: function(context, asset) {
      context.commit('toggleSelectedAsset', asset);
    },
    promoteAssetToPod: function(context, asset) {
      const USER = context.rootGetters.keywords.AUTH.GETUSER;
      const USERUUID = context.rootGetters[USER].userId;

      const payload = {
        uuid: uuidv4(),
        useruuid: USERUUID,
      };

      const data = {
        payload,
        assetID: asset.id,
        podID: asset.podid,
      }

      return Api.pod.promoteAssetToPod(data)
      .then(function(result) {
        return result.data;
      });

    },
    moveManyPods: function(context, payload) {
      return Api.pod.moveManyPods(payload)
      .then(function(response) {
        response;   // not used
        // console.log("Move pods response", response);
      })
    },
    copyManyPods: function(context, payload) {
      Api.pod.copyManyPods(payload);
    },
    convertSubtitle: function(context, asset) {
      const payload = {};

      const data = {
        payload,
        assetID: asset.id,
        podID: asset.podid,
      }

      return Api.pod.convertSubtitle(data)
      .then(function(result) {
        return result.data;
      });
    },
    addVersion: function(context, payload) {
      return Api.pod.addVersion(payload)
      .then(function(response) {
        context.commit('addOrUpdatePods', response.data);

        for (let i = 0; i < response.data.length; i++) {
          context.dispatch('updateOtherStoresPod', response.data[i]);
        }
      });
    },
    reorderVersions: function(context, payload) {
      return Api.pod.reorderVersions(payload)
      .then(function(response) {
        context.commit('addOrUpdatePods', response.data);

        for (let i = 0; i < response.data.length; i++) {
          context.dispatch('updateOtherStoresPod', response.data[i]);
        }

        if (payload.removeid) {
          context.commit('removeVersion', payload.removeid);
        }
      });
    },
    addOrUpdatePods: function(context, pods) {
      context.commit('addOrUpdatePods', pods);
    },
    addOrUpdatePod: function(context, pod) {
      context.commit('addOrUpdatePods', pod);
      context.dispatch('updateOtherStoresPod', pod);

      // TODO: Check if isNodeTransitioning is still needed
      if (window.isNodeTransitioning) {
        return;
      }

      if (context.getters.getViewPod && context.getters.getViewPod.id === pod.id) {

        if (context.getters.getViewPod.archived == 1) {
          context.commit('archivePod', { pod, });
        }

        const REFRESHSUBTITLES = context.rootGetters.keywords.PLAYER.REFRESHSUBTITLES;
        context.dispatch(REFRESHSUBTITLES, context.getters.getViewPod, { root: true });

        const REFRESHTRANSCRIPTLINES = context.rootGetters.keywords.POD.TRANSCRIPT.REFRESHTRANSCRIPTLINES;
        context.dispatch(REFRESHTRANSCRIPTLINES, pod.id, {root:true});

        const REFRESHPODMETA = context.rootGetters.keywords.POD.REFRESHPODMETA;
        context.dispatch(REFRESHPODMETA, pod.id, {root: true});
      }
    },
    updateOrIgnorePod: function(context, pod) {
      context.commit('addOrUpdatePods', pod);
      context.dispatch('updateOtherStoresPod', pod);
    },
    podMovedNotification: function(context, pod) {
      const CURRENTNODE = context.rootGetters.keywords.NODE.CURRENTNODE;
      const currentNode = context.rootGetters[CURRENTNODE];
      const data = {
        pod,
        currentNode,
      }
      context.commit('addOrUpdatePods', pod);
      context.commit('movePod', data);
    },

    /**
     * This function is called when a pod is copied. It will update the pod store with the new pod.
     * @todo This function could be removed if we do not use it in the near future.
     * @param context
     * @param payload
     */
    podCopyJobNotification: function(context, payload) {
      const fiveHundredMegaBytes = 500_000_000;
      const oneGigaByte = 1_000_000_000;

      let timeout = null;
      if (payload.filesSize < fiveHundredMegaBytes) {
        timeout = 1000;
      } else if (payload.filesSize < oneGigaByte) {
        timeout = 3000;
      }

      if (timeout) {
        setTimeout(() => {
          Api.pod.getPodsByNodeID(payload.nodeId)
            .then((result) => {
              context.commit('addOrUpdatePods', result.data);
            });
        }, timeout);
      } else{
        const CREATESNACKBAR = context.rootGetters.keywords.MAIN.CREATESNACKBAR;
        context.commit(CREATESNACKBAR, {
          message: 'Your files will be processed as soon as possible.',
          color: 'success',
        }, { root: true });
      }
    },

    podCopiedNotification: function(context, payload) {
      context.commit('addOrUpdatePods', payload.pod);
    },
    podDeletedNotification: function(context, ids) {
      const podIds = Array.isArray(ids) ? ids : [ids];
      context.commit('deletePods', podIds);

      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const getRootNodeId = context.rootGetters[ROOTNODEID];

      if (getRootNodeId) {
        const payload = {
          rootNodeId: getRootNodeId,
          silent: true,
        };

        const REFRESHNODESLIST = context.rootGetters.keywords.RECYCLEBIN.REFRESHNODESLIST;
        context.dispatch(REFRESHNODESLIST, payload, { root: true });
      }
    },
    removePodsByNodeDeleted: function(context, node) {
      const GETPODSBYNODEID = context.rootGetters.keywords.POD.GETPODSBYNODEID;
      const nodeIdsForDeletion = node.childIds ? [node.id, ...node.childIds] : [node.id];
      let podIds = [];

      for (let i = 0; i < nodeIdsForDeletion.length; i++) {
        const pods = context.rootGetters[GETPODSBYNODEID](nodeIdsForDeletion[i]);
        podIds = podIds.concat(pods.map(x => x.id));
      }

      context.dispatch('podDeletedNotification', podIds);
    },
    setActiveToolbar(context, toolbarID) {
      context.commit('setActiveToolbar', toolbarID)
    },
    updateTeamOnly: function(context, payload) {
      return Api.pod.updateTeamOnly(payload)
        .then(function(result) {
          context.commit('addOrUpdatePods', result.data);
          context.dispatch('updateOtherStoresPod', result.data);
          return result.data;
        })
    },
    renameFile: function(context, payload) {
      return Api.pod.renameFile(payload)
        .then(function(result) {
          context.commit('addOrUpdatePods', result.data);
          context.dispatch('updateOtherStoresPod', result.data);
          return result.data;
        })
    },
  },
}
