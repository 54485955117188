<template>
  <v-menu
  v-model="showMenu"
  :disabled="isCurrentNodeArchived"
  offset-y
  >

  <template v-slot:activator="{on, attrs }">
    <v-btn
      v-bind="attrs"
      v-on="on"
      :disabled="isCurrentNodeArchived"
      color="primary"
      elevation=0
      class="ml-2"
      data-cy="nodeSideMenu-createNodes-button"
    >
      <v-icon style="">mdi-plus</v-icon>
    </v-btn>
  </template>


      <!-- <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">

            <v-btn
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="primary"
              elevation=0
              class="ml-2"
            >
              <v-icon style="">mdi-plus</v-icon>
            </v-btn>


          </template>
          <span>Im A ToolTip</span>
        </v-tooltip>
      </template> -->


      <v-list data-cy="nodeSideMenu-createNodes-menu">
        <CreateNodeDialog v-if="showingCreateFolder" :nodeType="nodeTypes.FOLDER" :parentNode="currentNode" >
          <v-list-item @click="stub" data-cy="nodeSideMenu-createNodes-createFolder">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-folder-plus</v-icon>
                {{$t('menus.createNode.createFolder')}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </CreateNodeDialog>

        <CreateNodeDialog v-if="showingCreateProject" :nodeType="nodeTypes.PROJECT" :parentNode="currentNode">
          <v-list-item @click="stub" data-cy="nodeSideMenu-createNodes-createProject">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon dense left>mdi-briefcase-plus</v-icon>
                {{$t('menus.createNode.createProject')}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </CreateNodeDialog>

        <CreateNodeDialog v-if="showingCreateClient" :nodeType="nodeTypes.CLIENT" :parentNode="currentNode">
          <v-list-item @click="stub" data-cy="nodeSideMenu-createNodes-createClient">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon dense left>mdi-account-plus</v-icon>
                  {{$t('menus.createNode.createClient')}}
                </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </CreateNodeDialog>
    </v-list>
  </v-menu>
</template>

<script>
import CreateNodeDialog from './dialogs/CreateNodeDialog.vue'

export default {
  name: "CreateNodesMenu",
  components: {
    CreateNodeDialog,
  },
  data() {
    return {
      // dialog: false,
      showMenu: false,
    };
  },

  methods: {
    stub() {
      this.showMenu = false;
    }
  },
  computed: {
    currentNode() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE]
    },
    isCurrentNodeArchived() {
      return Boolean(this.currentNode && this.currentNode.archived);
    },
    nodeTypes() {
      const NODETYPES = this.$store.getters.keywords.COMMON.NODETYPES;
      return this.$store.getters[NODETYPES];
    },
    showingCreateFolder() {
      return true;
    },
    showingCreateProject() {
      // return  this.currentNode &&
      //         (this.currentNode.type == this.nodeTypes.CLIENT ||
      //         this.currentNode.type == this.nodeTypes.TENANT);

      return true;
    },
    showingCreateClient() {
      return  this.currentNode &&
              this.currentNode.type == this.nodeTypes.TENANT;
    },
  }
}
</script>

<style>
</style>
