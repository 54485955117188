import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    embedLinks: [],
    embedMediaInfo: {},
  },
  getters: {
    getEmbedLinks: function(state) {
      return state.embedLinks;
    },
    getEmbedlinkInfo: function(state) {
      return state.embedMediaInfo;
    },
  },
  mutations: {
    setEmbedLinks: function(state, links) {
      state.embedLinks = links;
    },
    // External view of embedded pod
    setEmbedlinkInfo: function(state, info) {
      state.embedMediaInfo = info;
    },
  },
  actions: {
    refreshEmbedLinks: function(context, podID) {
      return Api.embedmedia.getEmbedLinks(podID)
      .then(function(response) {
        context.commit('setEmbedLinks', response.data);
      })
    },

    createEmbedLink: function(context, payload) {
      return Api.embedmedia.createEmbedLink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    deleteEmbedLink: function (context, payload) {
      const { linkId, podId } = payload;
      return Api.embedmedia.deleteEmbedLink(podId, linkId)
      .then(function(response) {
        return response.data;
      });
    },

    // External call
    refreshEmbedlinkInfo: function(context, code) {
      //context.commit('setAccesslinkInfo', {});
      return Api.embedmedia.getEmbedlinkInfo(code)
      .then(function(response) {
        context.commit('setEmbedlinkInfo', response.data);
        return response.data;
      })
    },
  }
}
