<template>
  <v-navigation-drawer
    app
    clipped
    fixed
    left
    bottom
    mobile-breakpoint="600"
    v-model="drawer"
    class="node-sidemenu"
    width="290"
    style=""
    ref="drawerElement"
    data-cy="nodeSideMenu-menu"
  >

    <div class="d-flex flex-column justify-space-between" style="height:100%">
      <div>
        <div class="d-flex">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Find directory"
            @keydown.enter="findNodes()"
            @click:prepend-inner="findNodes()"
            @click:clear="clearSearchResults()"
            @input="searchInputChanged()"
            hide-details
            solo flat
            clearable
          >
          </v-text-field>

          <CreateNodesMenu v-if="$canControlNodeAndPod()">
          </CreateNodesMenu>
        </div>

        <div class="mt-4">
          <AccesspointSwitch>
          </AccesspointSwitch>
        </div>

        <div class="mt-4">
          <NodeExpansionPanel>
          </NodeExpansionPanel>
        </div>
      </div>


      <div v-if="showingUpgradeButton" class="d-flex flex-column text-center">
        <span v-if="getSubscriptionDaysLeft">
          {{ $tc('layouts.main.subscriptionDaysLeft', getSubscriptionDaysLeft) }}
        </span>
        <v-btn color="primary" elevation=0 to="/console/subscriptions">
          <v-icon>mdi-crown-circle</v-icon>
          {{$t('layouts.main.upgradeSubscription')}}
        </v-btn>
      </div>
    </div>

  </v-navigation-drawer>
</template>

<script>
import AccesspointSwitch from '../../../components/AccesspointSwitch.vue'
import CreateNodesMenu from './components/CreateNodesMenu.vue'
import NodeExpansionPanel from './NodeExpansionPanel.vue'

export default {
  name: "NodeSidemenu",
  components: {
    AccesspointSwitch,
    CreateNodesMenu,
    NodeExpansionPanel,
  },
  data() {
    return {
      drawer: null,

      searchText: '',
    };
  },
  mounted() {
    // const drawerElement = this.$refs.drawerElement.$el;
    // const contentElement = drawerElement.querySelector('.v-navigation-drawer__content');
    // if (contentElement) {
    //   contentElement.classList.add('scrollable');
    // }
  },
  watch: {
    getSidemenuToggle() {
      this.drawer = !this.drawer;
    }
  },
  methods: {
    findNodes() {
      if (this.searchText.length < 3) {
        return;
      }

      if (!this.getCurrentNode || !this.getCurrentNode.id) {
        return;
      }

      const payload = {
        allWords: 1,
        includeAssets: 0,
        includeSubfolders: 1,
        nodeid: this.getCurrentNode.id,
        searchStr: this.searchText,
        searchZone: "folders",
        wholeWords: 0,
      };

      const SEARCHINFOLDERS = this.$store.getters.keywords.SEARCH.SEARCHINFOLDERS;
      this.$store.dispatch(SEARCHINFOLDERS, payload);
    },
    clearSearchResults() {
      // console.log("Clear search results")
      const RESETFOLDERSEARCHRESULTS = this.$store.getters.keywords.SEARCH.RESETFOLDERSEARCHRESULTS;
      this.$store.dispatch(RESETFOLDERSEARCHRESULTS);
    },
    searchInputChanged() {
      if (!this.searchText) {
        this.clearSearchResults();
      }
    },
  },
  computed: {
    getSidemenuToggle() {
      const GETSIDEMENUTOGGLE = this.$store.getters.keywords.MAIN.GETSIDEMENUTOGGLE;
      return this.$store.getters[GETSIDEMENUTOGGLE];
    },
    showingUpgradeButton() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];

      return  this.$canControlTenantConfig() &&
              !this.$isFCPExtension() &&
              !this.$isAdobeExtension() &&
              activeTenant &&
              activeTenant.subscription &&
              activeTenant.subscription.subscriptionType &&
              activeTenant.subscription.subscriptionType === 'freetrial';
    },
    getSubscriptionDaysLeft() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];

      if (activeTenant?.subscription?.subscriptionEnd) {
        return activeTenant?.subscription?.subscriptionEnd?.days || 0;
      }

      return 0;
    },
    getCurrentNode() {
      const GETCURRENTNODE = this.$store.getters.keywords.NODE.GETCURRENTNODE;
      return this.$store.getters[GETCURRENTNODE];
    },
  }
}
</script>

<style>

.node-sidemenu {
  margin-top: 80px;
  margin-left: 4px;
  border-radius: 7px;
  /* padding-top: 80px; */
  max-height: calc(100% - 84px) !important;
  padding: 8px;
}

.node-sidemenu .v-navigation-drawer__content {
  overflow-x: auto;
}

@media only screen and (max-width: 600px) {
  .node-sidemenu {
    margin-top: 80px;
    margin-left: 0px;
    border-radius: 0px;
    /* padding-top: 84px; */
    max-height: calc(100% - 84px) !important;
  }
}

.theme--dark.node-sidemenu {
  background-color: #232b36 !important;
}

.theme--light.node-sidemenu {
  background-color: #FFFFFF !important
}



</style>
