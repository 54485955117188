import API from '../AxiosInstance';

export default {
  getNotifications: function(payload) {
    const body = {
        // headers, body parameters, query parameters
    };

    return API.get(`tenants/${payload.accessPointId}/notifications`, body);
  },

  getNotificationTopics: function() {
    const body = {};
    return API.get('me/notifications/info', body);
  }
}
