<template>
  <v-card outlined class="pa-4">
    <!-- Label input field and add button -->
    <div class="d-flex align-center mb-4">
      <v-text-field
          v-model="newLabel"
          @keyup.enter="addLabel"
          class="mr-4"
          solo flat
          :error-messages="errorMessage"
          hide-details="auto"
      ></v-text-field>

      <div style="align-self: flex-start;">
        <v-btn color="secondaryButtons" @click="addLabel" elevation="0">
          <v-icon >mdi-plus-circle</v-icon>
          {{$t('menus.uploadFiles.addLabel')}}
        </v-btn>
      </div>

    </div>

    <v-container>
      <v-row>
        <!-- Treeview column -->
        <v-col cols="12" md="6">
          <v-treeview
              v-model="selectedLabels"
              v-if="hasLabelCategories"
              :items="categories"
              :label="$t('routes.pod.menuEditLabels.selectCategory')"
              selection-type="leaf"
              selectable return-object open-all
          ></v-treeview>
        </v-col>

        <!-- Chips container column -->
        <v-col cols="12" md="6">
          <!-- Displaying labels as chips -->
          <div class="d-flex flex-wrap">
            <v-chip
                v-for="(label, index) in labels"
                :key="index"
                close
                @click:close="removeLabel(label)"
                class="ma-2"
                label
            >
              {{ label }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "UploadDialogCustomLabels",
  data() {
    return {
      newLabel: '',
      labels: [],
      categories: [],
      selectedLabels: [],
      errorMessage: '',
      flatItems: [],
    };
  },
  watch: {
    selectedLabels: function (newItems, oldItems) {
      for (const selectedItem of newItems) {
        if (selectedItem.isLabel) {
          if (!this.labels.includes(selectedItem.name)) {
            this.labels.push(selectedItem.name);
          }
        }
      }

      const itemsToRemove = [];
      for (const oldItem of oldItems) {
        if (oldItem.isLabel && !newItems.includes(oldItem)) {
          itemsToRemove.push(oldItem.name);
        }
      }

      if (itemsToRemove.length > 0) {
        this.labels = this.labels.filter(label => !itemsToRemove.includes(label));
      }

    },
    labels: function () {
      this.$emit('labelsChanged', this.labels.map((label) => label));
    }
  },
  mounted() {
    this.resetState();

    if (this.categories.length === 0) {
      this.loadCategoriesWithLabels();
    }
  },
  methods: {
    addLabel() {
      this.errorMessage = '';

      const newLabel = this.newLabel.trim();
      if (newLabel !== '') {
        if (this.labels.includes(newLabel)) {
          this.errorMessage = this.$t('menus.uploadFiles.labelAlreadyExists');
        } else {
          this.labels.push(this.newLabel);
        }
      }

      const hasCustomLabel = this.flatItems.some((customLabel) => customLabel.name === newLabel);
      if (hasCustomLabel) {
        for (const customLabel of this.flatItems) {
          if (customLabel.name === newLabel) {
            this.selectedLabels.push(customLabel);
          }
        }
      }

      this.newLabel = '';
    },
    removeLabel(label) {
      const hasSelectedLabel = this.selectedLabels.some((selectedLabel) => selectedLabel.name === label);
      if (hasSelectedLabel) {
        const newSelectedLabels  = [];
        if (this.selectedLabels.length > 0) {
          for (const selectedItem of this.selectedLabels) {
            if (selectedItem.isLabel && selectedItem.name !== label) {
              newSelectedLabels.push(selectedItem);
            }
          }

          this.selectedLabels = newSelectedLabels;
        }
      }

      const newLabels = [];
      for (const currentLabel of this.labels) {
        if (currentLabel !== label) {
          newLabels.push(currentLabel);
        }
      }

      this.labels = newLabels;
    },
    loadCategoriesWithLabels: function() {
      const payload = {
        accessPointId: this.accessPointId,
        includeLabels: true,
      };

      const GETCATEGORIES = this.$store.getters.keywords.LABEL.GETCATEGORIES;
      this.$store.dispatch(GETCATEGORIES, payload)
        .then((categories) => {
          this.categories = categories;

          let flatItems = [];

          let stack = this.categories.slice();
          while (stack.length) {
            let node = stack.shift();
            flatItems.push(node);
            stack = stack.concat(node.children || []);
          }

          this.flatItems = flatItems;
        });
    },
    resetState() {
      this.labels = [];
      this.selectedLabels = [];
      this.errorMessage = '';
    }
  },
  computed: {
    accessPointId: function() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    hasLabelCategories() {
      return this.categories && this.categories.length > 0;
    },
  },
}
</script>
