import Vue from 'vue'

// input is a number
Vue.filter('toKilo', function(value, precision) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw "toKilo parameter must be a number.";
  }

  if (value >= 1000) {
    return (value / 1000).toFixed(precision) + "K";
  }

  return value;
});
