<template>
  <div>
    <v-form ref="form" data-cy="podMenu-subtitles-form" class="no-selection">
      <v-card>
        <v-card-title class="primary d-flex justify-space-between">
          <div class="pod-subtitlesform-inout d-flex justify-space-between">
            <v-text-field
                v-model="inTime"
                v-bind:rules="timeInputRules"
                v-bind:label="$t('routes.pod.menuSubtitles.inTime')"
                v-on:change="inoutTimeUpdated"
                hide-details="auto"
                block dense
                solo flat
                data-cy="podMenu-subtitles-inTime">
            </v-text-field>

            <v-text-field
                v-model="duration"
                v-bind:label="$t('routes.pod.menuSubtitles.duration')"
                v-bind:rules="durationRules"
                v-on:change="durationUpdated"
                hide-details="auto"
                class="mx-10"
                block dense
                solo flat
                data-cy="podMenu-subtitles-duration">
            </v-text-field>

            <v-text-field
                v-model="outTime"
                v-bind:rules="timeInputRules.concat(outTimeComparison)"
                v-bind:label="$t('routes.pod.menuSubtitles.outTime')"
                v-on:change="inoutTimeUpdated"
                hide-details="auto"
                block dense
                solo flat
                data-cy="podMenu-subtitles-outTime">
            </v-text-field>
          </div>
        </v-card-title>

        <div class="subtitles-form-body">
        <v-card-text>
          <v-textarea
            v-model="subtitleLine"
            class="media-list-notes-textarea mt-2"
            v-bind:error-messages="subtitleLineError"
            v-bind:placeholder="$t('routes.pod.menuSubtitles.subtitleLine')"
            rows="3"
            hide-details="auto"
            solo flat
            required
            data-cy="podMenu-subtitles-addSubtitleLine"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between">
          <div v-show="subtitleLine.length > 80">
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <span style="color: #ff8282;">{{subtitleLine.length}}</span>
                  {{$t('routes.pod.menuSubtitles.characterShort')}}
                </span>
              </template>

              <span>{{$t('routes.pod.menuSubtitles.keepLengthShort')}}</span>
            </v-tooltip>
          </div>
          <div v-show="subtitleLine.length <= 80">
            {{subtitleLine.length}} {{$t('routes.pod.menuSubtitles.characterShort')}}
          </div>

          <div v-show="characterPerSecond > 24">
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <span style="color: #ff8282;">{{characterPerSecond}}</span>
                  {{$t('routes.pod.menuSubtitles.charsPerSec')}}
                </span>
              </template>

              <span>{{$t('routes.pod.menuSubtitles.keepCSPShort')}}</span>
            </v-tooltip>
          </div>
          <div v-show="characterPerSecond <= 24">
            {{characterPerSecond}} {{$t('routes.pod.menuSubtitles.charsPerSec')}}
          </div>

          <div>
            <v-btn color="primary"
                   v-bind:title="this?.subtitleForEditing?.id ? $t('common.save') : $t('common.add')"
                   v-on:click.prevent="saveLine"
                   elevation=0
                   data-cy="podMenu-subtitles-save-btn">
              <v-icon v-if="this?.subtitleForEditing?.id">mdi-content-save</v-icon>
              <v-icon v-else>mdi-plus-thick</v-icon>
            </v-btn>

            <v-btn outlined class="ml-2" v-bind:title="$t('common.cancel')" v-on:click.prevent="hideForm" data-cy="podMenu-subtitles-cancel-btn" elevation="0">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "PodSubtitlesForm",
  props: {
    subtitleForEditing: {
      type: Object,
    },
    pod: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    let self = this;
    return {
      copiedCues: [],

      inTime: "00:00.00",
      outTime: "00:01.00",
      duration: '1',
      durationRules: [
        v => v >= 0 || this.$t('routes.pod.menuSubtitles.timeIsRequired'),
        v => /^\d{1,}(\.\d{1})?$/g.test(v) || this.$t('routes.pod.menuSubtitles.invalidFormat'),
      ],

      subtitleLine: "",
      subtitleLineError: "",

      timeInputRules: [
        v => !!v || this.$t('routes.pod.menuSubtitles.timeIsRequired'),
        v => /^\d{2,}:\d{2}\.\d{2}$/g.test(v) || this.$t('routes.pod.menuSubtitles.invalidFormat'),
        function(v) {
            let seconds = (v || "").split(".")[0].split(":")[1];
            if (parseInt(seconds) >= 60) return self.$t('routes.pod.menuSubtitles.invalidSeconds');
            return true;
        },
        function(v) {
            let videoDuration = (self.transcodedAsset && self.transcodedAsset.duration) ? parseFloat(self.transcodedAsset.duration) : 0;
            if (!videoDuration) return true;
            if ((self.videotimeToInt(v) / 1000) > videoDuration) return self.$t('routes.pod.menuSubtitles.incorrectTime');
            return true;
        }
      ],
    };
  },
  mounted: function() {
    this.refreshInputs(this.subtitleForEditing);
  },
  methods: {
    videotimeToInt: function(value) {
      let minutes = value.split(":")[0];
      let seconds = value.split(".")[0].split(":")[1];
      let milliseconds = value.split(".")[1];

      milliseconds = parseInt(parseFloat("0." + milliseconds) * 1000);
      seconds = parseInt(seconds) * 1000;
      minutes = parseInt(minutes) * 60000;

      return minutes + seconds + milliseconds;
    },
    durationUpdated: function() {
      if (this.duration === "") {
        this.duration = 0;
      }

      const validForm = this.$refs.form.validate();
      if (!validForm) {
        return;
      }

      // update the out time if the duration has been changed
      let inTime = this.videotimeToInt(this.inTime);
      let duration = parseFloat(this.duration) * 1000;
      let outTime = inTime + duration;

      this.outTime = Vue.filter("toTimeMilliseconds")(outTime / 1000);
    },
    inoutTimeUpdated: function() {
      // update the duration if in or out time has been changed
      let inTime = this.videotimeToInt(this.inTime);
      let outTime = this.videotimeToInt(this.outTime);

      this.duration = this.calculateDuration({
        startTime: inTime,
        endTime: outTime,
      });
    },
    hideForm: function() {
      this.$emit('visibility', false);
    },
    refreshInputs: function(item) {
      this.subtitleLine = item.text;

      this.subtitleLineError = "";

      this.duration = this.calculateDuration(item);
      this.inTime = item.startTime ? Vue.filter("toTimeMilliseconds")(item.startTime / 1000) : '00:00.00';
      this.outTime = item.endTime ? Vue.filter("toTimeMilliseconds")(item.endTime / 1000) : '00:01.00';
    },
    saveLine: function() {
      this.subtitleLineError = "";
      const validForm = this.$refs.form.validate();
      if (!validForm) {
        return;
      }

      let isNewLine = false;
      if (!this.subtitleForEditing?.id) {
        isNewLine = true;
      }

      if (!this.subtitleLine) {
        this.subtitleLineError = this.$t('routes.pod.menuSubtitles.subtitleIsRequired');
        return;
      }

      const payload = {
        subtitle: {
          id: isNewLine ? null : this.subtitleForEditing.id,
          text: this.subtitleLine,
          startTime: this.videotimeToInt(this.inTime),
          endTime: this.videotimeToInt(this.outTime),
        },
      };

      const GETSUBTITLES = this.$store.getters.keywords.SUBTITLE.GETSUBTITLES;
      const subtitlesSnapshot = this.$store.getters[GETSUBTITLES];

      console.log('subtitlesSnapshot', subtitlesSnapshot);

      if (isNewLine) {
        const ADDSUBTITLELINE = this.$store.getters.keywords.SUBTITLE.ADDSUBTITLELINE;
        this.$store.commit(ADDSUBTITLELINE, payload);
      } else {
        const UPDATESUBTITLELINE = this.$store.getters.keywords.SUBTITLE.UPDATESUBTITLELINE;
        this.$store.commit(UPDATESUBTITLELINE, payload);
      }

      this.saveAsset(subtitlesSnapshot);
    },
    saveAsset: function(subtitlesSnapshot) {
      let self = this;

      const GETSELECTEDASSET = this.$store.getters.keywords.SUBTITLE.GETSELECTEDASSET;
      const selectedAsset = this.$store.getters[GETSELECTEDASSET];

      const GETTRANSFORMEDSUBTITLES = this.$store.getters.keywords.SUBTITLE.GETTRANSFORMEDSUBTITLES;
      const cues = this.$store.getters[GETTRANSFORMEDSUBTITLES];

      const data = {
        podID: this.pod.id,
        assetID: selectedAsset.id,
        payload: {
          cues,
        }
      };

      const UPDATESUBTITLEASSET = this.$store.getters.keywords.POD.UPDATESUBTITLEASSET;
      this.$store.dispatch(UPDATESUBTITLEASSET, data)
        .then(function(response) {
          console.log('response', response);

          self.hideForm();
        })
        .catch(function(error) {
          if (error?.response?.data?.error?.message) {
            self.$showMessage(error.response.data.error.message, { type: 'error' });
          } else {
            console.log(error);
            self.$showMessage(self.$t('backend.system-error'), { type: 'error' });
          }

          const SETSUBTITLES = self.$store.getters.keywords.SUBTITLE.SETSUBTITLES;
          self.$store.commit(SETSUBTITLES, subtitlesSnapshot);
        });
    },
    // @see https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
    roundTo(n, digits) {
      if (digits === undefined) {
        digits = 0;
      }

      let multiplicator = Math.pow(10, digits);
      n = parseFloat((n * multiplicator).toFixed(11));
      let test = (Math.round(n) / multiplicator);
      return +(test.toFixed(digits));
    },
    calculateDuration(item) {
      if (typeof item.startTime === 'number' && typeof item.endTime === 'number') {
        if (item.endTime > 0) {
          const duration = ((item.endTime - item.startTime) / 1000);
          const result = this.roundTo(duration, 1);
          return result > 0 ? result : 0;
        }
      }

      return 0;
    }
  },
  watch: {
    subtitleForEditing: function(newItem) {
      this.refreshInputs(newItem);
    },
  },
  computed: {
    transcodedAsset: function() {
      if (!this.pod ||
          !this.pod.asset ||
          !this.pod.asset.web ||
          !Array.isArray(this.pod.asset.web)) {
        return null;
      }

      // Get first web asset with status COMPLETE
      for(let i = 0; i < this.pod.asset.web.length; i++) {
        if (this.pod.asset.web[i].state == this.getPodStates.COMPLETE) {
          return this.pod.asset.web[i];
        }
      }

      return null;
    },
    getPodStates: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
    outTimeComparison: function () {
      return this.videotimeToInt(this.inTime) < this.videotimeToInt(this.outTime) ||
        this.$t('routes.pod.menuSubtitles.invalidOutTime');
    },
    characterPerSecond: function() {
      let char = this.subtitleLine.length;
      let duration = parseFloat(this.duration);

      if (duration == 0) {
        return "-";
      }

      let csp = char / duration;
      return csp.toFixed(1);
    }
  }
}
</script>

<style>
.pod-subtitlesform-inout > div { width: 33%;}
</style>
