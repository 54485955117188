import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    users: [],
    selectedUserIds: [],
    editUserId: null,

    usertypes: [],
    selectedUsertype: null,
    sortedBy: {
      column: 'username',
      desc: false,
    },
  },
  getters: {
    getUsers: function(state) {
      return state.users;
    },
    getEditUser: function(state) {
      return state.users.find(x => x.id === state.editUserId);
    },
    getSelectedUsers: function(state) {
      return state.users.filter(x => state.selectedUserIds.indexOf(x.id) >= 0);
    },
    getUsertypes: function(state) {
      return state.usertypes;
    },
    getSortedBy: function(state) {
      return state.sortedBy;
    }
  },
  mutations: {
    setUsers: function(state, users) {
      state.users = users;

      // filter out the selected users that are not present in the new users array
      state.selectedUserIds = state.selectedUserIds.filter(function(userId) {
        return !!state.users.find(y => y.id === userId);
      });
    },
    setEditUser: function(state, user) {
      state.editUserId = (user && user.id) ? user.id : null;
    },
    addUser: function(state, user) {
      state.users.push(user);
    },
    updateUser: function(state, user) {
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].id == user.id) {
          Object.assign(state.users[i], user);
          break;
        }
      }
    },
    removeUser: function(state, user) {
      if (state.editUserId && user.id === state.editUserId) {
        state.editUserId = null;
      }

      const userIndex = state.users.indexOf(user);
      if (userIndex >= 0) {
        state.users.splice(userIndex, 1);
      }

      state.selectedUserIds = state.selectedUserIds.filter(x => x !== user.id);
    },
    toggleSelection: function(state, user) {
      let index = state.selectedUserIds.indexOf(user.id);

      if (index >= 0) {
        state.selectedUserIds.splice(index, 1);
      } else {
        state.selectedUserIds.push(user.id);
      }
    },
    setUsertypes: function(state, usertypes) {
      state.usertypes = usertypes;
    },
    resetSelectedUsers: function(state) {
      state.selectedUserIds = [];
    },
    resetUsers: function(state) {
      state.users = [];
      state.originUsers = [];
      state.selectedUserIds = [];
    },
  },
  actions: {
    refreshUsertypes: function(context) {
      return Api.user.getUsertypes()
      .then(function(result) {
        context.commit('setUsertypes', result.data);
      })
      .catch(function(error) {
        console.error("Error getting usertypes!", error);
      })
    },
    refreshUsers: function(context, payload) {
      return Api.user.getAllUsers(payload)
      .then(function(result) {
        context.commit('setUsers', result.data);
      })
      .catch(function(error) {
        console.error("Error getting users!", error);
      })
    },
    setEditUser: function(context, user) {
      context.commit('setEditUser', user);
    },
    createUser: function(context, formData) {
      return Api.user.createUser(formData)
      .then(function(result) {
        return result.data;
      });
    },
    updateUser: function(context, formData) {
      return Api.user.updateUser(formData)
      .then(function(result) {
        // update the user in the users lists (including selectedUsers)
        context.commit('updateUser', result.data);
        return result.data;
      })
    },
    deleteUser: function(context, payload) {
      return Api.user.deleteUser(payload)
      .then(function(result) {
        return result.data;
      });
    },
    detachUser: function(context, payload) {
      return Api.user.detachUser(payload)
      .then(function(result) {
        return result.data;
      });
    },
    savePermissions: function(context, payload) {
      return Api.user.savePermissions(payload)
      .then(function(result) {
        console.log("Saved user permissions", result);
        context.commit('updateUser', result.data);
        return result.data;
      });
    },
    addAccesspoint: function(context, payload) {
      return Api.user.addAccesspoint(payload.userid, payload.nodeid)
        /*
      .then(function(result) {
        const REFRESHUSERS = context.rootGetters.keywords.USER.REFRESHUSERS;
        const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;

        response = result.data;
        const accesspointId = context.rootGetters[ROOTNODEID];
        const payload = { accessPointId: accesspointId };
        return context.dispatch(REFRESHUSERS, payload)
      })
      */
      .then(function(result) {
        return result.data;
      });
    },
    deleteAccesspoint: function(context, payload) {
      return Api.user.deleteAccesspoint(payload.userid, payload.nodeid)
      /*
      .then(function(result) {
        const REFRESHUSERS = context.rootGetters.keywords.USER.REFRESHUSERS;
        const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;

        response = result.data;
        const accesspointId = context.rootGetters[ROOTNODEID];
        const payload = { accessPointId: accesspointId };
        return context.dispatch(REFRESHUSERS, payload)
      })
      */
      .then(function(result) {
        return result.data;
      });
    },
    updateAccesspoint: function(context, payload) {
      return Api.user.updateAccesspoint(payload)
        /*
      .then(function(result) {
        const REFRESHUSERS = context.rootGetters.keywords.USER.REFRESHUSERS;
        const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;

        response = result.data;
        const accesspointId = context.rootGetters[ROOTNODEID];
        const payload = { accessPointId: accesspointId };
        return context.dispatch(REFRESHUSERS, payload)
      })
      */
        .then(function(result) {
          return result.data;
        });
    },
    changeRootNode: function(context, payload) {
      return Api.user.changeRootNode(payload.nodeid, payload.users)
      .then(function(result) {
        return result.data;
      });
    },
    toggleDisabledUsers: function(context) {
      context.commit('toggleDisabledUsers');
    },
    pingUser: function(context, payload) {
      return Api.user.pingUser(payload)
      .then(function(response) {
        console.log('response', response);
        return response.data;
      });
    },
    updatePassword: function(context, payload) {
      return Api.user.updatePassword(payload)
      .then(function(response) {
        return response.data;
      });
    },
    sendTemporaryPasswordCode: function(context, payload) {
      return Api.user.sendTemporaryPasswordCode(payload)
        .then(function(response) {
          return response.data;
        });
    },
    resetTemporaryPassword: function(context, payload) {
      return Api.user.resetTemporaryPassword(payload)
        .then(function(response) {
          return response.data;
        });
    }
  },
}
