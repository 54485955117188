<template>
  <v-menu
    v-model="contextMenuModel"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-y
  >
    <v-list style="overflow-y: auto">
      <!-- Set retention period -->
      <v-list-item v-if="$canControlTenantConfig()" link>

        <RecycleBinSetRetentionPeriodDialog :retention-period="this.retentionPeriod">
          <v-list-item-content @click.prevent="$emit('contextMenuItemClicked')">
            <v-list-item-title>
              {{ $t('routes.recycleBin.setRetentionTime') }}
            </v-list-item-title>
          </v-list-item-content>
        </RecycleBinSetRetentionPeriodDialog>

      </v-list-item>

      <!-- Empty the recycle bin -->
      <v-list-item v-if="itemsCount > 0 && $canBelongstoTeam()" link>
        <RecycleBinConfirmationDialog
          type="empty"
          :is-showing-dialog="isShowingConfirmationDialog"
          :in-progress="emptyRecycleBinInProgress"
          :error-message="emptyRecycleBinErrorMessage"
          @confirm="confirmEmptyRecycleBin"
          @cancel="isShowingRecycleBinConfirmationDialog = false"
        >
          <v-list-item-content @click.prevent="emptyRecycleBinItemClicked">
            <v-list-item-title>
              {{ $t('routes.recycleBin.emptyRecycleBin') }}
            </v-list-item-title>
          </v-list-item-content>
        </RecycleBinConfirmationDialog>

      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import RecycleBinSetRetentionPeriodDialog from '@/routes/recycle-bin/components/RecycleBinSetRetentionPeriodDialog.vue';
import RecycleBinConfirmationDialog from '@/routes/recycle-bin/components/RecycleBinConfirmationDialog.vue';

export default {
  name: "RecycleBinContextMenu",
  components: { RecycleBinConfirmationDialog, RecycleBinSetRetentionPeriodDialog },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    positionX: {
      type: Number,
      required: true,
    },
    positionY: {
      type: Number,
      required: true,
    },
    itemsCount: {
      type: Number,
      required: true,
    },
    retentionPeriod: {
      type: Number,
      required: true,
    },
  },
  emits: ['contextMenuItemClicked'],
  data() {
    return {
      isShowingConfirmationDialog: false,
      emptyRecycleBinErrorMessage: '',
      emptyRecycleBinInProgress: false,
    };
  },
  methods: {
    emptyRecycleBinItemClicked() {
      this.isShowingConfirmationDialog = true;
      this.$emit('contextMenuItemClicked');
    },
    async confirmEmptyRecycleBin() {
      const EMPTYRECYCLEBIN = this.$store.getters.keywords.RECYCLEBIN.EMPTYRECYCLEBIN;

      this.emptyRecycleBinInProgress = true;

      try {
        await this.$store.dispatch(EMPTYRECYCLEBIN);
        this.isShowingConfirmationDialog = false;
      } catch (error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          this.emptyRecycleBinErrorMessage = error.response.data.error.message;
        } else {
          this.emptyRecycleBinErrorMessage = this.$t('common.generalError');
        }

        this.isShowingConfirmationDialog = false;
      } finally {
        this.emptyRecycleBinInProgress = false;
      }
    },
  },
  computed: {
    contextMenuModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  }
}
</script>

<style scoped>
.v-card__title {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
