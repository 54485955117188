import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    subtitles: [],
    origin: '',
    selectedAssetId: null,
  },
  getters: {
    getSubtitleAssets: function(state, getters, rootState, rootGetters) {
      const GETSUBTITLEASSETS = rootGetters.keywords.POD.GETSUBTITLEASSETS;
      return rootGetters[GETSUBTITLEASSETS];
    },
    getSubtitles: function(state) {
      return state.subtitles;
    },
    getSelectedAsset: function(state, getters) {
      return getters.getSubtitleAssets.find(x => x.id == state.selectedAssetId);
    },
    getTransformedSubtitles: function(state) {
      const cues = [];

      if (state.subtitles.length > 0) {
        for (let i = 0; i < state.subtitles.length; i++) {
          const cue = {
            type: 'cue',
            data: {
              text: state.subtitles[i].text,
              start: state.subtitles[i].startTime,
              end: state.subtitles[i].endTime,
            },
          };

          cues.push(cue);
        }
      }

      return cues;
    }
  },
  mutations: {
    setSelectedAssetId: function(state, assetId) {
      state.selectedAssetId = assetId;
    },
    setSubtitles: function(state, subtitles) {
      state.subtitles = subtitles;
    },
    setOrigin: function(state, origin) {
      state.origin = origin;
    },
    addSubtitleLine: function(state, payload) {
      const subtitles = [...state.subtitles];
      subtitles.push(payload.subtitle);

      subtitles.sort(function(a, b) {
        if (a.startTime < b.startTime) return -1;
        if (a.startTime > b.startTime) return 1;
        return 0;
      });

      for (let i = 0; i < subtitles.length; i++) {
        subtitles[i].id = i + 1;
      }

      state.subtitles = subtitles;
    },
    updateSubtitleLine: function(state, payload) {
      if (state.subtitles.length > 0) {
        for (let i = 0; i < state.subtitles.length; i++) {
          if ((state.subtitles[i].id === payload.subtitle.id)) {
            state.subtitles[i].text = payload.subtitle.text;
            state.subtitles[i].endTime = payload.subtitle.endTime;
            state.subtitles[i].startTime = payload.subtitle.startTime;

            // state.subtitles[i].duration.seconds = ((payload.subtitle.endTime - payload.subtitle.startTime) / 1000).toFixed(1);
            // state.subtitles[i].duration.millisecond = payload.subtitle.endTime - payload.subtitle.startTime;
            break;
          }
        }
      }
    },
    resetSelectedAssetId: function(state) {
      state.selectedAssetId = null;
      state.origin = '';
    },
    resetSubtitles: function(state) {
      return state.subtitles = [];
    },
    removeSubtitleLine: function(state, payload) {
      if (state.subtitles.length > 0) {
        const foundItem = state.subtitles.find((item) => item.id === payload.subtitle.id);

        if (foundItem) {
          const filteredItems = state.subtitles.filter((item) => item.id !== payload.subtitle.id);
          state.subtitles = filteredItems;
        }
      }
    },
  },
  actions: {
    selectSubtitleAsset: function(context, payload) {
      context.commit("setSelectedAssetId", payload.assetId);

      const foundAsset = context.getters.getSelectedAsset;
      if (!foundAsset) {
        return;
      }

      return Api.subtitle.fetchContent(foundAsset.podid, foundAsset.id)
        .then((result) => {
          context.commit("setSubtitles", result.data.subtitles || []);
          context.commit("setOrigin", result.data.origin || '');
        });
    },
    resetState(context) {
      context.commit('resetSubtitles');
      context.commit('resetSelectedAssetId');
    }
  },
}
