<template>
  <v-dialog
      v-model="dialog"
      v-bind:disabled="!transcript"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-deleteTranscript-activator">
        <slot></slot>
      </div>
    </template>

    <v-card data-cy="dialog-deleteTranscript-modal">
      <v-card-title class="red darken-3  d-flex justify-space-between">
        <span>
          {{$t('routes.pod.menuTranscript.deleteTranscriptMessage')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5" data-cy="dialog-deleteTranscript-transcriptName">
        {{ transcript ? transcript.name : ''}}

        <div v-if="errorMessage" class="error" data-cy="dialog-deleteTranscript-errorMessage">
          {{errorMessage}}
        </div>

        <div v-if="errorTranslatable" class="error">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-on:click="deleteTranscript" elevation=0 color="red darken-3" data-cy="dialog-deleteTranscript-ok">
          {{$t('common.delete')}}
        </v-btn>
        <v-btn text @click="dialog = false" data-cy="dialog-deleteTranscript-false">
          {{$t('common.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "TranscriptDeleteDialog",
  props: {
    transcript: {
      type: Object,
      required: false,
    },
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: "",
      errorTranslatable: null,
    };
  },
  watch: {
    dialog() {
      this.errorMessage = "";
      this.errorTranslatable = null;
    }
  },
  methods: {
    deleteTranscript: function() {
      let self = this;

      const data = {
        id: this.transcript.id,
        podID: this.transcript.podid,
      };

      console.log('this.transcript', this.transcript);
      const DELETETRANSCRIPT = this.$store.getters.keywords.POD.TRANSCRIPT.DELETETRANSCRIPT;

      this.$store.dispatch(DELETETRANSCRIPT, data)
      .then(function() {
        self.dialog = false;
        self.$emit("refreshSelectedTranscript");
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    }
  }
}
</script>
