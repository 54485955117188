<template>
  <v-dialog
      v-model="showDialog"
      persistent
      max-width="320"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="">
        <slot></slot>
      </div>
    </template>


    <v-card data-cy="d">
      <v-card-title class="primary d-flex justify-space-between">
        {{$t('routes.users.permissionsChanged')}}

        <v-btn icon @click="closeAndRedirect()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        {{$t('routes.users.yourPermissionsHasChanged')}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeAndRedirect()" data-cy=""> {{$t('common.redirect')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "PermissionsChangedDialog",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      // dialog: false,

    //   podName: this.name || "",
    };
  },
  methods: {
    closeAndRedirect: function() {
      // redirect to base
      // window.location.href = '/';
        // window.location.reload();

        this.$router.push('/account/logout');
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
}
</script>

<style>

</style>
