import moment from 'moment';


// filters the nodes and pods that are currently listed in the node view
export default {
  namespaced: true,
  state: {
    archivedFilter: false,
    // selectedNodeTypes: [],
    datesRange: [],
    selectedUploaders: [],
    selectedFileTypes: [],
    selectedPodStatuses: [],

    sortColumn: '',
    sortDesc: false,
  },
  getters:{
    getSortColumn: function(state) {
      return state.sortColumn;
    },
    getSortDesc: function(state) {
      return state.sortDesc;
    },


    getNodes: function(state, getters, rootState, rootGetters) {
      const CHILDNODES = rootGetters.keywords.NODE.CHILDNODES;
      return rootGetters[CHILDNODES];
      // state; getters; rootState; rootGetters;
      // return [];
    },
    getPods: function(state, getters, rootState, rootGetters) {
      const GETPODS = rootGetters.keywords.POD.GETPODS;
      return rootGetters[GETPODS].filter(x => x.parentpodid == null);
    },


    getArchivedFilter: function(state) {
      return state.archivedFilter;
    },
    getArchivedNodes: function(state, getters) {
      return getters.getNodes.filter(function(x) {
        const isArchived = Boolean(x.archived);
        return state.archivedFilter ? isArchived : !isArchived;
      });
    },
    getArchivedPods: function(state, getters) {
      return getters.getPods.filter(function(x) {
        const isArchived = Boolean(x.archived);
        return state.archivedFilter ? isArchived : !isArchived;
      });
    },


    // Combine all nodes and pos
    nodesAndPods: function(state, getters) {
      // if we show the archived nodes and pods separately
      // return getters.getArchivedNodes.concat(getters.getArchivedPods);

      // but now we show them together
      return getters.getNodes.concat(getters.getPods);
    },


    // available node types
    // getAvailableNodeTypes: function(state, getters) {
    //   let types = getters.getArchivedNodes.map(x => x.type);
    //   types = types.concat(state.selectedNodeTypes);
    //   let uniqueTypes = types.filter((x, i, a) => a.indexOf(x) == i); // remove duplicated
    //   return uniqueTypes.sort();
    // },
    // // filter by node types
    // filterByNodeTypes: function(state, getters) {
    //   if (state.selectedNodeTypes.length == 0) {
    //     return getters.nodesAndPods;
    //   }

    //   return getters.nodesAndPods.filter(function(x) {
    //     return state.selectedNodeTypes.indexOf(x.type) >= 0;
    //   });
    // },
    filterByNodeTypes: function (state, getters) {
      return getters.nodesAndPods;
    },



    // availabel created at dates
    getAvailableDates: function(state, getters) {
      if (getters.filterByNodeTypes.length == 0) {
        return state.datesRange.length > 0 ? state.datesRange : [];
      }

      let minDate = new Date(getters.filterByNodeTypes[0].createdat);
      let maxDate = new Date(getters.filterByNodeTypes[0].createdat);

      for (let i = 1; i < getters.filterByNodeTypes.length; i++) {
        let createdAt = new Date(getters.filterByNodeTypes[i].createdat);
        if (createdAt > maxDate)  maxDate = createdAt;
        if (createdAt < minDate)  minDate = createdAt;
      }

      if (state.datesRange.length > 0) {
        let stateMin = moment(state.datesRange[0]);
        let stateMax = state.datesRange[1] ? moment(state.datesRange[1]) : moment(state.datesRange[0]);

        if (minDate > stateMin) { minDate = stateMin.toDate()}
        if (maxDate < stateMax) { maxDate = stateMax.toDate()}
      }

      return [minDate, maxDate];
    },
    // filter by created at dates range
    filterByDatesRange: function(state, getters) {
      if (state.datesRange.length == 1) {
        return getters.filterByNodeTypes.filter(function(x) {
          return moment(x.createdat).format('YYYY-MM-DD') == state.datesRange[0];
        });
      }

      if (state.datesRange.length == 2) {
        let startDate = moment(state.datesRange[0]);
        let endDate = moment(state.datesRange[1]).add(1, 'day');

        return getters.filterByNodeTypes.filter(function(x) {
          let currentDate = moment(x.createdat);
          return currentDate >= startDate && currentDate <= endDate;
        });
      }

      // if (state.datesRange.length == 0)
      return getters.filterByNodeTypes;
    },



    // get available uploaders (array)
    getAvailableUploaders: function(state, getters) {
      let uploaders = {};
      for (let item of getters.filterByDatesRange) {
        if (!item.createdby)
          continue;
        uploaders[item.createdby.id] = item.createdby;
      }
      for (let upl of state.selectedUploaders) {
        uploaders[upl.id] = upl;
      }

      return Object.values(uploaders);
    },
    // filter by uploader
    filterByUploader: function(state, getters) {
      if (state.selectedUploaders.length == 0) {
        return getters.filterByDatesRange;
      }

      let uploaderIDs = state.selectedUploaders.map(x => x.id);
      return getters.filterByDatesRange.filter(x => uploaderIDs.indexOf(x.createdbyid) >= 0);
    },

    // get available file types
    getAvailableFileTypes: function(state, getters) {
      let filetypes = [].concat(state.selectedFileTypes);

      for (let item of getters.filterByUploader) {
        if (item && item.asset && item.asset.raw && item.asset.raw[0] && item.asset.raw[0].filetype) {
          filetypes.push(item.asset.raw[0].filetype);
        }
      }

      // return unique file types
      return filetypes.filter((value, index, self) => self.indexOf(value) === index);
    },
    // Filter by file types
    filterByFileTypes: function(state, getters) {
      if (state.selectedFileTypes.length == 0) {
        return getters.filterByUploader;
      }

      return getters.filterByUploader.filter(function (item) {
        if (item && item.asset && item.asset.raw && item.asset.raw[0] && item.asset.raw[0].filetype) {
          let type = item.asset.raw[0].filetype;
          if (state.selectedFileTypes.indexOf(type) >= 0) {
            return true;
          }
        }

        return false;
      });
    },

    // Get available pod statuses
    getAvailablePodStatuses: function(state, getters) {
      let statuses = [].concat(state.selectedPodStatuses);
      for (let item of getters.filterByFileTypes) {
        if (item.badgestatus !== null) {
          statuses.push(item.badgestatus);
        }
      }

      return statuses.filter((value, index, self) => self.indexOf(value) === index).sort();
    },
    // Filter by pod statuses
    filterByPodStatus: function(state, getters) {
      if (state.selectedPodStatuses.length == 0) {
        return getters.filterByFileTypes;
      }

      return getters.filterByFileTypes.filter(function(item) {
        if (
            item.badgestatus !== null &&
            state.selectedPodStatuses.indexOf(item.badgestatus) >= 0
          ) {
            return true;
        }

        return false;
      });
    },

    sortByColumn: function(state, getters) {
      if (!state.sortColumn) return getters.filterByPodStatus;

      const items = [...getters.filterByPodStatus];
      switch (state.sortColumn) {
        case 'type': items.sort(sortByType); break;
        case 'name': items.sort(sortByName); break;
        case 'size': items.sort(sortBySize); break;
        case 'date': items.sort(sortByDate); break;
        case 'note': items.sort(sortByNote); break;
        default: break;
      }

      if (state.sortDesc) {
        return items.reverse();
      }
      return items;


      function sortByType (a, b) {
        if (a.nodeid && b.nodeid) return this.sortByName(a, b);
        if (a.nodeid && !b.nodeid) return 1;
        if (!a.nodeid && b.nodeid) return -1;
        if (!a.nodeid && !b.nodeid) return a.type - b.type;
      }
      function sortByName (a, b) {
        return a.name.localeCompare(b.name);
      }
      function sortBySize(a, b) {
        let sizeA = a.size || 0;
        let sizeB = b.size || 0;

        return sizeA - sizeB;
      }
      function sortByDate (a, b) {
        let dateA = new Date(a.createdat);
        let dateB = new Date(b.createdat);

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      }
      function sortByNote (a, b) {
        if (a.note && b.note) return a.note.localeCompare(b.note);
        if (a.note && !b.note) return 1;
        if (!a.note && b.note) return -1;
        // if (!a.note && !b.note) return 0;
        return 0;
      }
    },


    // the final list
    getFilteredList: function(state, getters) {
      return getters.sortByColumn;
    },
  },
  mutations: {
    // TODO: reset the selected nodes and pods when changing the filters
    setArchivedFilter: function(state, archived) {
      state.archivedFilter = Boolean(archived);
    },
    // setNodeTypeFilter: function(state, nodeTypeIds) {
    //   state.selectedNodeTypes = nodeTypeIds;
    // },
    setDatesRangeFilter: function(state, datesRange) {
      state.datesRange = datesRange;
    },
    setUploadersFilter: function(state, uploaders) {
      state.selectedUploaders = uploaders;
    },
    setFiletypesFilter: function(state, fileTypes) {
      state.selectedFileTypes = fileTypes;
    },
    setPodStatusFilter: function(state, podStatuses) {
      state.selectedPodStatuses = podStatuses;
    },
    setSortColumn: function(state, sortByColumnName) {
      if (sortByColumnName === null) {
        state.sortColumn = '';
        state.sortDesc = false;
        return;
      }

      if (state.sortColumn == sortByColumnName) {
        state.sortDesc = !state.sortDesc;
        if (!state.sortDesc) {
          state.sortColumn = '';
        }
      } else {
        state.sortColumn = sortByColumnName;
        state.sortDesc = false;
      }
    },
  },
  actions: {},
}
