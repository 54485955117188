import API from '../AxiosInstance';

export default {
  listNodes: function (rootNodeId) {
    return API.get(`recycle-bin/${rootNodeId}`, {});
  },
  restorePods: function (rootNodeId, podIds) {
    return API.post(`recycle-bin/${rootNodeId}/restore`, {
      podids: podIds,
    });
  },
  permanentlyDeletePods: function (rootNodeId, podIds) {
    return API.post(`recycle-bin/${rootNodeId}/delete`, {
      podids: podIds,
    });
  },
  emptyRecycleBin: function (rootNodeId) {
    return API.post(`recycle-bin/${rootNodeId}/empty`, {});
  },
}
