<template>
  <v-breadcrumbs v-bind:items="breadcrumbItems" class="pl-0" data-cy="topbar-breadcrumbs">
    <template v-slot:divider>
      <v-icon class="mr-0">mdi-chevron-right</v-icon>
    </template>

    <template v-slot:item="{ item }">
      <span class="breadcrumb-item">

        <span v-if="!item.disabled" class="text-truncate breadcrumb-item-text" style="margin-top: 6px;">
          <router-link  v-bind:to="{ path: item.href }">
            <v-icon v-if="item.icon" small class="mb-1 mr-0">{{item.icon}}</v-icon>
            {{item.text}}
          </router-link>
        </span>

        <span v-if="item.disabled" class="text-truncate breadcrumb-item-text" style="margin-top: 6px;">
          <v-icon v-if="item.icon" small class="mb-1 mr-0">{{item.icon}}</v-icon>
          {{item.text}}
        </span>
      </span>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'BreadcrumbNodes',
  methods: {
    getPodItem: function(pod) {
      return {
        text: pod.name,
        icon: 'mdi-file-outline',
      };
    },
    getNodeItem: function(node) {
      let item = {
        id: node.id,
        parentid: node.parentid,
        text: node.name,
        href: '/node/' + node.id,
        disabled: false,
        icon: this.getNodeIcon(node),
      };

      return item;
    },
    getSeparationItem: function() {
      return {
        text: '',
        disabled: true,
        icon: 'mdi-dots-horizontal',
      };
    },
    getNodeIcon(node) {
      if (node.archived === 1 || node.archived === 2) {
        return 'mdi-package-down';
      }

      switch (node.type) {
        case 1: return 'mdi-folder';
        case 2: return 'mdi-briefcase-clock';
        case 3: return 'mdi-account-tie';
        case 4: return 'mdi-home-assistant';
        default: return 'mdi-folder';
      }
    }
  },
  computed: {
    getNodeById() {
      return function(nodeId) {
        const GETNODEBYID = this.$store.getters.keywords.NODE.GETNODEBYID;
        return this.$store.getters[GETNODEBYID](nodeId);
      }
    },
    currentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE];
    },
    getActiveAccessPoint: function() {
      const GETACTIVEACCESSPOINT = this.$store.getters.keywords.AUTH.GETACTIVEACCESSPOINT;
      return this.$store.getters[GETACTIVEACCESSPOINT];
    },
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    breadcrumbItems: function() {
      let items = []

      if (this.pod) {
        items.unshift(this.getPodItem(this.pod));
      }

      if (this.currentNode) {
        items.unshift(this.getNodeItem(this.currentNode));

        const parentNode = this.getNodeById(this.currentNode.parentid);
        if (parentNode) {
          items.unshift(this.getNodeItem(parentNode));

          const parentOfParent = this.getNodeById(parentNode.parentid);
          if (parentOfParent) {
            items.unshift(this.getNodeItem(parentOfParent));
          }
        }
      }

      if (!this.getActiveAccessPoint) {
        return [];
      }

      const contextNodeId = this.getActiveAccessPoint.node.id;
      const contextNode = this.getActiveAccessPoint.node;

      const alreadyAdded = items.length > 0 && items[0].id == contextNodeId;

      if (!alreadyAdded) {
        while (items.length > 3) {
          items.shift();
        }

        if (contextNode && items.length > 0 && items[0].parentid != contextNodeId) {
          items.unshift(this.getSeparationItem());
        }

        if (contextNode) {
          items.unshift(this.getNodeItem(contextNode));
        }
      }

      if (items.length > 1) {
        items[items.length-1].disabled = true;
      }

      return items;
    }


  }
}
</script>

<style>
  .breadcrumb-item  a{
    /* font-weight: 600; */
    text-decoration: none;
    /* color: #dcdcdc !important; */
  }

  .theme--dark .breadcrumb-item  a {
    color: #EBEBEB !important;
  }
  .theme--light .breadcrumb-item  a {
    color: #131313 !important;
  }

  .breadcrumb-item-text {
    display: inline-block;
    max-width: 150px;
    /* line-height: 0.8; */
  }

  @media only screen and (max-width: 960px) {
    .breadcrumb-item-text {
      max-width: 120px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .v-breadcrumbs {
      padding: 0px !important;
    }
  }

  /* @media only screen and (max-width: 960px) {
    .v-breadcrumbs {
      font-size: 12px !important;
    }

    .breadcrumb-item-text {
      max-width: 80px !important;
    }

    .v-breadcrumbs > li.v-breadcrumbs__divider {
      padding: 0px !important;
    }
  } */
</style>
