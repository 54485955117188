import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    usersData: [],
    tenantsData: [],

    userInfo: null,
    tenantInfo: null,
  },
  getters: {
    getUsersData: function(state) {
      return state.usersData;
    },
    getTenantsData: function(state) {
      return (state.tenantsData);
    },
    getUserInfo: function(state) {
      return state.userInfo;
    },
    getTenantInfo: function(state) {
      return state.tenantInfo;
    },
  },
  mutations: {
    setUsersData: function(state, data) {
      state.usersData = data;
    },
    setTenantsData: function(state, data) {
      state.tenantsData = data;
    },
    setUserInfo: function(state, info) {
      state.userInfo = info;
    },
    setTenantInfo: function(state, info) {
      state.tenantInfo = info;
    }
  },
  actions: {
    refreshUsersData: function(context) {
      return Api.usage.getUsersData()
      .then(function(response) {
        context.commit('setUsersData', response.data);
        console.log("Got users data", response);
      });
    },
    refreshTenantsData: function(context) {
      return Api.usage.getTenantsData()
      .then(function(response) {
        context.commit('setTenantsData', response.data);
        console.log("Got tenants data", response);
      });
    },

    refreshUserInfo: function(context, userId) {
      context.commit('setUserInfo', null);
      return Api.usage.getUserUsage(userId)
      .then(function(response) {
        console.log("Got user usage", response);
        context.commit('setUserInfo', response.data);
      });
    },
    refreshTenantInfo: function(context, tenantId) {
      context.commit('setTenantInfo', null);
      return Api.usage.getTenantUsage(tenantId)
      .then(function(response) {
        console.log("Got tenant usage", response);
        context.commit('setTenantInfo', response.data);
      });
    },
  }
}
