import API from '../AxiosInstance';

export default {
  updateFirstName: function(payload) {
    const body = {
      firstName: payload.firstName,
    };

    return API.patch('accounts/firstname', body);
  },
  updateLastName: function(payload) {
    const body = {
      lastName: payload.lastName,
    };

    return API.patch('accounts/lastname', body);
  },
  updateNotificationSetting: function(payload) {
    const body = {
      name: payload.name,
      value: payload.value,
    };

    return API.patch('accounts/settings/notification', body);
  },
  updateLanguage: function(payload) {
    const body = {
      language: payload.language,
    };

    return API.patch('accounts/settings/language', body);
  },
  updateEmailAddress: function(payload) {
    const body = {
      email: payload.email,
    };

    return API.patch('accounts/email', body);
  },
  changeTheme: function(payload) {
    const body = {
      theme: payload.theme,
    };

    return API.patch('accounts/settings/theme', body);
  },
  changeFolderView: function(payload) {
    const body = {
      view: payload.view,
    };

    return API.patch('accounts/settings/folderview', body);
  },
  getUsage: function(payload) {
    const queryParams = {
      params: {
        tid: payload.tenantId,
      }
    };

    return API.get(`logs/usage`, queryParams);
  },

  getAccountDeletionInfo: function() {
    const body = {};

    return API.get(`accounts/deletion`, body);
  },
  deleteAccount: function() {
    const body = {};

    return API.delete(`accounts/me`, body);
  },
}
