export default {
  // name: "LayoutThemeMixin",
  mounted() {
    // set the initial state of the dark mode
    this.$vuetify.theme.dark = this.isDarkMode;
  },
  watch: {
    isDarkMode(newValue) {
      this.$vuetify.theme.dark = newValue;
    }
  },
  computed: {
    isDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    }
  }
}