import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    updateFirstName: function(context, payload) {
      return Api.account.updateFirstName(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateLastName: function(context, payload) {
      return Api.account.updateLastName(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateNotificationSetting: function(context, payload) {
      return Api.account.updateNotificationSetting(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateLanguage: function(context, payload) {
      return Api.account.updateLanguage(payload)
        .then(function(response) {
          return response.data;
        });
    },
    updateEmailAddress: function(context, payload) {
      return Api.account.updateEmailAddress(payload)
        .then(function(response) {
          return response.data;
        });
    },
    changeTheme: function(context, payload) {
      return Api.account.changeTheme(payload)
        .then(function(response) {
          return response.data;
        });
    },
    changeFolderView: function(context, payload) {
      return Api.account.changeFolderView(payload)
        .then(function(response) {
          return response.data;
        });
    },
    getUsage: function(context, payload) {
      return Api.account.getUsage(payload)
        .then(function(response) {
          return response.data;
        });
    },
    getAccountDeletionInfo: function() {
      return Api.account.getAccountDeletionInfo()
        .then(function(response) {
          return response.data;
        });
    },
    deleteAccount: function() {
      return Api.account.deleteAccount()
        .then(function(response) {
          return response.data;
        });
    },
  },
}
